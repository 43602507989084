import * as Yup from "yup";

const emailRegex =
  /^(?!\s)\w+([.-]?\w+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.){1,2}[a-zA-Z]{2,3}))$/;
const firstnameRegex = /^[a-zA-Z]*$/;
const lastRegex = /^[a-zA-Z\s]*$/;

export const loginSchema = Yup.object({
  email: Yup.string()
    .required("Please enter the email address.")
    .trim()
    .matches(emailRegex, "Please enter valid email id.")
    .max(64, "Email cannot be more than 64 characters.")
    .email("Invalid email address."),

  password: Yup.string()
    .required("Please enter the password.")
    .trim()
    .min(8, "Must be more than 8 characters.")
    // .matches(passwordRegex, 'Password must be combination of lowercase, uppercase, digit and special character.')
    .max(20, "Password cannot be more than 20 characters."),
});

export const forgotPasswordSchema = Yup.object({
  email: Yup.string()
    .required("Please enter the email address.")
    .trim()
    .matches(emailRegex, "Please enter valid email id.")
    .max(64, "Email cannot be more than 64 characters.")
    .email("Invalid email address."),
});

export const resetPasswordSchema = Yup.object({
  newPassword: Yup.string()
    .required("Please enter the new password.")
    .trim()
    .min(8, "Must be more than 8 characters.")
    // .matches(passwordRegex, 'Password must be combination of lowercase, uppercase, digit and special character.')
    .max(20, "Password cannot be more than 20 characters."),

  confirmPassword: Yup.string()
    .required("Please enter the confirn password.")
    .trim()
    .oneOf([Yup.ref("newPassword"), null], "Password must match."),
});

export const addNewCategorySchema = Yup.object().shape({
  // // // selectStage: Yup.string().required('Stage is required'),
  // // // dietName: Yup.string()
  // // //   .max(30, 'Diet name must be 30 characters or less')
  // // //   .required('Diet name is required'),
  // // // saveas: Yup.string().required('Please select an option')
});

export const addExerciseSchema = Yup.object().shape({
  exerciseName: Yup.string()
    .min(5, "Exercise name must be at least 5 characters long.")
    .max(30, "Exercise name must be at most 30 characters long.")
    .required("Exercise name is required."),
  categoryImage: Yup.mixed().required("Exercise thumbnail image is required."),
  description: Yup.string()
    .min(10, "Description must be at least 10 characters long.")
    .max(400, "Description must be at most 400 characters long.")
    .required("Description is required."),
});

export const addWorkoutSchema = Yup.object().shape({
  selectStage: Yup.array()
    .of(Yup.string())
    .min(1, "Please select at least one stage.")
    .required("Please select a stage."),
  workoutCategoryName: Yup.string()
    .min(5, "Workout category name must be at least 5 characters.")
    .max(50, "Workout category name cannot be more than 50 characters.")
    .required("Please add workout category name.")
    .matches(
      /^(?!^\d+$)[a-zA-Z0-9\s]+$/,
      "Recipe type should contain characters and not only numbers."
    ),
});

export const addCategorySchema = Yup.object().shape({
  stage: Yup.string().required("Please select the stage."),
  categoryName: Yup.string()
    .min(5, "Category name must be at least 5 characters.")
    .max(50, "Category name must be at most 50 characters.")
    .matches(/[a-zA-Z]/, "Category name must contain at least one letter.")
    .required("Please enter the category name."),
  saveAs: Yup.string().required("Please specify how to save."),
  thumbnail: Yup.mixed().required("Please upload a thumbnail."),
});

export const editCategorySchema = Yup.object().shape({
  selectStage: Yup.string().required("Please select the stage."),
  categoryName: Yup.string()
    .min(5, "Category name must be at least 5 characters.")
    .max(50, "Category name must be at most 50 characters.")
    .matches(/[a-zA-Z]/, "Category name must contain at least one letter.")
    .required("Please enter the category name."),
  // saveAs: Yup.string().required("Please specify how to save."), // Add this if saveAs is in your form
  // categoryImage: Yup.mixed().required("Please upload a thumbnail."),
});

export const changePasswordSchema = Yup.object({
  oldPassword: Yup.string()
    .required("Please enter the old password.")
    .trim()
    .min(8, "Must be more than 8 characters.")
    .max(20, "Password cannot be more than 20 characters."),

  newPassword: Yup.string()
    .required("Please enter the new password.")
    .trim()
    .min(8, "Must be more than 8 characters.")
    .max(20, "Password cannot be more than 20 characters.")
    .notOneOf(
      [Yup.ref("oldPassword")],
      "New password cannot be the same as old password."
    ), // Custom validation

  confirmPassword: Yup.string()
    .required("Please re-enter new password.")
    .trim()
    .oneOf([Yup.ref("newPassword"), null], "Password must match."),
});

export const editAccountSchema = Yup.object({
  firstName: Yup.string()
    .required("Please enter your first name.")
    .trim()
    .min(2, "First name cannot be less than 2 characters.")
    .max(20, "First name cannot be more than 20 characters.")
    .matches(firstnameRegex, "Invalid first name."),

  lastName: Yup.string()
    .required("Please enter your last name.")
    .trim()
    .min(2, "Last name cannot be less than 2 characters.")
    .max(20, "Last name cannot be more than 20 characters.")
    .matches(lastRegex, "Invalid last name."),

  email: Yup.string()
    .required("Please enter the email address.")
    .trim()
    .matches(emailRegex, "Please enter valid email id.")
    .max(64, "Email cannot be more than 64 characters.")
    .email("Invalid email address."),
});

export const PlanSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  consultType: Yup.string().required("Consult type is required"),
  services: Yup.array()
    .of(
      Yup.object().shape({
        service: Yup.string().required("Service is required"),
        price: Yup.number()
          .required("Price is required")
          .positive("Price must be positive"),
      })
    )
    .min(1, "At least one service is required"),
});

export const addServiceSpecializationSchema = Yup.object().shape({
  selectType: Yup.string()
    .oneOf(["Specialization", "Service"], "Please select a valid type")
    .required("Please select type."),
  name: Yup.string()
    .max(50, "Name is too long - should be 50 chars maximum")
    .required("Please select name."),
});

const FILE_SIZE = 5 * 1024 * 1024; // 5 MB
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png", "image/gif"];

Yup.addMethod(Yup.string, "wordCount", function (min, max, message) {
  return this.test(
    "wordCount",
    message || `The text must be between ${min} and ${max} words`,
    function (value) {
      const words = value ? value.trim().split(/\s+/).length : 0;
      return words >= min && words <= max;
    }
  );
});

export const addConsultSchema = Yup.object().shape({
  companyName: Yup.string(),
  companyLogo: Yup.mixed()
    .nullable()
    .test(
      "fileSize",
      "Please upload a file smaller than 5 MB.",
      (value) => value === null || (value && value.size <= FILE_SIZE)
    )
    .test(
      "fileFormat",
      "Please upload a supported file format (jpg, jpeg, png, gif).",
      (value) =>
        value === null || (value && SUPPORTED_FORMATS.includes(value.type))
    ),
  consultName: Yup.string()
    .required("Please enter the consultant name.")
    .min(3, "Name is too short - should be 3 chars minimum")
    .max(80, "Name is too long - should be 80 chars maximum"),
  consultLogo: Yup.mixed()
    .required("Please upload the consultant logo.")
    .test(
      "fileSize",
      "Please upload a file smaller than 5 MB.",
      (value) => value && value.size <= FILE_SIZE
    )
    .test(
      "fileFormat",
      "Please upload a supported file format (jpg, jpeg, png, gif).",
      (value) => value && SUPPORTED_FORMATS.includes(value.type)
    ),
  consultDescription: Yup.string()
    .wordCount(500, "Description can be atmost  500 words.")
    .required("Description is required."),

  websiteURL: Yup.string().url("Please enter a valid URL."),
  meetingLink: Yup.string(),
  email: Yup.string()
    .email("Please enter a valid email.")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Please enter a valid email address."
    ),
  country: Yup.string().required("Please enter the country."),
  code: Yup.string().required("Please enter the code."),
  phone: Yup.string()
    .required("Please enter the phone number.")
    .matches(/^\+?[1-9]\d{1,14}$/, "Invalid phone number format.")
    .max(15, "Phone number can be most 15 digits.")
    .min(5, "Phone number should be minimum 5 digits."),
  availability: Yup.string().required("Please enter the availability."),
  address: Yup.string(),
  latitude: Yup.number(),
  longitude: Yup.number(),
  plans: Yup.array().of(
    Yup.object().shape({
      serviceName: Yup.string()
        .required("Please enter the service name.")
        .min(5, "Service is too short - should be 5 chars minimum")
        .max(100, "Service is too long - should be 100 chars maximum"),
      price: Yup.number()
        .required("Please enter the price.")
        .positive("Price must be positive.")
        .max(99999, "Price cannot exceed 5 digits."),
    })
  ),
  packages: Yup.array().of(
    Yup.object().shape({
      packageName: Yup.string()
        .required("Please enter the package name.")
        .min(5, "Service is too short - should be 5 chars minimum")
        .max(100, "Service is too long - should be 100 chars maximum"),
      price: Yup.number()
        .required("Please enter the price.")
        .positive("Price must be positive.")
        .max(99999, "Price cannot exceed 5 digits."),
    })
  ),
});
export const subscriptionSchema = Yup.object({
  subscriptionName: Yup.string()
    .required("Please enter the subscription name.")
    .trim()
    .min(2, "Name cannot be less than 2 characters.")
    .max(30, "Name cannot be more than 30 characters."),

  price: Yup.string().required("Please enter the price.").trim(),

  discount: Yup.number().optional(),

  duration: Yup.string().required("Please select the duration."),
  StripePriceId: Yup.string().required("Please enter price Id."),
});

export const recipeSchema = Yup.object().shape({
  selectStage: Yup.array()
    .min(1, "Please select at least one stage.")
    .required("Please select stage."),
  dietaryPreference: Yup.array()
    .min(1, "Please select at least one dietary preference.")
    .required("Please select dietary preference."),
  cuisine: Yup.array()
    .min(1, "Please select at least one cuisine.")
    .required("Please select cuisine."),
  recipeType: Yup.array()
    .min(1, "Please select at least one recipe type.")
    .required("Please select recipe type."),
  dietType: Yup.array()
    .min(1, "Please select at least one diet type.")
    .required("Please select diet type."),
  preparationTime: Yup.number()
    .typeError("Preparation time must be a number.")
    .positive("Preparation time must be a positive number.")
    .integer("Preparation time must be an integer.")
    .required("Please enter preparation time."),
  servings: Yup.number()
    .typeError("Servings must be a number.")
    .positive("Servings must be a positive number.")
    .integer("Servings must be an integer.")
    .required("Please enter no. of servings.")
    .min(1, "Servings must be at least 1.")
    .max(100, "Servings can be at most 100."),
  thumbnailImage: Yup.string().required("Please upload thumbnail image."),
  ingredientDetails: Yup.string()
    .required("Please enter ingredient details.")
    .max(500, "Ingredient details can be at most 500 characters."),
  recipeName: Yup.string()
    .required("Please enter recipe name.")
    .max(50, "Recipe name can be at most 50 characters.")
    .matches(
      /^(?!^\d+$)[a-zA-Z0-9\s]+$/,
      "Recipe name should contain characters and not only numbers."
    ),
  steps: Yup.array()
    .of(
      Yup.string()
        .required("Step cannot be empty.")
        .max(500, "Each step can be at most 500 characters.")
    )
    .min(1, "Please add at least one step."),
  nutrition: Yup.array()
    .of(
      Yup.object().shape({
        key: Yup.string()
          .required("Key cannot be empty.")
          .max(50, "Key can be at most 50 characters."),
        value: Yup.string()
          .required("Value cannot be empty.")
          .max(100, "Value can be at most 100 characters."),
      })
    )
    .min(1, "Please add at least one nutrition item."),
  notes: Yup.string().max(500, "Notes can be at most 500 characters."),
});

export const editConsultSchema = Yup.object().shape({
  companyName: Yup.string(),
  companyLogo: Yup.mixed()
    .nullable()
    .test(
      "fileSize",
      "Please upload a file smaller than 5 MB.",
      (value) => !value || (value && value.size <= FILE_SIZE)
    )
    .test(
      "fileFormat",
      "Please upload a supported file format (jpg, jpeg, png, gif).",
      (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
    ),
  consultName: Yup.string().required("Please enter the consultant name."),
  consultLogo: Yup.mixed()
    .test(
      "fileSize",
      "Please upload a file smaller than 5 MB.",
      (value) => !value || (value && value.size <= FILE_SIZE)
    )
    .test(
      "fileFormat",
      "Please upload a supported file format (jpg, jpeg, png, gif).",
      (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
    ),
  consultDescription: Yup.string().required(
    "Please enter the consultant description."
  ),
  websiteURL: Yup.string().url("Please enter a valid URL."),
  email: Yup.string()
    .email("Please enter a valid email.")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Please enter a valid email address."
    ),
  country: Yup.string().required("Please enter the country."),
  code: Yup.string().required("Please enter the code."),
  phone: Yup.string().required("Please enter the phone number."),
  availability: Yup.string().required("Please enter the availability."),
  address: Yup.string(),
  latitude: Yup.number(),
  longitude: Yup.number(),
  plans: Yup.array().of(
    Yup.object().shape({
      serviceName: Yup.string().required("Please enter the service name."),
      price: Yup.number()
        .required("Please enter the price.")
        .positive("Price must be positive."),
    })
  ),
  packages: Yup.array().of(
    Yup.object().shape({
      packageName: Yup.string(),
      price: Yup.number().positive("Price must be positive."),
    })
  ),
});

export const CouponSchema = Yup.object({
  couponCode: Yup.string().required("Please enter coupon code."),

  discount: Yup.number()
    .required("Please enter discount value.")
    .min(0, "Discount must be a positive number.")
    .max(100, "Discount should be smaller than 100"),
});

export const addTrimesterSchema = Yup.object({
  trimesterName: Yup.string()
    .required("Please enter Trimester Name")
    .min(2, "Trimester name cannot be less than 2 characters.")
    .max(20, "Trimester name cannot be more than 20 characters."),
});

export const weekSchema = Yup.object({
  weekNumber: Yup.number().required("Week number is required."),
  description: Yup.array()
    .of(
      Yup.object().shape({
        Images: Yup.array()
          .of(Yup.string().url("Invalid URL format"))
          .optional(),
        Textdescription: Yup.string()
          .required("hjghf")
          .max(10, "Description cant be more than 1000 characters."),
        Videos: Yup.object({
          video: Yup.string().url("Invalid URL format").optional(),
          image: Yup.string().url("Invalid URL format").optional(),
        }).optional(),
        ContentDetails: Yup.array()
          .of(
            Yup.object().shape({
              title: Yup.string()
                .max(50, "Title must be at most 50 characters")
                .optional(),
              description: Yup.string()
                .max(1000, "Description must be at most 1000 characters")
                .optional(),
              image: Yup.string().url("Invalid URL format").optional(),
            })
          )
          .optional(),
        accordionTitleDescriptions: Yup.array()
          .of(
            Yup.object().shape({
              title: Yup.string()
                .max(50, "Title must be at most 50 characters")
                .optional(),
              description: Yup.string()
                .max(1000, "Description must be at most 1000 characters")
                .optional(),
            })
          )
          .optional(),
        Symptoms: Yup.array()
          .of(
            Yup.object().shape({
              title: Yup.string()
                .max(50, "Title must be at most 50 characters")
                .optional(),
              image: Yup.string().url("Invalid URL format").optional(),
            })
          )
          .optional(),
        Tips: Yup.array()
          .of(Yup.string().max(50, "Tip must be at most 50 characters"))
          .optional(),
        FAQ: Yup.array()
          .of(
            Yup.object().shape({
              question: Yup.string()
                .max(100, "Question must be at most 100 characters")
                .optional(),
              answer: Yup.string()
                .max(1000, "Answer must be at most 1000 characters")
                .optional(),
            })
          )
          .optional(),
      })
    )
    .optional(),
  weekThumbnail: Yup.string().url("Invalid URL format").optional(),
});

export const programSchema = Yup.object({
  selectRange: Yup.string().required("Please select a stage."),
  selectCategory: Yup.string().required("Please select a category."),
  programName: Yup.string()
    .required("Please enter program name.")
    .max(50, "Program name can be at most 50 characters.")
    .matches(/[a-zA-Z]/, "Program name must contain at least one letter."),
  categoryImage: Yup.mixed().required("Please upload program image."),
  description: Yup.string()
    .required("Please enter description.")
    .max(500, "Description can be at most 500 characters."),
});

export const topicProgram = Yup.object({
  selectStage: Yup.string().required("Please select a stage."),
  categoryName: Yup.string().required("Please select a category."),
  programName: Yup.string()
    .required("Please enter program name.")
    .max(50, "Program name can be atmost 50 characters"),
  categoryImage: Yup.mixed().required("Please upload thumnail image."),
});

export const dietarySchema = Yup.object({
  selectStage: Yup.array()
    .of(Yup.string())
    .min(1, "Please select at least one stage.")
    .required("Please select a stage."),
  dietaryName: Yup.string()
    .required("Please enter dietary name.")
    .max(50, "Dietary name can be at most 50 characters.")
    .matches(
      /^(?!^\d+$)[a-zA-Z0-9\s]+$/,
      "Dietary name should contain characters and not only numbers."
    ),

  // saveAs: Yup.string().required("Please select save as option."),
});
export const cuisineSchema = Yup.object({
  selectStage: Yup.array()
    .of(Yup.string())
    .min(1, "Please select at least one stage.")
    .required("Please select a stage."),
  cuisineName: Yup.string()
    .required("Please enter cuisine name.")
    .max(30, "Cuisine name can be atmost 30 characters.")
    .matches(
      /^(?!^\d+$)[a-zA-Z0-9\s]+$/,
      "Cuisine name should contain characters and not only numbers."
    ),

  saveAs: Yup.string().required("Please select save as option."),
});
export const dietTypeSchema = Yup.object({
  selectStage: Yup.array()
    .of(Yup.string())
    .min(1, "Please select at least one stage.")
    .required("Please select a stage."),
  dietName: Yup.string()
    .max(30, "Diet name must be 30 characters or less.")
    .required("Please enter diet type name."),
  // saveas: Yup.string().required("Please select save as option"),
});

export const recipeTypeSchema = Yup.object({
  selectStage: Yup.array()
    .of(Yup.string())
    .min(1, "Please select at least one stage.")
    .required("Please select a stage."),
  recipeType: Yup.string()
    .max(30, "Diet name must be 30 characters or less.")
    .required("Please enter recipe type name.")
    .matches(
      /^(?!^\d+$)[a-zA-Z0-9\s]+$/,
      "Recipe type should contain characters and not only numbers."
    ),
});

export const topicSchema = Yup.object({
  topicName: Yup.string()
    .required("Please enter topic name.")
    .max(50, "Topic Name cannot exceed 50 characters"),

  categoryImage: Yup.mixed().required("Please upload a thumbnail image."),

  contentDetails: Yup.array()
    .of(
      Yup.object({
        description: Yup.string()
          .max(1200, "Description cannot exceed 1200 characters.")
          .required("Please enter a description."),
        images: Yup.array().of(Yup.string()), // Optional field for images
      })
    )
    .min(1, "At least one description section is required."),
});

export const workoutSubcategory = Yup.object({
  selectStage: Yup.string().required("Please select a stage."),
  workoutSubCategoryName: Yup.string().required(
    "Please enter subcategory name."
  ),
  image: Yup.array().of(Yup.string()).min(1, "At least one image is required."),
  videos: Yup.array()
    .of(Yup.object())
    .min(1, "At least one video is required."),
  exercises: Yup.array()
    .of(Yup.object())
    .min(1, "At least one exercise is required."),

  exercisesInstructions: Yup.string()
    .required("Please enter exercises instructions.")
    .max(1200, "Exercise instructions can be at most 1200 characters"),
  time: Yup.number()
    .positive("Time must be a positive number.")
    .max(400, "Time must not exceed 400 minutes.")
    .required("Please enter time."),

  description: Yup.string()
    .required("Please enter description.")
    .max(1200, "Description can be at most 1200 characters."),
});
