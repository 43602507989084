import React, { useState, useEffect } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  OutlinedInput,
} from "@mui/material";
import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";

const MultiSelectDropdown = ({
  options,
  value = [],
  onChange,
  placeholder,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    // Ensure that value is always an array
    setSelectedOptions(Array.isArray(value) ? value : []);
  }, [value]);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleSelect = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedOptions(value);
    onChange(value); // Directly call onChange with the new value
  };

  return (
    <FormControl fullWidth variant="outlined">
      <Select
        labelId="checkbox-dropdown-label"
        id="checkbox-dropdown"
        open={open}
        onClose={handleToggle}
        onOpen={handleToggle}
        value={selectedOptions}
        onChange={handleSelect}
        IconComponent={(props) => (
          <ArrowDropDownIcon
            {...props}
            style={{ color: "rgba(245, 140, 169, 1)", fontSize: "1.875rem" }}
          />
        )}
        displayEmpty
        multiple
        renderValue={(selected) => {
          if (!Array.isArray(selected) || selected.length === 0) {
            return <span style={{ color: "#afafaf" }}>{placeholder}</span>;
          }
          return (
            <span
              style={{
                display: "inline-block",
                whiteSpace: "pre-wrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
              }}
            >
              {selected
                .map((val) => {
                  const option = options.find((opt) => opt.value === val);
                  return option ? option.key : val;
                })
                .join(", ")}
            </span>
          );
          return (
            <span
              style={{
                display: "inline-block",
                whiteSpace: "pre-wrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
              }}
            >
              {selected
                .map((val) => {
                  const option = options.find((opt) => opt.value === val);
                  return option ? option.key : val;
                })
                .join(", ")}
            </span>
          );
        }}
        input={<OutlinedInput />}
        sx={{
          borderRadius: "5px",
          maxWidth: "100%", // Ensure the dropdown doesn't exceed its container's width
          width: "100%", // Make sure it takes full width of its container
          "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input":
            {
              minHeight: "13px !important",
              lineHeight: "14px !important",
            },
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          PaperProps: {
            style: {
              marginTop: "8px", // Adjust spacing if needed
              padding: "0px",
            },
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox checked={selectedOptions.indexOf(option.value) > -1} />
            <ListItemText
              primary={option.key}
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                padding: "13px",
              }}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelectDropdown;
