import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Button, Tooltip, Typography, Divider } from "@mui/material";
import PaginationTable from "../../../components/PaginationTable";
import { useNavigate } from "react-router-dom";
import BasicButtons from "../../../components/Button";
import AlertDialog from "../../../components/AlertDialog";
import "../../../scss/main.css";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";
import typographyStyles from "../../../theme/Typography";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PublishIcon from "@mui/icons-material/Publish";
import UnpublishIcon from "@mui/icons-material/Unpublished";
import {
  deleteDataHandler,
  getDataHandlerWithToken,
  postDataHandler,
  postDataHandlerWithToken,
  putDataHandlerWithToken,
} from "../../../apiconfig/service";
import moment from "moment";
import FilterBox from "../../../components/FilterBox";
import DataNotFound from "../../../components/DataNotFound";
import DeleteModal from "../../../Modal/DeleteModal";

const columns = [
  { id: "sr_no", label: "Sr.No." },
  { id: "username", label: "Name" },
  { id: "title", label: "Title" },
  { id: "publish", label: "Status" }, // Replaced rating with publish
  { id: "created_at", label: "Created Date & Time" },
  { id: "action", label: "Action" },
];

const TestimonialManagement = () => {
  const Navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [testimonialData, setTestimonialData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const fetchData = async () => {
    try {
      const response = await getDataHandlerWithToken("getAllTestimonials");
      if (response?.responseCode === 200) {
        setTestimonialData(response?.result);
        setFilteredData(response?.result);
      }
      setTestimonialData(response?.result || []);
    } catch (error) {
      console.error("Error in fetching data.", error);
      toast.error("Failed to fetch testimonials data.");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = (testimonialId) => {
    Navigate(
      `/staticcontent/testimonialmanagement/edittestimonial/${testimonialId}`
    );
  };

  const handleClickOpen = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  const handleDelete = async (testimonialId) => {
    try {
      const response = await deleteDataHandler("deleteTestimonial", {
        testimonialId,
      });
      if (response?.responseCode === 200) {
        toast.success("Testimonial deleted successfully");
        setTestimonialData(
          testimonialData.filter((item) => item._id !== testimonialId)
        );

        fetchData();
      } else {
        toast.error(
          response?.responseMessage || "Failed to delete testimonial"
        );
      }
    } catch (error) {
      console.error("Error deleting testimonial", error);
      toast.error("Failed to delete testimonial");
    }
    setOpen(false);
  };

  const handlePublishToggle = async (testimonialId, currentStatus) => {
    try {
      const action = currentStatus ? "unpublish" : "publish";

      const response = await putDataHandlerWithToken(
        "togglePublishTestimonial",
        {
          testimonialId,
          action,
        }
      );

      if (response?.responseCode === 200) {
        toast.success(
          `Testimonial ${
            action === "publish" ? "published" : "unpublished"
          } successfully`
        );
        fetchData();

        setTestimonialData((prevData) =>
          prevData.map((item) =>
            item._id === testimonialId
              ? { ...item, publish: !currentStatus }
              : item
          )
        );
      } else {
        toast.error(
          response?.responseMessage || "Failed to update testimonial status"
        );
      }
    } catch (error) {
      console.error("Error updating testimonial status", error);
      toast.error("Failed to update testimonial status");
    }
  };

  const renderCellContent = (type, value) => {
    switch (type) {
      case "publish":
        return value ? "Published" : "Unpublished"; // Display Publish Status
      case "created_at":
        return moment(value).format("MM/DD/YYYY, h:mm A");
      default:
        return value;
    }
  };

  const handleFilterChange = (filterCriteria) => {
    let filtered = testimonialData;

    if (filterCriteria.searchText) {
      filtered = filtered.filter((category) =>
        category.username
          .toLowerCase()
          .includes(filterCriteria.searchText.toLowerCase().trim())
      );
    }

    const parseCustomDate = (romDate) => {
      const year = romDate.$y;
      const month = romDate.$M; // Months are 0-indexed in JavaScript Date
      const day = romDate.$D;
      const hours = romDate.$H;
      const minutes = romDate.$m;
      const seconds = romDate.$s;
      return new Date(year, month, day, hours, minutes, seconds);
    };

    if (filterCriteria.fromDate) {
      const fromDate = parseCustomDate(filterCriteria.fromDate);
      filtered = filtered.filter((category) => {
        const createdAtDate = new Date(category.createdAt);
        return createdAtDate >= fromDate;
      });
    }

    if (filterCriteria.toDate) {
      const toDate = parseCustomDate(filterCriteria.toDate);
      filtered = filtered.filter((category) => {
        const createdAtDate = new Date(category.createdAt);
        return createdAtDate <= toDate;
      });
    }

    if (
      filterCriteria.selectedValue &&
      filterCriteria.selectedValue !== "All"
    ) {
      // Additional filtering logic if needed
    }

    setFilteredData(filtered);
  };

  return (
    <DashboardLayout
      sectionName="Static Content Management"
      title="Testimonial Management"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={() => Navigate("/staticcontent")}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>
            Testimonial Management
          </Typography>
        </Box>

        <Box className="button-container">
          <BasicButtons
            text="+Add Testimonial"
            onClick={() =>
              Navigate("/staticcontent/testimonialmanagement/addtestimonial")
            }
          />
        </Box>
      </Box>
      <br />
      <br />
      <Divider />
      <Box className="filter-container">
        <FilterBox
          placeholder="Search by name"
          onFilterChange={handleFilterChange}
          dropdownNone={"none"}
        />
      </Box>
      <Box className="StaticScreenContainer">
        <Box>
          {filteredData?.length < 1 ? (
            <DataNotFound />
          ) : (
            <PaginationTable
              columns={columns}
              data={filteredData?.map((item, index) => ({
                sr_no: index + 1,
                username: item?.username,
                title: item?.title,
                publish: item?.publish ? "Published" : "Publish", // Render publish status
                created_at: moment(item.createdAt).format("MM/DD/YYYY, h:mm A"),
                action: (
                  <Box className="row-button-container" sx={{ gap: "15%" }}>
                    <Tooltip title="View">
                      <VisibilityIcon
                        onClick={() =>
                          Navigate(
                            `/staticcontent/testimonialmanagement/viewtestimonial/${item?._id}`
                          )
                        }
                        sx={{
                          color: "rgb(225,140,168)",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Edit">
                      <EditIcon
                        onClick={() => handleEdit(item?._id)}
                        sx={{
                          color: "rgb(225,140,168)",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Delete">
                      <DeleteIcon
                        onClick={() => handleClickOpen(item?._id)}
                        sx={{
                          color: "rgb(225,140,168)",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                    <Tooltip title={item.publish ? "Unpublish" : "Publish"}>
                      {item.publish ? (
                        <UnpublishIcon
                          onClick={() =>
                            handlePublishToggle(item._id, item.publish)
                          }
                          sx={{
                            color: "rgb(225,140,168)",
                            cursor: "pointer",
                          }}
                        />
                      ) : (
                        <PublishIcon
                          onClick={() =>
                            handlePublishToggle(item._id, item.publish)
                          }
                          sx={{
                            color: "rgb(225,140,168)",
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </Tooltip>
                  </Box>
                ),
              }))}
            />
          )}
        </Box>
      </Box>

      {open && (
        <DeleteModal
          open={open}
          handleClose={handleClose}
          title={"Delete Testimonial"}
          description={"Are you sure you want to delete this testimonial?"}
          handleDelete={() => handleDelete(selectedRow)}
        />
      )}
    </DashboardLayout>
  );
};

export default TestimonialManagement;
