import React, { useState, useEffect } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Button, Divider, Typography, Tooltip } from "@mui/material";
import FilterBox from "../../../components/FilterBox";
import BasicButtons from "../../../components/Button";
import PaginationTable from "../../../components/PaginationTable";
import "../../../scss/main.css";
import { useNavigate } from "react-router-dom";
import BlockModal from "../../../Modal/BlockModal";
import { saveAs } from "file-saver";
import {
  getDataHandlerWithToken,
  putDataHandlerWithToken,
} from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import typographyStyles from "../../../theme/Typography";
import Loading from "../../../components/Loading";
import DataNotFound from "../../../components/DataNotFound";

const columns = [
  { id: "sr_no", label: "Sr.No." },
  { id: "option", label: "Option" },
  { id: "option_name", label: "Option Name" },
  { id: "created_at", label: "Created At" },
  { id: "action", label: "Action" },
];
const se = [
  { key: "Language", value: "LANGUAGE" },
  { key: "Exercise", value: "EXERCISE" },
  { key: "Other than a mum", value: "OTHERTHANMUM" },
  { key: "Other", value: "OTHER" },
];

const OptionListing = () => {
  const [optionList, setOptionList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [render, setRender] = useState(false);
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const [blockOptionId, setBlockOptionId] = useState(null);
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleEdit = (obj) => {
    navigate("/optionlisting/editoption", { state: { obj } });
  };

  const handleBlock = (id, status, name) => {
    setBlockOptionId(id);
    setStatus(status === "ACTIVE" ? "BLOCK" : "ACTIVE");
    setName(name);
    setBlockModalOpen(true);
  };

  const handleBlockConfirm = async (id) => {
    const query = {
      optionId: blockOptionId,
    };

    try {
      const res = await putDataHandlerWithToken(
        "blockUnblockOption",
        {},
        query
      );
      if (res.responseCode === 200) {
        const updatedOptionList = optionList.map((option) =>
          option._id === id ? { ...option, status: status } : option
        );
        setOptionList(updatedOptionList);
        setFilteredData(updatedOptionList);
        setRender(!render);
        toast.success(res?.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
    setBlockModalOpen(false);
  };

  const handleBlockCancel = () => {
    setBlockModalOpen(false);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const query = { limit: Number.MAX_SAFE_INTEGER };
      const res = await getDataHandlerWithToken("listOptions", query); // Change the API endpoint to listOptions
      if (res.responseCode === 200) {
        setOptionList(res.result.docs || []);
        setFilteredData(res.result.docs);
      } else {
        toast.error(res.data.responseText);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [render]);

  const handleFilterChange = (filterCriteria) => {
    let filtered = optionList;

    if (filterCriteria.searchText) {
      filtered = filtered.filter((option) =>
        option.optionName
          .toLowerCase()
          .includes(filterCriteria.searchText.toLowerCase().trim())
      );
    }

    const parseCustomDate = (romDate) => {
      const year = romDate.$y;
      const month = romDate.$M;
      const day = romDate.$D;
      const hours = romDate.$H;
      const minutes = romDate.$m;
      const seconds = romDate.$s;
      return new Date(year, month, day, hours, minutes, seconds);
    };

    if (filterCriteria.fromDate) {
      const fromDate = parseCustomDate(filterCriteria.fromDate);
      filtered = filtered.filter((option) => {
        const createdAtDate = new Date(option.createdAt);
        return createdAtDate >= fromDate;
      });
    }

    if (filterCriteria.toDate) {
      const toDate = parseCustomDate(filterCriteria.toDate);
      filtered = filtered.filter((option) => {
        const createdAtDate = new Date(option.createdAt);
        return createdAtDate <= toDate;
      });
    }

    if (
      filterCriteria.selectedValue &&
      filterCriteria.selectedValue !== "All"
    ) {
      filtered = filtered.filter(
        (option) => option.stage === filterCriteria.selectedValue
      );
    }

    setFilteredData(filtered);
  };

  const handleDownloadCSV = () => {
    const dataWithoutId = optionList.map(({ _id, ...rest }) => rest);
    const headers = Object.keys(dataWithoutId[0]);
    let csvContent = headers.join(",") + "\n";
    csvContent += dataWithoutId
      .map((row) => headers.map((header) => row[header]).join(","))
      .join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;
    const filename = `option_list_${formattedDate}.csv`;
    saveAs(blob, filename);
  };

  return (
    <DashboardLayout>
      <Box className="category-container">
        <Box className="heading-container-top">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ ...typographyStyles.h4 }}>
              Option Listing
            </Typography>
          </Box>

          <Box className="button-container">
            <Box className="button-sub-container">
              <BasicButtons text="Download CSV" onClick={handleDownloadCSV} />
              <BasicButtons
                text="+Add Option"
                onClick={() => {
                  navigate("/optionlisting/addoption");
                }}
              />
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box className="filter-container">
          <FilterBox
            placeholder="Enter option name"
            onFilterChange={handleFilterChange}
            dropdownNone={"none"}
          />
        </Box>

        <Box className="table-container">
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <Loading />
            </Box>
          ) : filteredData?.length < 1 ? (
            <DataNotFound />
          ) : (
            <PaginationTable
              columns={columns}
              data={filteredData.map((obj, index) => ({
                sr_no: index + 1,
                option: (
                  <Tooltip
                    title={
                      se.find((option) => option.value === obj.optionType)
                        ?.key || obj.optionType
                    }
                    arrow
                  >
                    <span>
                      {se.find((option) => option.value === obj.optionType)?.key
                        .length > 20
                        ? `${se
                            .find((option) => option.value === obj.optionType)
                            ?.key.substring(0, 20)}...`
                        : se.find((option) => option.value === obj.optionType)
                            ?.key || obj.optionType}
                    </span>
                  </Tooltip>
                ),
                option_name: (
                  <Tooltip title={obj.optionName} arrow>
                    <span>
                      {obj.optionName.length > 20
                        ? `${obj.optionName.substring(0, 20)}...`
                        : obj.optionName}
                    </span>
                  </Tooltip>
                ),
                created_at: new Date(obj.createdAt).toLocaleString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                }),
                action: (
                  <Box className="row-button-container" sx={{ gap: "15%" }}>
                    <EditIcon
                      onClick={() => handleEdit(obj)}
                      sx={{
                        color: "rgb(225,140,168)",
                        borderColor: "rgb(225,140,168)",
                        fontFamily: "'Poppins', sans-serif",
                        cursor: "pointer",
                      }}
                    />
                    <BlockIcon
                      onClick={() =>
                        handleBlock(obj._id, obj.status, obj.optionName)
                      }
                      sx={{
                        color: obj.status === "ACTIVE" ? "green" : "red",
                        borderColor: "rgb(225,140,168)",
                        fontFamily: "'Poppins', sans-serif",
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                ),
              }))}
            />
          )}
        </Box>
      </Box>
      {blockModalOpen && (
        <BlockModal
          title="OPTION"
          status={status}
          name={name}
          onClose={handleBlockCancel}
          onBlock={handleBlockConfirm}
        />
      )}
    </DashboardLayout>
  );
};

export default OptionListing;
