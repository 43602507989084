import React, { useState, useEffect } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Button, Typography, Divider } from "@mui/material";
import typographyStyles from "../../../theme/Typography";
import BasicButtons from "../../../components/Button";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import "../../../scss/main.css";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "../../../scss/main.css";
import { useNavigate } from "react-router-dom";
import {
  getDataHandler,
  patchTokenDataHandler,
} from "../../../apiconfig/service";

import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";

const privacyPolicyStyle = {
  privacyPolicyContainer: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  privacyPolicyTitle: {
    ...typographyStyles.h4,
  },
  buttonSection: {
    padding: "0 25%",
    display: "flex",
    gap: "2%",
  },
  editorContainer: {
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "4px",
    padding: "10px",
  },
  editor: {
    minHeight: "300px",
  },
};

const CHAR_LIMIT = 1200;

const EditAboutUs = () => {
  const [content, setContent] = useState([]);
  const [charCount, setCharCount] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch data when component mounts
    getDataHandler("getAllstaticContentByType", { contentType: "ABOUTUS" })
      .then((response) => {
        if (response?.responseCode === 200) {
          const contentData = response?.result || [];
          setContent(contentData);
          // Initialize charCount state for each item
          const initialCharCount = contentData.reduce((acc, item) => {
            acc[item._id] = item.description.length;
            return acc;
          }, {});
          setCharCount(initialCharCount);
        } else {
          toast.error(response?.responseMessage || "Failed to fetch ABOUTUS");
        }
      })
      .catch((error) => {
        console.error("Error in fetching data", error);
        toast.error("Failed to fetch ABOUTUS");
      });
  }, []);

  const handleUpdate = async (editorContent, staticContentId) => {
    if (charCount[staticContentId] > CHAR_LIMIT) {
      toast.error(`Character limit of ${CHAR_LIMIT} exceeded.`);
      return;
    }

    const updatedContent = {
      contentType: "ABOUTUS",
      description: editorContent,
      staticContentId: staticContentId,
    };

    try {
      const response = await patchTokenDataHandler(
        "updateStaticContent",
        updatedContent
      );
      if (response?.responseCode === 200) {
        toast.success("ABOUTUS updated successfully");
        navigate("/staticcontent");
      } else {
        toast.error(response?.responseMessage || "Failed to update about us");
      }
    } catch (error) {
      toast.error("Failed to update about us");
    }
  };

  const handleEditorChange = (data, staticContentId) => {
    const charLength = data.length;
    if (charLength <= CHAR_LIMIT) {
      setContent((prevContent) =>
        prevContent.map((c) =>
          c._id === staticContentId ? { ...c, description: data } : c
        )
      );
      setCharCount((prevCharCount) => ({
        ...prevCharCount,
        [staticContentId]: charLength,
      }));
    } else {
      toast.error(`Character limit of ${CHAR_LIMIT} exceeded.`);
    }
  };

  return (
    <DashboardLayout
      sectionName="Static Content Management"
      title="Edit About Us"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>Edit About Us</Typography>
        </Box>
      </Box>
      <Divider />
      {content.map((item) => (
        <Box key={item._id} className="StaticScreenContainer">
          <Box
            className="StaticEditorContainer"
            sx={privacyPolicyStyle.editorContainer}
          >
            <CKEditor
              editor={ClassicEditor}
              data={item.description}
              onChange={(event, editor) => {
                const data = editor.getData();
                handleEditorChange(data, item._id);
              }}
              config={{
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "underline",
                  "strikethrough",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "blockQuote",
                  "|",
                  "link",
                  "|",
                  "undo",
                  "redo",
                ],
                height: "400px",
              }}
              className="StaticEditor"
            />
            <Typography
              variant="body2"
              color={
                charCount[item._id] > CHAR_LIMIT ? "error" : "textSecondary"
              }
              align="right"
              sx={{ mt: 1 }}
            >
              {charCount[item._id] || 0}/{CHAR_LIMIT} characters
            </Typography>
          </Box>
          <Box className="StaticBtnContainer">
            <BasicButtons
              text="Update"
              name="submit"
              type="button"
              onClick={() => handleUpdate(item.description, item._id)}
              width={"20%"}
            />
          </Box>
          <Divider sx={{ my: 2 }} />
        </Box>
      ))}
    </DashboardLayout>
  );
};

export default EditAboutUs;
