import { Box, styled } from "@mui/material";
import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const MainContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
});

const ContentSection = styled(Box)(({ theme }) => ({
    // border: '2px solid pink',
    marginLeft: '25%',
    marginRight: '25%',
    paddingTop: '5%',
    // paddingBottom: '5%',
    height: '100vh',
    [theme.breakpoints.down('md')]: {
        marginLeft: '20%',
        marginRight: '20%',
    },
    [theme.breakpoints.down('sm')]: {
        marginLeft: '10%',
        marginRight: '10%',
    },
}));

const footerSection = {
    left: '0',
    right: '0',
    bottom: '0'
}

const LoginLayout = ({ children }) => {
    return (
        <MainContainer>
            <Box><Header /></Box>
            <ContentSection>
                {children}
            </ContentSection>
            <Box sx={footerSection}><Footer /></Box>
        </MainContainer>
    );
};

export default LoginLayout;
