import React, { useEffect, useState } from "react";
import { Box, Typography, Divider, Tooltip, IconButton } from "@mui/material";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import PaginationTable from "../../../components/PaginationTable";
import { useNavigate } from "react-router-dom";
import backBtn from "../../../assets/backBtn.svg";

import { toast } from "react-hot-toast";
import typographyStyles from "../../../theme/Typography";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "../../../Modal/DeleteModal";
import {
  getDataHandlerWithToken,
  deleteDataHandler,
} from "../../../apiconfig/service";
import moment from "moment";
import DataNotFound from "../../../components/DataNotFound";
import BasicButtons from "../../../components/Button";

const columns = [
  { id: "sr_no", label: "Sr.No." },
  { id: "name", label: "Name" },
  { id: "designation", label: "Designation" },
  { id: "created_at", label: "Created At" },
  { id: "action", label: "Action" },
];

const ListTeam = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [teamData, setTeamData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDataHandlerWithToken("getAllTeam");
        if (response?.responseCode === 200) {
          setTeamData(response?.result?.docs || []);
        } else {
          //   toast.error(response?.responseMessage || "Failed to fetch team data");
        }
      } catch (error) {
        console.error("Error in fetching data", error);
        toast.error("Failed to fetch team data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleEdit = (team) => {
    navigate(`/staticcontent/team/editTeam`, {
      state: { team },
    });
  };

  const handleClickOpen = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  const handleDeleteConfirm = async () => {
    if (!selectedRow) return;

    try {
      const response = await deleteDataHandler("deleteTeamMember", {
        teamId: selectedRow._id,
      });
      console.log(response);
      if (response?.responseCode === 200) {
        toast.success("Team member deleted successfully");
        setTeamData(teamData.filter((item) => item._id !== selectedRow._id));
      } else {
        toast.error(
          response?.responseMessage || "Failed to delete team member"
        );
      }
    } catch (error) {
      console.error("Error deleting team member", error);
      toast.error("Failed to delete team member");
    } finally {
      handleClose();
    }
  };

  return (
    <DashboardLayout sectionName="Team Management" title="Team List">
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/staticcontent")}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>
            Team Management
          </Typography>
        </Box>

        <Box className="button-container">
          <BasicButtons
            text="+Add Team Member"
            onClick={() => navigate("/staticcontent/team/addTeam")}
          />
        </Box>
      </Box>
      <br />
      <Divider />
      <Box className="TeamScreenContainer">
        {teamData?.length < 1 ? (
          <DataNotFound />
        ) : (
          <PaginationTable
            columns={columns}
            data={teamData?.map((item, index) => ({
              sr_no: index + 1,
              name: (
                <Tooltip title={item.memberName} placement="top">
                  <Typography variant="body2">{item.memberName}</Typography>
                </Tooltip>
              ),
              designation: (
                <Tooltip title={item.designation} placement="top">
                  <Typography variant="body2">{item.designation}</Typography>
                </Tooltip>
              ),
              created_at: moment(item.createdAt).format("MM/DD/YYYY, h:mm A"),
              action: (
                <Box className="row-button-container">
                  <IconButton
                    onClick={() => handleEdit(item)}
                    sx={{ color: "rgb(225,140,168)" }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleClickOpen(item)}
                    sx={{ color: "rgb(225,140,168)" }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ),
            }))}
          />
        )}
      </Box>
      {open && (
       <DeleteModal
       open={open}
       handleClose={handleClose}
       handleDelete={handleDeleteConfirm}
       title={selectedRow?.memberName}
       
     />
     
      )}
    </DashboardLayout>
  );
};

export default ListTeam;
