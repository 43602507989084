// utils/imageUtils.js

import { getCroppedImg } from "./cropImage"; // Ensure this path is correct
import { postDataHandler} from "../apiconfig/service"; // Ensure this path is correct

export const cropAndUploadImage = async (image, croppedAreaPixels) => {
  try {
    // Crop the image
    const croppedImageBlob = await getCroppedImg(image, croppedAreaPixels);
    const formData = new FormData();
    formData.append("file", croppedImageBlob);

    // Upload to Cloudinary
    const uploadRes = await postDataHandler("uploadFile", formData);
    if (uploadRes.responseCode === 200) {
      console.log(uploadRes.result)
      return uploadRes.result; // Return the Cloudinary URL
    } else {
      throw new Error("Error while uploading file");
    }
  } catch (error) {
    console.error(error);
    throw error; // Re-throw the error to be handled by the calling code
  }
};

