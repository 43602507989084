import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Card, CardMedia, Divider, Typography } from "@mui/material";
import typographyStyles from "../../../theme/Typography";
import InputField from "../../../components/InputField";
import BasicButtons from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import { postDataHandlerWithToken } from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";
import "../../../scss/main.css";
import ImageCropper from "../../../utils/ImageCropper";

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
};

const AddCards = () => {
  const navigate = useNavigate();
  const [thumbnailPreview, setThumbnailPreview] = useState(null);
  const [bgPreview, setBgPreview] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedField, setSelectedField] = useState(null);
  const [categoryImageUrl, setCategoryImageUrl] = useState(null);
  const [bgImageUrl, setBgImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object({
    bannerTitle: Yup.string()
      .min(5, "Banner title must be at least 5 characters.")
      .max(25, "Banner title must be 25 characters or less.")
      .required("Please enter the banner title."),
    description: Yup.string()
      .min(10, "Description must be at least 10 characters.")
      .max(100, "Description must be 100 characters or less.")
      .required("Please enter the description."),
    categoryImage: Yup.mixed().required("Please upload the image."),
    bgImage: Yup.mixed().required("Please upload the background image."),
  });

  const formik = useFormik({
    initialValues: {
      bannerTitle: "",
      description: "",
      categoryImage: null,
      bgImage: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);

        const data = {
          title: values.bannerTitle,
          description: values.description,
          imageUrl: categoryImageUrl,
          bgImg: bgImageUrl,
          contentType: "CARDSBANNER",
        };

        const response = await postDataHandlerWithToken(
          "addStaticContent",
          data
        );
        toast.success("Banner added successfully");
        navigate("/staticcontent/cardsBanner");
      } catch (error) {
        console.error("Error adding banner:", error);
        toast.error("Failed to add banner. Please try again later.");
      } finally {
        setLoading(false);
      }
    },
  });

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    setFieldValue,
  } = formik;

  const handleThumbnailChange = (event, fieldName) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setSelectedField(fieldName);
      setFieldValue(fieldName, file);
    }
  };

  const handleCropComplete = (croppedUrl) => {
    if (selectedField === "categoryImage") {
      setCategoryImageUrl(croppedUrl);
      setThumbnailPreview(croppedUrl);
    } else if (selectedField === "bgImage") {
      setBgImageUrl(croppedUrl);
      setBgPreview(croppedUrl);
    }
    setSelectedImage(null);
  };

  return (
    <DashboardLayout sectionName="Static Content Management" title="Add Banner">
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>Add Banner</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <form className="add-edit-content-section" onSubmit={handleSubmit}>
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Banner Title:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="bannerTitle"
                  placeholder="Enter the banner title"
                  value={values.bannerTitle}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={50}
                />
                {errors.bannerTitle && touched.bannerTitle ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.bannerTitle}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Description:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="description"
                  placeholder="Enter the description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={100}
                />
                {errors.description && touched.description ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.description}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Upload Image:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="file"
                  name="categoryImage"
                  onBlur={handleBlur}
                  onChange={(e) => handleThumbnailChange(e, "categoryImage")}
                  acceptedFile="image/*"
                />
                {errors.categoryImage && touched.categoryImage ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.categoryImage}
                  </p>
                ) : null}{" "}
                <br />
                {thumbnailPreview && (
                  <Box sx={{ marginTop: "10px" }}>
                    <Card sx={{ maxWidth: 200, maxHeight: 200 }}>
                      <CardMedia component="img" image={thumbnailPreview} />
                    </Card>
                  </Box>
                )}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Upload Background Image:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="file"
                  name="bgImage"
                  onBlur={handleBlur}
                  onChange={(e) => handleThumbnailChange(e, "bgImage")}
                  acceptedFile="image/*"
                />
                {errors.bgImage && touched.bgImage ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.bgImage}
                  </p>
                ) : null}{" "}
                
                {bgPreview && (
                  <Box sx={{ marginTop: "10px" }}>
                    <Card sx={{ maxWidth: 200, maxHeight: 200 }}>
                      <CardMedia component="img" image={bgPreview} />
                    </Card>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            className="add-edit-button-section"
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <BasicButtons
              text="Add"
              name="submit"
              type="submit"
              disabled={loading}
              loading={loading}
              width={"20%"}
            />
          </Box>
        </form>
      </Box>
      {selectedImage && (
        <ImageCropper
          image={selectedImage}
          aspectRatio={selectedField === "categoryImage" ? 4 / 3 : 16 / 9}
          onCropComplete={handleCropComplete}
          onClose={() => setSelectedImage(null)}
        />
      )}
    </DashboardLayout>
  );
};

export default AddCards;
