import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Card,
  CardMedia,
} from "@mui/material";
import "../../../scss/main.css";
import typographyStyles from "../../../theme/Typography";
import ViewImg from "../../../assets/viewImg.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { getDataHandlerWithToken } from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
  addEditInfo: {  fontWeight: "normal",
  wordBreak: "break-word", // Ensure text wraps within the cell
  overflow: "hidden", // Hide overflow content
  textOverflow: "ellipsis", // Show ellipsis (...) for overflowing text
  
  },
};

const stripHtml = (html) => {
  let doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
};
const se = [
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum (0-6 Weeks After Birth)", value: "POSTPARTUM" },
  {
    key: "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
    value: "BEYOND",
  },
];
const ViewProgram = () => {
  const [program, setProgram] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const fetchProgram = async () => {
    const query = {
      programId: location.state?.programId,
    };
    try {
      const res = await getDataHandlerWithToken("viewProgram", query);
      console.log(res?.result);
      if (res.responseCode === 200) {
        setProgram(res?.result);
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProgram();
  }, []);

  console.log(location.state?.programId);
  const programStage = program?.stage;
  const matchingKey = se.find((opt) => opt.value === programStage)?.key || "";

  return (
    <DashboardLayout
      sectionName="Content - Program Management"
      title="Program General Details"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{
                cursor: "pointer",
              }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>View Program</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <Box className="add-edit-content-section">
          <Box className="add-edit-content">
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>Stage:</TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {matchingKey}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Category:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {program?.categoryName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Program Name:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {program?.programName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Program Thumbnail Image:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      <Card sx={{ maxWidth: 300, maxHeight: "auto" }}>
                        <CardMedia
                          component="img"
                          image={program?.programThumbnailImage}
                        />
                      </Card>
                    </TableCell>
                  </TableRow>
                  {program?.description && (
                    <TableRow>
                      <TableCell sx={addEditStyle.addEditLabel}>
                        Description:
                      </TableCell>
                      <TableCell sx={addEditStyle.addEditInfo}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: program?.description,
                          }}
                          style={{ padding: "10px" }}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default ViewProgram;
