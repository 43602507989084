import React, { useEffect, useState, useCallback } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  Card,
  CardMedia,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import typographyStyles from "../../../theme/Typography";
import BasicButtons from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import InputField from "../../../components/InputField";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import {
  addCategorySchema,
  addNewCategorySchema,
  editCategorySchema,
} from "../../../schema";
import {
  getCategoryWithToken,
  postDataHandler,
  postDataHandlerWithToken,
} from "../../../apiconfig/service";
import toast from "react-hot-toast";
import "../../../scss/main.css";
import backBtn from "../../../assets/backBtn.svg";
import Cropper from "react-easy-crop";
import ReactModal from "react-modal";
import { getCroppedImg } from "../../../utils/cropImage";
import { modalStyles } from "../../../scss/style";

// Styles for the modal and cropping area
const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
  radioContainer: {
    display: "flex",
  },
};

const dropdownOptions = [
  "Trying to Conceive",
  "Pregnant",
  "Postpartum (0-6 Weeks After Birth)",
  "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
];

const se = [
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum (0-6 Weeks After Birth)", value: "POSTPARTUM" },
  {
    key: "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
    value: "BEYOND",
  },
];

const EditCategory = () => {
  const [categoryId, setCategoryId] = useState(null);
  const [categoryType, setCategoryType] = useState("FREE");
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailPreview, setThumbnailPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [newImageSelected, setNewImageSelected] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location?.state?.categoryId) {
      setCategoryId(location.state.categoryId);
    }
  }, [location]);

  useEffect(() => {
    const fetchCategory = async () => {
      if (!categoryId) return;
      try {
        const result = await getCategoryWithToken("viewCategory", {
          categoryId,
        });
        if (result.responseCode === 200) {
          const categoryData = result.result;

          setValues({
            selectStage: se.find(
              (option) => option.value === categoryData.stage
            ).key,
            categoryName: categoryData.categoryName,
          });
          setCategoryType(categoryData.saveAs);
          setThumbnail(categoryData.image);
          setThumbnailPreview(categoryData.image);
        } else {
          toast.error(result.responseMessage);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchCategory();
  }, [categoryId]);

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    setValues,
  } = useFormik({
    initialValues: {
      selectStage: "",
      categoryName: "",
    },
    validationSchema: editCategorySchema,

    onSubmit: async (values) => {
      const formData = new FormData();
      if (newImageSelected) {
        formData.append("file", croppedImage);
      }

      try {
        setLoading(true);
        let imageUploadResult = null;
        if (newImageSelected) {
          imageUploadResult = await postDataHandler("uploadFile", formData);
          if (imageUploadResult.responseCode !== 200) {
            console.log("Error while uploading file");
            return;
          }
        }

        const res = await postDataHandlerWithToken("addUpdateCategory", {
          categoryId: categoryId,
          categoryName: values.categoryName,
          stage: se.find((option) => option.key === values.selectStage).value,
          saveAs: categoryType,
          image: newImageSelected ? imageUploadResult.result : thumbnail,
        });

        if (res.responseCode === 200) {
          toast.success(res.responseMessage);
          navigate("/category");
        } else {
          toast.error(res.data.responseMessage);
        }
      } catch (error) {
        console.error("Failed to add category:", error);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleDropdownChange = (value) => {
    setValues({ ...values, selectStage: value });
  };

  const handleCategoryTypeChange = (event) => {
    setCategoryType(event.target.value);
  };

  const handleThumbnailChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setIsModalOpen(true);
      setNewImageSelected(true); // Mark new image as selected
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCrop = useCallback(async () => {
    try {
      const croppedImageBlob = await getCroppedImg(
        selectedImage,
        croppedAreaPixels
      );
      setCroppedImage(croppedImageBlob);
      setThumbnailPreview(URL.createObjectURL(croppedImageBlob));
      setThumbnail(croppedImageBlob);
      setIsModalOpen(false);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, selectedImage]);

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
    setCroppedImage(null);
    setThumbnailPreview(thumbnail); // Revert to the original thumbnail if canceling
    setNewImageSelected(false); // Mark new image as not selected
  };

  return (
    <DashboardLayout sectionName="Category Management" title="Edit Category">
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{
                cursor: "pointer",
              }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>Edit Category</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <form className="add-edit-content-section" onSubmit={handleSubmit}>
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Select Stage:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <Dropdown
                  name="selectRange"
                  value={values.selectStage}
                  onBlur={handleBlur}
                  sx={addEditStyle.categoryInputField}
                  options={dropdownOptions}
                  onChange={handleDropdownChange}
                />
                {errors.selectStage && touched.selectStage ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.selectStage}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Category Name:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="categoryName"
                  value={values.categoryName}
                  maxLength={50}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter category name"
                />
                {errors.categoryName && touched.categoryName ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.categoryName}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>Save As:</Typography>
              </Box>
              <Box className="add-edit-input-field">
                <FormControl component="fieldset">
                  <RadioGroup
                    name="categoryType"
                    value={categoryType}
                    onChange={handleCategoryTypeChange}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <FormControlLabel
                      className="radio"
                      value="FREE"
                      control={<Radio />}
                      label="Free"
                    />
                    <FormControlLabel
                      className="radio"
                      value="LOCKED"
                      control={<Radio />}
                      label="Locked (will be accessed after subscription)"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Program Thumbnail Image:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="file"
                  name="categoryImage"
                  onBlur={handleBlur}
                  onChange={handleThumbnailChange}
                  acceptedFile="image/*"
                />
                {thumbnailPreview && (
                  <Box mt={2}>
                    <Card sx={{ maxWidth: 300, maxHeight: "auto" }}>
                      <CardMedia component="img" image={thumbnailPreview} />
                    </Card>
                  </Box>
                )}
                {errors.categoryImage && touched.categoryImage ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.categoryImage}
                  </p>
                ) : null}
              </Box>
            </Box>
          </Box>
          <Box
            className="add-edit-button-section"
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <BasicButtons
              text={"Edit Category"}
              disabled={loading}
              name="submit"
              type="submit"
              loading={loading}
              width={"20%"}
            />
          </Box>
        </form>
      </Box>
      {/* Image Cropping Modal */}
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={modalStyles}
        contentLabel="Crop Image Modal"
      >
        <Box sx={{ position: "relative", height: "100%" }}>
          <Cropper
            image={selectedImage}
            crop={crop}
            zoom={zoom}
            aspect={17 / 12} // Set the aspect ratio as required
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              p: 2,
              gap: 5,
            }}
          >
            <BasicButtons text="Cancel" onClick={handleCancel} />
            <BasicButtons text="Crop" onClick={handleCrop} />
          </Box>
        </Box>
      </ReactModal>
    </DashboardLayout>
  );
};

export default EditCategory;
