import React, { useState, useEffect } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Card,
  CardMedia,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { getDataHandlerWithToken } from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";

const addEditStyle = {
  addEditLabel: {
    fontWeight: "bold",
  },
  addEditInfo: {
    fontWeight: "normal",
  },
};

const ViewTestimonial = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [testimonial, setTestimonial] = useState(null);

  useEffect(() => {
    const fetchTestimonial = async () => {
      try {
        const response = await getDataHandlerWithToken("getTestimonial", {
          testimonialId: id,
        });
        if (response?.responseCode === 200) {
          setTestimonial(response.result);
        } else {
          toast.error(
            response?.responseMessage || "Failed to fetch testimonial"
          );
        }
      } catch (error) {
        console.error("Error fetching testimonial:", error);
        toast.error("Failed to fetch testimonial data.");
      }
    };

    fetchTestimonial();
  }, [id]);

  if (!testimonial) {
    return (
      <DashboardLayout
        sectionName="View Testimonial"
        title="Testimonial Not Found"
      >
        <Box sx={{ padding: "2rem" }}>
          <Typography variant="h5">Testimonial not found</Typography>
        </Box>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout
      sectionName="Static Content Management"
      title="View Testimonial"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography variant="h4">View Testimonial</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <Box className="add-edit-content-section">
          <Box className="add-edit-content">
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>Name:</TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {testimonial?.username}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>Title:</TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {testimonial?.title}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Description:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {testimonial?.description}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Rating:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {testimonial?.rating}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Profile Pic:
                    </TableCell>
                    <TableCell>
                      <Card sx={{ maxWidth: 200, maxHeight: 200 }}>
                        <CardMedia
                          component="img"
                          image={testimonial?.profilePic}
                        />
                      </Card>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>Video:</TableCell>
                    <TableCell>
                      <Card sx={{ maxWidth: 200, maxHeight: 200 }}>
                        <CardMedia
                          component="video"
                          src={testimonial?.video}
                          controls
                        />
                      </Card>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default ViewTestimonial;
