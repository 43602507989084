import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  Card,
  CardMedia,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import typographyStyles from "../../../theme/Typography";
import backBtn from "../../../assets/backBtn.svg";
import "../../../scss/main.css";

const viewStyle = {
  viewTitle: {
    ...typographyStyles.h4,
  },
  viewLabel: {
    ...typographyStyles.h6,
  },
  viewInfo: {
    ...typographyStyles.h6,
    fontWeight: "300",
  },
};

const ViewClinicalResult = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const clinicalResult = location.state?.clinicalResult;

  if (!clinicalResult) {
    return <Typography>No clinical result found.</Typography>;
  }

  return (
    <DashboardLayout
      sectionName="Clinical Results Management"
      title="View Clinical Result"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>
            View Clinical Result
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="view-container" sx={{ padding: "2%" }}>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={viewStyle.viewLabel}>Title:</TableCell>
                <TableCell sx={viewStyle.viewInfo}>{clinicalResult.title}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={viewStyle.viewLabel}>Percentages:</TableCell>
                <TableCell sx={viewStyle.viewInfo}>{clinicalResult.percentages} %</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={viewStyle.viewLabel}>Description:</TableCell>
                <TableCell sx={viewStyle.viewInfo}>
                  <div dangerouslySetInnerHTML={{ __html: clinicalResult.description }} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={viewStyle.viewLabel}>Image:</TableCell>
                <TableCell sx={viewStyle.viewInfo}>
                  {clinicalResult.imageUrl && (
                    <Card sx={{ maxWidth: 200, }}>
                      <CardMedia
                        component="img"
                        image={clinicalResult.imageUrl}
                        alt={clinicalResult.title}
                      />
                    </Card>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </DashboardLayout>
  );
};

export default ViewClinicalResult;
