import React, { useState, useEffect } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Button, Tooltip, Typography, Divider } from "@mui/material";

import FilterBox from "../../../components/FilterBox";
import BasicButtons from "../../../components/Button";
import PaginationTable from "../../../components/PaginationTable";
import DataNotFound from "../../../components/DataNotFound";

import "../../../scss/main.css";
import { useNavigate, useLocation } from "react-router-dom";
import { saveAs } from "file-saver";
import {
  getDataHandlerWithToken,
  putDataHandlerWithToken,
} from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import BlockModal from "../../../Modal/BlockModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import typographyStyles from "../../../theme/Typography";
import backBtn from "../../../assets/backBtn.svg";
import LoadingPage from "../LoadingPage/LoadingPage";
import Loading from "../../../components/Loading";

const columns = [
  { id: "sr_no", label: "Sr.No." },
  { id: "stage", label: "Stage" },
  { id: "Subcategory_name", label: "Workout Subcategory Name" },

  { id: "created_date_time", label: "Created Date & Time" },
  { id: "action", label: "Action" },
];
const se = [
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum (0-6 Weeks After Birth)", value: "POSTPARTUM" },
  {
    key: "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
    value: "BEYOND",
  },
];

const WorkoutSubCategory = () => {
  const [programList, setProgramList] = useState([]);
  const [topicList, setTopicList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [render, setRender] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [filteredTopicList, setFilteredTopicList] = useState([]);
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const [blockId, setBlockId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState();
  const [name, setName] = useState();
  const [noData, setNoData] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  //   const fetchData = async () => {
  //     try {
  //       const res = await getDataHandlerWithToken("listProgram");
  //       console.log("API response:", res);
  //       if (res.responseCode === 200) {
  //         setProgramList(res?.result?.docs || []);
  //         setFilteredData(res?.result?.docs)
  //       } else {
  //         toast.error(res?.data?.responseMessage);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  const fetchTopic = async () => {
    try {
      setLoading(true);
      const query = {
        limit: Number.MAX_SAFE_INTEGER,
      };
      const res = await getDataHandlerWithToken(
        "listWorkoutSubCategory",
        query
      );
      console.log("API response:", res);
      if (res.responseCode === 200) {
        setTopicList(res?.result?.docs || []);
        const programId = location?.state?.categoryId;
        console.log(programId);
        const filteredTopic = res?.result?.docs.filter(
          (program) => program.workoutCategoryId === programId,
        );
        console.log("API response:", filteredTopic);
        filteredTopic.length===0 ? setNoData(true):setNoData(false);

        setFilteredTopicList(filteredTopic);
        setFilteredData(filteredTopic);
      } else {
        setNoData(true);
      }
    } catch (error) {
      console.log(error);
      
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTopic();
  }, [render, toggle]);
  // useEffect(() => {
  //   //filteredTopic.length===0 ? setNoData(true):setNoData(false);
  //   setLoading(false);
  // }, [topicList]);
  const handleFilterChange = (filterCriteria) => {
    let filtered = filteredTopicList;
    console.log(filterCriteria);

    if (filterCriteria.searchText) {
      filtered = filtered.filter((category) =>
        category.workoutSubCategoryName
          .toLowerCase()
          .includes(filterCriteria.searchText.toLowerCase().trim())
      );
      console.log("filtered: ", filtered);
    }

    const parseCustomDate = (romDate) => {
      const year = romDate.$y;
      const month = romDate.$M; // Months are 0-indexed in JavaScript Date
      const day = romDate.$D;
      const hours = romDate.$H;
      const minutes = romDate.$m;
      const seconds = romDate.$s;
      return new Date(year, month, day, hours, minutes, seconds);
    };

    if (filterCriteria.fromDate) {
      const fromDate = parseCustomDate(filterCriteria.fromDate);
      console.log("fromDate:", fromDate, " (", fromDate.toISOString(), ")");

      filtered = filtered.filter((category) => {
        const createdAtDate = new Date(category.createdAt);
        return createdAtDate >= fromDate;
      });

      console.log("Filtered categories:", filtered);
    }

    if (filterCriteria.toDate) {
      const toDate = parseCustomDate(filterCriteria.toDate);
      console.log(toDate);

      filtered = filtered.filter((category) => {
        const createdAtDate = new Date(category.createdAt);
        return createdAtDate <= toDate;
      });

      console.log("Filtered categories:", filtered);
    }

    if (
      filterCriteria.selectedValue &&
      filterCriteria.selectedValue !== "All"
    ) {
      console.log(filterCriteria.selectedValue);
      filtered = filtered.filter(
        (category) => category.stage === filterCriteria.selectedValue
      );
    }

    setFilteredData(filtered);
    console.log(filteredData);
  };

  const handleView = (id) => {
    navigate("/workoutlisting/workoutSubcategory/viewSubcategory", {
      state: { subcategoryId: id },
    });
  };

  const handleEdit = (id) => {
    navigate("/workoutlisting/workoutSubcategory/editSubcategory", {
      state: { subcategoryId: id },
    });
  };
  const handleBlock = (id, name, status) => {
    setBlockId(id);
    setStatus(status == "ACTIVE" ? "BLOCK" : "ACTIVE");
    setName(name);
    setBlockModalOpen(true);
  };

  const handleBlockConfirm = async () => {
    const id = blockId;
    const query = {
      subCategoryId: id,
    };
  
    try {
      const res = await putDataHandlerWithToken(
        "blockUnblockWorkoutSubCategory",
        {},
        query
      );
      console.log(res);
      if (res.responseCode === 200) {
        const updatedProgramList = topicList.map((program) =>
          program._id === id ? { ...program, status: status } : program
        );
        setTopicList(updatedProgramList);
  
        // Update the filteredData as well
        const updatedFilteredData = filteredData.map((program) =>
          program._id === id ? { ...program, status: status } : program
        );
        setFilteredData(updatedFilteredData);
  
        setBlockModalOpen(false);
        toast.success(res.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  const handleBlockCancel = () => {
    setBlockModalOpen(false);
  };
  const handleDownloadCSV = () => {
    // Remove _id field from data and extract headers
    const dataWithoutId = filteredTopicList.map(({ _id, ...rest }) => rest);
    const headers = Object.keys(dataWithoutId[0]);

    // Create CSV content with headers
    let csvContent = headers.join(",") + "\n";

    // Append rows of data
    csvContent += dataWithoutId
      .map((row) => headers.map((header) => row[header]).join(","))
      .join("\n");

    // Create a Blob object representing the data as a CSV file
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;

    // Create the filename
    const filename = `WorkoutSubcategory_${formattedDate}.csv`;

    // Use FileSaver.js to save the Blob as a file
    saveAs(blob, filename);
  };
  console.log(filteredTopicList);
  return (
    <DashboardLayout
      sectionName="Content - Program + Topic Management"
      title="Topic General Detials"
      showBackButtonSection={true}
    >
      <Box className="category-container">
        <Box className="heading-container-top">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Box>
              <img
                src={backBtn}
                alt="backBtn"
                style={{
                  // // width: "2.813",
                  // height: "5.813",
                  cursor: "pointer",
                  gap: "10px",
                }}
                onClick={() => navigate(-1)}
              />
            </Box>
            <Typography sx={{ ...typographyStyles.h4 }}>
              Workout Subcategory Listing
            </Typography>
          </Box>

          
          <Box className="button-container">
            <Box className="button-sub-container">
              <BasicButtons text="Download CSV" onClick={handleDownloadCSV} />
              <BasicButtons
                text=" +Add Subcategory"
                onClick={() => {
                  navigate(
                    "/workoutlisting/workoutSubcategory/addSubcategory",
                    {
                      state: { categoryId: location.state.categoryId },
                    }
                  );
                }}
              />
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box className="filter-container">
          <FilterBox
            placeholder="Enter your search query"
            onFilterChange={handleFilterChange}
          />
        </Box>

        <Box className="table-container">
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh", // Adjust height as needed
              }}
            >
              <Loading />
            </Box>
          ) : noData ? (
            <DataNotFound />
          ) : (
            <PaginationTable
              columns={columns}
              data={filteredData.map((obj, index) => ({
                sr_no: index + 1,
                stage: (
                  <Tooltip
                    title={
                      se.find((option) => option.value === obj.stage)?.key ||
                      "Unknown Stage"
                    }
                    arrow
                  >
                    <span>
                      {(
                        se.find((option) => option.value === obj.stage)?.key ||
                        "Unknown Stage"
                      ).length > 20
                        ? `${(
                            se.find((option) => option.value === obj.stage)
                              ?.key || "Unknown Stage"
                          ).substring(0, 20)}...`
                        : se.find((option) => option.value === obj.stage)
                            ?.key || "Unknown Stage"}
                    </span>
                  </Tooltip>
                ),
                Subcategory_name: (
                  <Tooltip title={obj.workoutSubCategoryName} arrow>
                    <span>
                      {obj.workoutSubCategoryName.length > 20
                        ? `${obj.workoutSubCategoryName.substring(0, 20)}...`
                        : obj.workoutSubCategoryName}
                    </span>
                  </Tooltip>
                ),

                created_date_time: new Date(obj.createdAt).toLocaleString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",

                    hour12: true,
                  }
                ),
                action: (
                  <Box className="row-button-container" sx={{ gap: "15%" }}>
                    <VisibilityIcon
                      onClick={() => handleView(obj._id)}
                      sx={{
                        color: "rgb(225,140,168)",
                        borderColor: "rgb(225,140,168)",
                        cursor: "pointer",
                      }}
                    />

                    <EditIcon
                      onClick={() => handleEdit(obj._id)}
                      sx={{
                        color: "rgb(225,140,168)",
                        borderColor: "rgb(225,140,168)",
                        fontFamily: "'Poppins', sans-serif",
                        cursor: "pointer",
                      }}
                    />

                    <BlockIcon
                      onClick={() =>
                        handleBlock(
                          obj._id,
                          obj.workoutSubCategoryName,
                          obj.status
                        )
                      }
                      sx={{
                        color: obj.status == "ACTIVE" ? "green" : "red",
                        borderColor: "rgb(225,140,168)",
                        fontFamily: "'Poppins', sans-serif",
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                ),
              }))}
            />
          )}
        </Box>
      </Box>
      {blockModalOpen && (
        <BlockModal
          title="SUBCATEGORY"
          status={status}
          name={name}
          onClose={handleBlockCancel}
          onBlock={handleBlockConfirm}
        />
      )}
    </DashboardLayout>
  );
};

export default WorkoutSubCategory;
