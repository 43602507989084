import React, { useEffect, useState } from "react";
import { Box, Typography, Divider } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import BasicButtons from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import InputField from "../../../components/InputField";
import {
  getDataHandlerWithToken,
  postDataHandler,
  postDataHandlerWithToken,
} from "../../../apiconfig/service";
import toast from "react-hot-toast";
import typographyStyles from "../../../theme/Typography";
import { CouponSchema, subscriptionSchema } from "../../../schema";
import backBtn from "../../../assets/backBtn.svg";
import * as Yup from "yup";

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
  addButton: {
    textTransform: "none",
    backgroundColor: "rgb(225,140,168)",
    color: "white",
    fontWeight: "600",
    fontSize: "18px",
    fontFamily: "'Poppins', sans-serif",
    boxShadow: "0 0 0.938rem 0 rgba(0, 0, 0, 0.2)",
    marginTop: "2px",
  },
};
const DiscountType = ["Percentage", "Fixed"];

const EditCoupon = () => {
  const [loading, setLoading] = useState(false);
  const [couponData, setCouponData] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchCoupon = async () => {
      const query = {
        couponId: location.state?.couponId,
      };
      try {
        const res = await getDataHandlerWithToken("viewCoupon", query);
        if (res.responseCode === 200) {
          setCouponData(res?.result);
        } else {
          toast.error(res?.data?.responseMessage);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchCoupon();
  }, []);

  console.log("dfsdg", couponData);

  const initialValues = {
    couponCode: couponData?.couponCode || "",
    discountType: couponData?.discountType || "",
    discount: couponData?.discount || "",
    maxUses: couponData?.maxUses || "",
    validity: couponData?.validity || "",
  };

  const validationSchema = CouponSchema;

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      console.log(values);
      const data = {
        couponId: location.state?.couponId,
        couponCode: values.couponCode,
        discountType: values.discountType,
        discount: values.discount,
        maxUses: values.maxUses,
        validity: values.validity,
      };

      try {
        setLoading(true);
        const response = await postDataHandlerWithToken(
          "addUpdateCoupon",
          data
        );
        if (response.responseCode === 200) {
          toast.success("Coupon added successfully.");
          navigate("/couponListing");
        } else {
          toast.error(response.data.responseMessage);
        }
      } catch (error) {
        console.error("Error adding coupon:", error);
        toast.error("Failed to add coupon. Please try again later.");
      } finally {
        setLoading(false);
      }
    },
  });

  const handleDropdownChange = (value, dropdownName) => {
    formik.setFieldValue(dropdownName, value);
  };

  const handleNumberChange = (event, fieldName) => {
    const { value } = event.target;
    if (value >= 0) {
      formik.setFieldValue(fieldName, value);
    } else {
      formik.setFieldValue(fieldName, 0);
    }
  };

  return (
    <DashboardLayout sectionName="Add Coupon" title="Coupon">
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>Edit Coupon</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <form
          className="add-edit-content-section"
          onSubmit={formik.handleSubmit}
        >
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Coupon Code:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="couponCode"
                  value={formik.values.couponCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter coupon code"
                  maxLength={30}
                />
                {formik.errors.couponCode && formik.touched.couponCode ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.couponCode}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Discount Type:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <Dropdown
                  name="discountType"
                  options={DiscountType}
                  value={formik.values.discountType}
                  onChange={(value) =>
                    handleDropdownChange(value, "discountType")
                  }
                  placeholder="Select discount type"
                />
                {formik.errors.discountType && formik.touched.discountType ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.discountType}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Discount:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="number"
                  name="discount"
                  value={formik.values.discount}
                  onChange={(event) => handleNumberChange(event, "discount")}
                  onBlur={formik.handleBlur}
                  placeholder="Enter discount"
                />
                {formik.errors.discount && formik.touched.discount ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.discount}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Maximum Uses:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="number"
                  name="maxUses"
                  value={formik.values.maxUses}
                  onChange={(event) => handleNumberChange(event, "maxUses")}
                  onBlur={formik.handleBlur}
                  placeholder="Enter maximum uses (default: Unlimited)"
                />
                {formik.errors.maxUses && formik.touched.maxUses ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.maxUses}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Validity (Days):
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="number"
                  name="validity"
                  value={formik.values.validity}
                  onChange={(event) => handleNumberChange(event, "validity")}
                  onBlur={formik.handleBlur}
                  placeholder="Enter validity in days"
                />
                {formik.errors.validity && formik.touched.validity ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.validity}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box
              className="add-edit-button-section"
              sx={{ alignItems: "center", justifyContent: "center" }}
            >
              <BasicButtons
                text={"Add Coupon"}
                disabled={loading}
                name="submit"
                type="submit"
                width={"25%"}
                loading={loading}
              />
            </Box>
          </Box>
        </form>
      </Box>
    </DashboardLayout>
  );
};

export default EditCoupon;
