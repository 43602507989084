import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Card, CardMedia, Divider, Typography } from "@mui/material";
import typographyStyles from "../../../theme/Typography";
import InputField from "../../../components/InputField";
import BasicButtons from "../../../components/Button";
import { useNavigate, useLocation } from "react-router-dom";
import {
  postDataHandler,
  putDataHandlerWithToken,
} from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";
import "../../../scss/main.css";
import ImageCropper from "../../../utils/ImageCropper";

const editStyle = {
  editTitle: {
    ...typographyStyles.h4,
  },
  editLabel: {
    ...typographyStyles.h6,
  },
};

const EditKeyFeatures = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { featureData } = state || {};
  const [iconPreview, setIconPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const SUPPORTED_FORMATS = ["image/jpeg", "image/png", "image/gif"];
  const FILE_SIZE = 1024 * 1024; // 1MB
  
  const validationSchema = Yup.object({
    featureTitle: Yup.string()

      .max(50, "Feature title must be 50 characters or less.")
      .required("Please enter the feature title."),
    featureIcon: Yup.mixed().required("Please upload the icon."),
  });
  console.log(featureData?.imageUrl);
  const formik = useFormik({
    initialValues: {
      featureTitle: featureData?.title || "",
      featureIcon: featureData?.imageUrl || "",
    },
     validationSchema : validationSchema,
     enableReinitialize:true,
    onSubmit: async (values) => {
      console.log(values);
      try {
        setLoading(true);
        const data = {
          staticContentId: featureData?._id,
          title: values.featureTitle,
          imageUrl: featureData?.imageUrl || "", // Keep existing image URL if no new image is uploaded
          contentType: "KEYFEATURES",
        };
        console.log(data);
        console.log(featureData?.imageUrl, );
       
        if (croppedImage) {
          console.log('Cropped Image URL:', croppedImage); // Log croppedImage
          data.imageUrl = croppedImage; // Update with the cropped image URL
        }

        const response = await putDataHandlerWithToken(
          "updateStaticContent",
          data
        );
        if (response.responseCode == 200) {
          toast.success("Feature updated successfully");
          navigate("/staticcontent/listKeyFeatures");
        } else {
          toast.error("Something went wrong, try again.");
        }
      } catch (error) {
        console.error("Error updating feature:", error);
        toast.error("Failed to update feature. Please try again later.");
      } finally {
        setLoading(false);
      }
    },
  });

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    setFieldValue,
  } = formik;

  const handleIconChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setFieldValue("featureIcon", file);
    }
  };
  const handleCropComplete = (cloudinaryUrl) => {
    setCroppedImage(cloudinaryUrl);
    setIconPreview(cloudinaryUrl)
    setSelectedImage(null);
  };

  useEffect(() => {
    if (featureData?.imageUrl) {
      setIconPreview(featureData.imageUrl);
    }
  }, [featureData]);

  const handleBackClick = () => {
    navigate(-1);
  };
  console.log(errors);

  return (
    <DashboardLayout
      sectionName="Static Content Management"
      title="Edit Key Feature"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={handleBackClick}
            />
          </Box>
          <Typography sx={editStyle.editTitle}>Edit Key Feature</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <form className="add-edit-content-section" onSubmit={handleSubmit}>
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={editStyle.editLabel}>Feature Title:</Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="featureTitle"
                  placeholder="Enter the feature title"
                  value={values.featureTitle}
                  onChange={handleChange}
                  onBlur={handleBlur}
                 
                />
                {errors.featureTitle && touched.featureTitle ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.featureTitle}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={editStyle.editLabel}>Upload Icon:</Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="file"
                  name="featureIcon"
                  onBlur={handleBlur}
                  onChange={handleIconChange}
                  acceptedFile="image/*"
                />
                {errors.featureIcon && touched.featureIcon ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.featureIcon}
                  </p>
                ) : null}
                {iconPreview && (
                  <Box sx={{ marginTop: "10px" }}>
                    <Card sx={{ maxWidth: 200, maxHeight: 200 }}>
                      <CardMedia component="img" image={iconPreview} />
                    </Card>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            className="add-edit-button-section"
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <BasicButtons
              text="Update"
              name="submit"
              type="submit"
              disabled={loading}
              loading={loading}
              width={"20%"}
            />
          </Box>
        </form>
      </Box>
      <ImageCropper
        image={selectedImage}
        aspectRatio={1}
        onCropComplete={handleCropComplete}
        onClose={() => setSelectedImage(null)}
      />
    </DashboardLayout>
  );
};

export default EditKeyFeatures;
