import * as React from "react";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "../scss/main.css";
import { Box, Grid } from "@mui/material";
import dayjs from "dayjs";

export default function ResponsiveDatePickers({
  value,
  onChange,
  placeholder,
  minDate,
}) {
  return (
    
     

<LocalizationProvider dateAdapter={AdapterDayjs}>
<DemoContainer
  components={[
    'DatePicker',
    'MobileDatePick er',
    'DesktopDatePicker',
    'StaticDatePicker',
  ]}
>
  
  
  <DemoItem  >
    <DatePicker 
     className="customDatePickerIcon"
             sx={{
               "& .MuiSvgIcon-root": {
                 color: "rgb(225, 140, 168) !important",
               },
               "& .MuiFormLabel-root": {
                 lineHeight: "16px !important",
               },
               width:"100%"
             }}
     label={placeholder}
             value={value}
             onChange={(newValue) => onChange(newValue)}
             minDate={minDate ? dayjs(minDate) : undefined}
             fullWidth  />
  </DemoItem>
  
</DemoContainer>
</LocalizationProvider>

      
   
  );
}
