import React from "react";
import { Box, Card, CardMedia, Divider, Typography } from "@mui/material";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import typographyStyles from "../../../theme/Typography";
import backBtn from "../../../assets/backBtn.svg";
import { useNavigate, useLocation } from "react-router-dom";

const viewCardStyle = {
  viewCardTitle: {
    ...typographyStyles.h4,
  },
  viewCardLabel: {
    ...typographyStyles.h6,
  },
  cardContainer: {
    marginTop: 2,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  cardImage: {
    width: 200,
    height: 200,
    objectFit: "cover",
  },
};

const ViewCardsComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const cardData = location.state?.cardData || {}; // Default to empty object if no data

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <DashboardLayout sectionName="Card Management" title="View Card">
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{
                cursor: "pointer",
              }}
              onClick={handleBackClick}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>View Card</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="view-card-container">
        <Box className="view-card-content">
          <Box sx={viewCardStyle.cardContainer}>
            <Card sx={{ maxWidth: 200 }}>
              <CardMedia
                component="img"
                image={cardData.imageUrl || ""} // Default to empty string if no image URL
                alt="Card Image"
                sx={viewCardStyle.cardImage}
              />
            </Card>
            <Box sx={{ marginLeft: 2 }}>
              <Typography sx={viewCardStyle.viewCardLabel}>
                Banner Title:
              </Typography>
              <Typography sx={{ marginBottom: 2 }}>
                {cardData.title || "N/A"}
              </Typography>
              <Typography sx={viewCardStyle.viewCardLabel}>
                Description:
              </Typography>
              <Typography>{cardData.description || "N/A"}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default ViewCardsComponent;
