import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Typography, Divider } from "@mui/material";
import typographyStyles from '../../../theme/Typography';
import "../../../scss/main.css";
import { getDataHandler } from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import backBtn from "../../../assets/backBtn.svg";

const stripHtml = (html) => {
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
};

const AboutUs = () => {
    const [aboutusData, setAboutusData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch data when component mounts
        getDataHandler("getAllstaticContentByType", { contentType: "ABOUTUS" })
            .then((response) => {
                if (response?.responseCode === 200) {
                    setAboutusData(response.result); // Set the entire response array
                } else {
                    toast.error(response?.responseMessage || "Failed to fetch About Us data");
                }
            })
            .catch((error) => {
                console.error("Error fetching data", error);
                toast.error("Failed to fetch About Us data");
            });
    }, []);

    return (
        <DashboardLayout sectionName="Static Content Management" title="About Us">
            <Box className="heading-container-top">
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                    }}
                >
                    <Box>
                        <img
                            src={backBtn}
                            alt="backBtn"
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate(-1)}
                        />
                    </Box>
                    <Typography sx={{ ...typographyStyles.h4 }}>
                        About Us
                    </Typography>
                </Box>
            </Box>
            <Divider />
            <Box className="StaticScreenContainer">
                {aboutusData?.length ? (
                    aboutusData?.map((item) => (
                        <Box
                            key={item._id}
                            className="StaticScreenContent"
                            sx={{
                                border: "1px solid #ddd",
                                borderRadius: "8px",
                                padding: "16px",
                                backgroundColor: "#f9f9f9",
                                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                marginBottom: "16px",
                            }}
                        >
                            <Typography variant="h6" sx={{ marginBottom: "8px" }}>
                                Title: {item.title}
                            </Typography>
                            <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                                Status: {item.status}
                            </Typography>
                            <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                                Active: {item.isActive ? "Yes" : "No"}
                            </Typography>
                            <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                                Created At: {new Date(item.createdAt).toLocaleDateString()}
                            </Typography>
                            <Typography variant="body1"    sx={{
              "& ul, & ol": {
                paddingLeft: "20px", // Indent lists
                marginBottom: "16px", // Add spacing below lists
              },
              "& li": {
                lineHeight: "1.5", // Adjust line height for readability
                marginBottom: "8px", // Add spacing between list items
              },
            }}>
                                <div dangerouslySetInnerHTML={{ __html:item.description}} />
                            </Typography>
                        </Box>
                    ))
                ) : (
                    <Typography variant="body1">Loading About Us data...</Typography>
                )}
            </Box>
        </DashboardLayout>
    );
}

export default AboutUs;
