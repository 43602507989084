import React, { useState, useEffect } from "react";

import { Box, Divider, Typography } from "@mui/material";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import typographyStyles from "../../../theme/Typography";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import AdminImg from "../../../assets/profileimg-h2m.png";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

import { getDataHandlerWithToken } from "../../../apiconfig/service";

const accountStyle = {
    accountContainer: {
        marginTop: '20px',
        display: "flex",
        flexDirection: "column",
        gap: "20px",
    },

  accountTitle: {
    ...typographyStyles.h4,
    fontWeight: "600",
  },

    accountContent: {
        display: 'flex',
        gap: '10%',
    },

    accountInfo: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        width: '100%',
    },

    adminImgContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: '10px',
    },

    adminImg: {
        height: '12rem', 
        width: '12rem',
        borderRadius: '50%', // Make the image circular
        objectFit: 'cover', // Ensure the image covers the circular area
    },

    adminInfoContainer: {
        display: 'flex',
        gap: '10%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },

  accountText: {
    ...typographyStyles.h6,
    fontWeight: "600",
  },

  accountlabel: {
    ...typographyStyles.h6,
    fontWeight: "600",
    color: "rgb(128,128,128)",
  },

    accountSettingContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        cursor: 'pointer',
    },

    accountSettingContent: {
        display: 'flex',
        alignItems: 'center',
        gap: '5%',
    },

  accountIcon: {
    color: "rgb(225,140,168)",
    fontSize: "40px",
  },

  accountLabel: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
};

const MyAccount = () => {
    const [admin, setAdmin] = useState();
    const [adminImage, setAdminImage] = useState();
    const Navigate = useNavigate();

    const fetchData = async () => {
        try {
            const res = await getDataHandlerWithToken("viewProfile");
            if (res.responseCode === 200) {
                setAdmin(res?.result);
            } else {
                toast.error(res?.data?.responseMessage);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <DashboardLayout sectionName="My Account" title="View Profile">
            <Box className="heading-container-top">
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 1
                    }}
                >
                    <Typography sx={{ ...typographyStyles.h4 }}>
                        My Account
                    </Typography>
                </Box>
            </Box>
            <Divider />
            <Box sx={accountStyle.accountContainer}>
                <Box sx={accountStyle.accountContent}>
                    <Box sx={accountStyle.accountInfo}>
                        <Box sx={accountStyle.adminImgContainer}>
                            <img
                                style={accountStyle.adminImg}
                                src={admin?.profilePicture != null ? admin?.profilePicture : AdminImg}
                                alt="AdminImg"
                            />
                        </Box>
                        <Box sx={accountStyle.adminInfoContainer}>
                            <Box sx={accountStyle.accountLabel}>
                                <Typography sx={accountStyle.accountlabel}>Name:</Typography>
                                <Typography sx={accountStyle.accountlabel}>Email Address:</Typography>
                            </Box>
                            <Box sx={accountStyle.accountLabel}>
                                <Typography sx={accountStyle.accountText}>{admin?.fullName ? admin?.fullName : "Admin Name"}</Typography>
                                <Typography sx={accountStyle.accountText}>{admin?.email ? admin?.email : "abc@gmail.com"}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={accountStyle.accountSettingContainer}>
                        <Box sx={accountStyle.accountSettingContent} onClick={() => { Navigate('/myaccount/editaccount') }}>
                            <AccountCircleIcon sx={accountStyle.accountIcon} />
                            <Typography sx={accountStyle.accountText}>Edit Account</Typography>
                        </Box>
                        <Box sx={accountStyle.accountSettingContent} onClick={() => { Navigate('/myaccount/changepassword') }}>
                            <SettingsIcon sx={accountStyle.accountIcon} />
                            <Typography sx={accountStyle.accountText}>Change Password</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </DashboardLayout>
    );
};

export default MyAccount;
