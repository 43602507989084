import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Card, CardMedia, Divider, Typography } from "@mui/material";
import typographyStyles from "../../../theme/Typography";
import InputField from "../../../components/InputField";
import BasicButtons from "../../../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import {
  postDataHandlerWithToken,
  postDataHandler,
  getDataHandlerWithToken,
  putDataHandlerWithToken,
} from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";
import "../../../scss/main.css";

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
};

const EditTestimonial = () => {
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const formik = useFormik({
    initialValues: {
      username: "",
      title: "",
      description: "",
      rating: 0,
      profilePic: null,
      video: null,
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .required("Please enter the username.")
        .min(5, "The username text must be at least 5 characters long.")
        .max(50, "The username text cannot be more than 50 characters long."),
      title: Yup.string()
        .required("Please enter the testimonial title.")
        .min(5, "The title text must be at least 5 characters long.")
        .max(50, "The title text cannot be more than 50 characters long."),
      description: Yup.string()
        .required("Please enter the testimonial text.")
        .min(20, "The testimonial text must be at least 20 characters long.")
        .max(
          500,
          "The testimonial text cannot be more than 500 characters long."
        ),
      rating: Yup.number()
        .min(1)
        .max(5)
        .required("Please enter a rating between 1 and 5."),
      // profilePic: Yup.mixed().required("Please upload the image."),
      // video: Yup.mixed().required("Please upload the video.")
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        console.log(values);

        // Upload image
        const imageFormData = new FormData();
        imageFormData.append("file", values.profilePic);
        const imageResponse = await postDataHandler(
          "uploadFile",
          imageFormData
        );
        const imageUrl = imageResponse.result;
        console.log(imageUrl);

        // Upload video
        const videoFormData = new FormData();
        videoFormData.append("file", values.video);
        const videoResponse = await postDataHandler(
          "uploadVideo",
          videoFormData
        );
        console.log(videoResponse.result);
        const videoUrl = videoResponse.result;

        // Prepare testimonial data
        const data = {
          testimonialId: id,
          username: values?.username,
          title: values?.title,
          description: values?.description,
          rating: values?.rating,
          profilePic: imageUrl || imagePreview,
          video: videoUrl || videoPreview,
        };
        console.log(data);

        // Send data to backend
        const response = await putDataHandlerWithToken(
          "updateTestimonial",
          data
        );
        console.log(response);

        if (response?.responseCode === 200) {
          toast.success("Testimonial updated successfully");
          navigate("/staticcontent/testimonialmanagement");
        } else {
          toast.error(
            response?.responseMessage || "Failed to update testimonial."
          );
        }
      } catch (error) {
        console.error("Error updating testimonial:", error);
        toast.error("Failed to update testimonial. Please try again later.");
      } finally {
        setLoading(false);
      }
    },
  });

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      formik.setFieldValue("profilePic", file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      formik.setFieldValue("video", file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setVideoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    // Fetch data when component mounts
    const fetchData = async () => {
      try {
        const response = await getDataHandlerWithToken("getTestimonial", {
          testimonialId: id,
        });
        if (response?.responseCode === 200) {
          const content = response?.result;
          formik.setValues({
            username: content?.username,
            title: content?.title,
            description: content?.description,
            rating: content?.rating,
          });
          setImagePreview(content?.profilePic);
          setVideoPreview(content?.video);
          console.log(content);
        } else {
          toast.error(
            response?.responseMessage || "Failed to fetch testimonial"
          );
        }
      } catch (error) {
        console.error("Error in fetching data.", error);
        toast.error("Failed to fetch testimonial data.");
      }
    };

    fetchData();
  }, [id, formik.setValues]);

  return (
    <DashboardLayout
      sectionName="Static Content Management"
      title="Add Testimonial"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={addEditStyle.addEditTitle}>
            Update Testimonial
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <form
          className="add-edit-content-section"
          onSubmit={formik.handleSubmit}
        >
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Username:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="username"
                  placeholder="Enter the username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  maxLength={50}
                />
                {formik.touched.username && formik.errors.username ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.username}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Testimonial Title:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="title"
                  placeholder="Enter the testimonial title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  maxLength={50}
                />
                {formik.touched.title && formik.errors.title ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.title}
                  </p>
                ) : null}
              </Box>  
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Testimonial Description:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="description"
                  placeholder="Enter the testimonial description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  maxLength={500}
                />
                {formik.touched.description && formik.errors.description ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.description}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>Rating:</Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="number"
                  name="rating"
                  placeholder="Enter the rating (1-5)"
                  value={formik.values.rating}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.rating && formik.errors.rating ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.rating}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Profile Pic:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="file"
                  name="profilePic"
                  onBlur={formik.handleBlur}
                  onChange={handleImageChange}
                 acceptedFile="image/*"
                />
                {formik.touched.profilePic && formik.errors.profilePic ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.profilePic}
                  </p>
                ) : null}
                {imagePreview && (
                  <Box sx={{ marginTop: "10px" }}>
                    <Card sx={{ maxWidth: 200, maxHeight: 200 }}>
                      <CardMedia component="img" image={imagePreview} />
                    </Card>
                  </Box>
                )}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Upload Video:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="file"
                  name="video"
                  onBlur={formik.handleBlur}
                  onChange={handleVideoChange}
                  acceptedFile="video/*"
                />
                {formik.touched.video && formik.errors.video ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.video}
                  </p>
                ) : null}
                {videoPreview && (
                  <Box sx={{ marginTop: "10px" }}>
                    <Card sx={{ maxWidth: 200, maxHeight: 200 }}>
                      <CardMedia
                        component="video"
                        src={videoPreview}
                        controls
                      />
                    </Card>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            className="add-edit-button-section"
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <BasicButtons
              text="Update Testimonial"
              name="submit"
              type="submit"
              disabled={loading}
              loading={loading}
              width={"20%"}
            />
          </Box>
        </form>
      </Box>
    </DashboardLayout>
  );
};

export default EditTestimonial;
