import React, { useEffect, useState } from "react";
import { Box, Typography,Divider,TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import InputField from "../../../components/InputField";
import BasicButton from "../../../components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  getDataHandler,
  patchTokenDataHandler,
  postDataHandlerWithToken,
} from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";
import typographyStyles from '../../../theme/Typography';


const EditFAQ = () => {
  const [faqData, setFaqData] = useState([]);
  const [initialValues, setInitialValues] = useState({
    question: "",
    answer: "",
  });
  validationSchema: Yup.object({
    question: Yup.string().required("Please enter the question."),
    answer: Yup.string().required("Please enter the answer."),
  })
  const navigate = useNavigate();
  const location = useLocation();

  const fetchProgram = async () => {
    const IdFromLocation = location.state?.faqId;

    const query = {
      contentType: "FAQ",
    };
    try {
      const res = await getDataHandler("getAllstaticContentByType", query);
      if (res.responseCode === 200) {
        const response = res.result;
        setFaqData(response);

        // Find the specific FAQ data by ID
        const currentFaq = response.find((faq) => faq._id === IdFromLocation);
        if (currentFaq) {
          setInitialValues({
            question: currentFaq.question,
            answer: currentFaq.answer,
          });
        }
      } else {
        toast.error(res.data.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProgram();
  }, [location.state?.faqId]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      question: Yup.string().required("Please enter the question."),
      answer: Yup.string().required("Please enter the answer."),
    }),
    onSubmit: async (values) => {
      try {
        const data = {
          contentType: "FAQ",
          title: "faq",
          question: values.question,
          answer: values.answer,
          staticContentId: location.state?.faqId,
        };

        const response = await patchTokenDataHandler(
          "updateStaticContent",
          data
        );
        toast.success("Question and answer were updated");

        // Handle success or navigate to another page
        navigate("/staticcontent/faqs"); // Navigate to FAQs page after successful addition
      } catch (error) {
        console.error("Error updating question and answer:", error);
        toast.error(error.message);
      }
    },
  });

  return (
    <DashboardLayout sectionName="Static Content Management" title="Edit FAQ">
         <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap:"10px"
          }}
        >
           <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{
                // // width: "2.813",
                // height: "5.813",
                cursor: "pointer",
              }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>
        Edit FAQ
          </Typography>

         
        </Box>
      </Box>
      <Divider />
      <Box className="StaticScreenContainer">
        <Box className="StaticScreenContent">
          <form onSubmit={formik.handleSubmit}>
          <Box sx={{ marginBottom: 4 }}>
              <Typography>Enter the question</Typography>
              <TextField
                fullWidth
                name="question"
                placeholder="Enter the question"
                value={formik.values.question}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                inputProps={{ maxLength: 150 }}
                error={formik.touched.question && Boolean(formik.errors.question)}
                sx={{ marginBottom: '8px' }}
              />
              {formik.touched.question && formik.errors.question && (
                <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                  {formik.errors.question}
                </p>
              )}
            </Box>
            <Box sx={{ marginBottom: 2 }}>
              <Typography>Enter the answer</Typography>
              <TextField
                fullWidth
                name="answer"
                placeholder="Enter the answer"
                value={formik.values.answer}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                multiline
                rows={10}
                inputProps={{ maxLength: 500 }}
                error={formik.touched.answer && Boolean(formik.errors.answer)}
                sx={{ marginBottom: '8px' }}
              />
              {formik.touched.answer && formik.errors.answer && (
                <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                  {formik.errors.answer}
                </p>
              )}
            </Box>
            <Box className="StaticBtnContainer" sx={{ marginBottom: 2 }}>
              <BasicButton text="Update" type="submit" width={"20%"}/>
              {/* <BasicButton
                text="Back"
                onClick={() => navigate("/staticcontent")}
              /> */}
            </Box>
          </form>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default EditFAQ;
