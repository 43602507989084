import React, { useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import * as Yup from "yup";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Divider,
} from "@mui/material";
import typographyStyles from "../../../theme/Typography";
import BasicButtons from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import InputField from "../../../components/InputField";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import { postDataHandlerWithToken } from "../../../apiconfig/service";
import backBtn from "../../../assets/backBtn.svg";
import MultiSelectDropdown from "../../../components/MultiSelectDropdown";
import { dietarySchema } from "../../../schema";

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
  radioContainer: {
    display: "flex",
  },
};

const dropdownOptions = [
  "Trying to Conceive",
  "Pregnant",
  "Postpartum (0-6 Weeks After Birth)",
  "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
];
const se = [
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum (0-6 Weeks After Birth)", value: "POSTPARTUM" },
  {
    key: "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
    value: "BEYOND",
  },
];

const AddDietary = () => {
  const [selectedValue, setSelectedValue] = useState([]);
  const [categoryType, setCategoryType] = useState("FREE");
  const [loading, setLoading] = useState(false);

  const handleDropdownChange = (value) => {
    setSelectedValue(value);
  };

  const handleCategoryTypeChange = (event) => {
    setCategoryType(event.target.value);
  };

  const validationSchema = dietarySchema;

  const Navigate = useNavigate();

  const handleFormSubmit = async (values) => {
    const data = {
      stage: values.selectStage,
      dietaryName: values.dietaryName,
    };

    try {
      setLoading(true);
      const res = await postDataHandlerWithToken("addUpdateDietary", data);

      if (res?.responseCode === 200) {
        toast.success(res?.responseMessage);
        Navigate("/dietarylisting");
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to add dietary preference.");
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      selectStage: [],
      dietaryName: "",
      saveAs: "FREE", // Default value for radio group
    },
    validationSchema: validationSchema,
    onSubmit: (values) => handleFormSubmit(values),
  });

  return (
    <DashboardLayout
      sectionName="Dietary Preference Management"
      title="Add Dietary"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{
                // // width: "2.813",
                // height: "5.813",
                cursor: "pointer",
              }}
              onClick={() => Navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>Add Dietery</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <form
          className="add-edit-content-section"
          onSubmit={formik.handleSubmit}
        >
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Select Stage:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <MultiSelectDropdown
                  name="selectStage"
                  onBlur={formik.handleBlur}
                  sx={addEditStyle.categoryInputField}
                  options={se}
                  value={formik.values.selectStage}
                  placeholder="Select stage"
                  onChange={(value) =>
                    formik.handleChange({
                      target: { name: "selectStage", value },
                    })
                  }
                />
                {formik.errors.selectStage && formik.touched.selectStage ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.selectStage}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Dietary Preference Name:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="dietaryName"
                  value={formik.values.dietaryName}
                  maxLength={50}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter dietary name"
                />
                {formik.errors.dietaryName && formik.touched.dietaryName ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.dietaryName}
                  </p>
                ) : null}
              </Box>
            </Box>
            {/* <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>Save As:</Typography>
              </Box>
              <Box className="add-edit-input-field">
                <FormControl component="fieldset">
                  <RadioGroup
                    name="saveAs"
                    value={formik.values.saveAs}
                    onChange={formik.handleChange}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <FormControlLabel
                     className="radio"
                      value="FREE"
                      control={<Radio />}
                      label="Free"
                    />
                    <FormControlLabel
                     className="radio"
                      value="LOCKED"
                      control={<Radio />}
                      label="Locked (will be accessed after subscription)"
                    />
                  </RadioGroup>
                </FormControl>
                {formik.errors.saveAs && formik.touched.saveAs ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.saveAs}
                  </p>
                ) : null}
              </Box>
            </Box> */}
          </Box>
          <Box
            className="add-edit-button-section"
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <BasicButtons
              text="Add Dietary"
              name="submit"
              type="submit"
              width={"20%"}
              disabled={formik.isSubmitting}
              loading={loading}
            />
            {/* <BasicButtons
              text="Back"
              width={"25%"}
              onClick={() => {
                Navigate("/dietarylisting");
              }}
            /> */}
          </Box>
        </form>
      </Box>
    </DashboardLayout>
  );
};

export default AddDietary;
