import React, { useState, useEffect } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Card,
  CardMedia,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { getDataHandlerWithToken } from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";
import typographyStyles from "../../../theme/Typography";

const addEditStyle = {
  addEditLabel: {
    fontWeight: "bold",
  },
  addEditInfo: {
    fontWeight: "normal",
  },
};

const ViewTopic = () => {
  const [program, setProgram] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const fetchProgram = async () => {
    const query = {
      topicId: location.state?.topicId,
    };
    try {
      const res = await getDataHandlerWithToken("viewTopic", query);
      if (res.responseCode === 200) {
        setProgram(res.result);
      } else {
        toast.error(res.data.responseMessage);
      }
    } catch (error) {
      toast.error(error.message || "Error fetching data");
    }
  };

  useEffect(() => {
    fetchProgram();
  }, []);

  return (
    <DashboardLayout
      sectionName="Content - Program + Topic Management"
      title="Topic General Details"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>View Topic</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <Box className="add-edit-content-section">
          <Box className="add-edit-content">
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Topic Name
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {program?.topicName}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Thumbnail Image
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      <Card sx={{ maxWidth: 300, maxHeight: "auto" }}>
                        <CardMedia
                          component="img"
                          image={program?.topicThumbnailImage}
                        />
                      </Card>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Description
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {/* Using dangerouslySetInnerHTML */}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: program?.description,
                        }}
                      />

                      {/* Uncomment if using react-html-parser */}
                      {/* {ReactHtmlParser(program?.description)} */}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Content Details
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      <table
                        style={{ width: "100%", borderCollapse: "collapse" }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                padding: "12px",
                                border: "1px solid #ddd",
                                backgroundColor: "rgb(225,140,168)",
                                width:"10%"
                              }}
                            >
                              Sr No.
                            </th>
                            <th
                              style={{
                                padding: "12px",
                                border: "1px solid #ddd",
                                backgroundColor: "rgb(225,140,168)",
                                textAlign: "center",
                                width:"30%"

                              }}
                            >
                              Images
                            </th>
                            <th
                              style={{
                                padding: "12px",
                                border: "1px solid #ddd",
                                backgroundColor: "rgb(225,140,168)",
                                textAlign: "center",
                                width:"60%"

                              }}
                            >
                              Description
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {program?.contentDetails?.map((item, index) => (
                            <tr
                              key={index}
                              style={
                                index % 2 === 0
                                  ? { backgroundColor: "#f0f0f0" }
                                  : {}
                              }
                            >
                              <td
                                style={{
                                  padding: "12px",
                                  border: "1px solid #ddd",
                                }}
                              >
                                {index + 1}
                              </td>
                              <td
                                style={{
                                  padding: "12px",
                                  border: "1px solid #ddd",
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                }}
                              >
                                {item.images && item.images.length > 0 ? (
                                  item.images.map((image, imgIndex) => (
                                    <Card
                                      key={imgIndex}
                                      sx={{
                                        maxWidth: 200,
                                        maxHeight: 200,
                                        marginTop: "3px",
                                        marginBottom: "3px",
                                      }}
                                    >
                                      <CardMedia
                                        component="img"
                                        image={image}
                                      />
                                    </Card>
                                  ))
                                ) : (
                                  <Typography>No images available</Typography>
                                )}
                              </td>
                              <td
                                style={{
                                  padding: "12px",
                                  border: "1px solid #ddd",
                                  textAlign: "center",
                                }}
                              >
                                {item.description ? (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item.description,
                                    }}
                                    style={{ padding: "10px" }}
                                  />
                                ) : (
                                  <Typography>
                                    No description available
                                  </Typography>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default ViewTopic;
