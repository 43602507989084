import React, { useState, useEffect } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Button, Typography, Divider, Tooltip } from "@mui/material";

import FilterBox from "../../../components/FilterBox";
import BasicButtons from "../../../components/Button";
import PaginationTable from "../../../components/PaginationTable";

import "../../../scss/main.css";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import {
  getDataHandlerWithToken,
  putDataHandlerWithToken,
} from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import BlockModal from "../../../Modal/BlockModal";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import typographyStyles from "../../../theme/Typography";
import LoadingPage from "../LoadingPage/LoadingPage";
import Loading from "../../../components/Loading";
import DataNotFound from "../../../components/DataNotFound";

const columns = [
  { id: "sr_no", label: "Sr.No." },
  { id: "stage", label: "Stage" },
  { id: "cuisine_name", label: "Cuisine Name" },
  { id: "created_date_time", label: "Created Date & Time" },
  { id: "status", label: "Status" },
  { id: "action", label: "Action" },
];
const se = [
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum (0-6 Weeks After Birth)", value: "POSTPARTUM" },
  {
    key: "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
    value: "BEYOND",
  },
];
const CuisineListing = () => {
  const [cuisineList, setCuisineList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [render, setRender] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const [blockCuisineId, setBlockCuisineId] = useState(null);
  const [status, setStatus] = useState();
  const [name, setName] = useState();
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      const query = {
        limit: Number.MAX_SAFE_INTEGER,
      };
      const res = await getDataHandlerWithToken("listCuisines", query);
      console.log("API response:", res);
      if (res.responseCode === 200) {
        setCuisineList(res?.result?.docs || []);
        setFilteredData(res?.result?.docs || []);
      } else {
        toast.error(res?.data?.responseText);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [render, toggle]);

  const Navigate = useNavigate();

  const handleEdit = (id) => {
    Navigate("/cuisionlisting/editcuision", { state: { cuisineId: id } });
  };

  const handleBlock = (id, status, name) => {
    setBlockCuisineId(id);
    setStatus(status == "ACTIVE" ? "BLOCK" : "ACTIVE");
    setName(name);
    setBlockModalOpen(true);
  };

  const handleBlockConfirm = async () => {
    try {
      const id = blockCuisineId;
      const res = await putDataHandlerWithToken(
        "blockUnblockCuisine",
        {},
        { cuisineId: blockCuisineId }
      );
      console.log(res);
      if (res.responseCode === 200) {
        const updatedProgramList = cuisineList.map((program) =>
          program._id === id ? { ...program, status: status } : program
        );
        setCuisineList(updatedProgramList);
        setFilteredData(updatedProgramList);
        setBlockModalOpen(false);
        toast.success(res.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
    setBlockModalOpen(false);
  };

  const handleBlockCancel = () => {
    setBlockModalOpen(false);
  };

  const handleFilterChange = (filterCriteria) => {
    let filtered = cuisineList;

    if (filterCriteria.searchText) {
      filtered = filtered.filter((category) =>
        category.cuisineName
          .toLowerCase()
          .includes(filterCriteria.searchText.toLowerCase().trim())
      );
    }

    const parseCustomDate = (romDate) => {
      const year = romDate.$y;
      const month = romDate.$M; // Months are 0-indexed in JavaScript Date
      const day = romDate.$D;
      const hours = romDate.$H;
      const minutes = romDate.$m;
      const seconds = romDate.$s;
      return new Date(year, month, day, hours, minutes, seconds);
    };

    if (filterCriteria.fromDate) {
      const fromDate = parseCustomDate(filterCriteria.fromDate);
      console.log("fromDate:", fromDate, " (", fromDate.toISOString(), ")");

      filtered = filtered.filter((category) => {
        const createdAtDate = new Date(category.createdAt);
        return createdAtDate >= fromDate;
      });

      console.log("Filtered categories:", filtered);
    }

    if (filterCriteria.toDate) {
      const toDate = parseCustomDate(filterCriteria.toDate);
      console.log(toDate);

      filtered = filtered.filter((category) => {
        const createdAtDate = new Date(category.createdAt);
        return createdAtDate <= toDate;
      });

      console.log("Filtered categories:", filtered);
    }

    if (
      filterCriteria.selectedValue &&
      filterCriteria.selectedValue !== "All"
    ) {
      console.log(filterCriteria.selectedValue);
      filtered = filtered.filter((category) =>
        category.stage.includes(filterCriteria.selectedValue)
      );
    }

    setFilteredData(filtered);
    console.log(filteredData);

    setFilteredData(filtered);
  };

  const handleDownloadCSV = () => {
    const dataWithoutId = cuisineList.map(({ _id, ...rest }) => rest);
    const headers = Object.keys(dataWithoutId[0]);
    let csvContent = headers.join(",") + "\n";
    csvContent += dataWithoutId
      .map((row) => headers.map((header) => row[header]).join(","))
      .join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;

    // Create the filename
    const filename = `CuisineListing_${formattedDate}.csv`;

    // Use FileSaver.js to save the Blob as a file
    saveAs(blob, filename);
  };

  return (
    <DashboardLayout sectionName="Cuisine Management">
      <Box className="category-container">
        <Box className="heading-container-top">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ ...typographyStyles.h4 }}>
              Cuisine Listing
            </Typography>
          </Box>

          <Box className="button-container">
            <Box className="button-container">
              <Box className="button-sub-container">
                <BasicButtons text="Download CSV" onClick={handleDownloadCSV} />
                <BasicButtons
                  text="+Add Cuisine"
                  onClick={() => Navigate("/cuisionlisting/addcuision")}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box className="filter-container">
          <FilterBox
            placeholder="Enter Cuisine Name"
            onFilterChange={handleFilterChange}
          />
        </Box>

        <Box className="table-container">
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh", // Adjust height as needed
              }}
            >
              <Loading />
            </Box>
          ) : filteredData?.length < 1 ? (
            <DataNotFound />
          ) : (
            <PaginationTable
              columns={columns}
              data={filteredData.map((obj, index) => {
                const stageKeys = se
                  .filter((option) => obj.stage.includes(option.value))
                  .map((item) => item.key);
                const stageText = stageKeys.join(", ") || "Unknown Stage";

                return {
                  sr_no: index + 1,
                  stage: (
                    <Tooltip title={stageText} arrow>
                      <span>
                        {stageText.length > 20
                          ? `${stageText.substring(0, 20)}...`
                          : stageText}
                      </span>
                    </Tooltip>
                  ),
                  cuisine_name: (
                    <Tooltip title={obj.cuisineName} arrow>
                      <span>
                        {obj.cuisineName.length > 20
                          ? `${obj.cuisineName.substring(0, 20)}...`
                          : obj.cuisineName}
                      </span>
                    </Tooltip>
                  ),
                  created_date_time: new Date(obj.createdAt).toLocaleString(
                    "en-US",
                    {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",

                      hour12: true,
                    }
                  ),
                  status: obj.status,
                  action: (
                    <Box className="row-button-container" sx={{ gap: "15%" }}>
                      <EditIcon
                        onClick={() => handleEdit(obj._id)}
                        sx={{
                          color: "rgb(225,140,168)",
                          borderColor: "rgb(225,140,168)",
                          fontFamily: "'Poppins', sans-serif",
                          cursor: "pointer",
                        }}
                      />

                      <BlockIcon
                        onClick={() =>
                          handleBlock(obj._id, obj.status, obj.cuisineName)
                        }
                        sx={{
                          color: obj.status == "ACTIVE" ? "green" : "red",
                          borderColor: "rgb(225,140,168)",
                          fontFamily: "'Poppins', sans-serif",
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                  ),
                };
              })}
            />
          )}
        </Box>
      </Box>
      {blockModalOpen && (
        <BlockModal
          title="CUISINE"
          status={status}
          name={name}
          onClose={handleBlockCancel}
          onBlock={handleBlockConfirm}
        />
      )}
    </DashboardLayout>
  );
};

export default CuisineListing;
