import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
// import { useAuth } from "./AuthContext"; // Adjust the path accordingly
import { AuthContext } from "../context/AuthContext";

const AuthGuard = () => {
  const { isAuthenticated } = useContext(AuthContext); // Use AuthContext

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default AuthGuard;
