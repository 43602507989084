import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Button, Tooltip, Typography, Divider } from "@mui/material";
import FilterBox from "../../../components/FilterBox";
import BasicButtons from "../../../components/Button";
import PaginationTable from "../../../components/PaginationTable";
import "../../../scss/main.css";
import { useNavigate } from "react-router-dom";
import {
  deleteDataHandler,
  getDataHandlerWithToken,
  putDataHandlerWithToken,
} from "../../../apiconfig/service";
import toast from "react-hot-toast";
import { saveAs } from "file-saver";
import BlockModal from "../../../Modal/BlockModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import typographyStyles from "../../../theme/Typography";
import Loading from "../../../components/Loading";
import DataNotFound from "../../../components/DataNotFound";
import AlertDialog from "../../../components/AlertDialog";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";

const columns = [
  { id: "sr_no", label: "Sr.No." },
  { id: "stage", label: "Stage" },
  { id: "recipe_type", label: "Recipe Type" },
  { id: "created_date_time", label: "Created Date & Time" },

  { id: "status", label: "Status" },
  { id: "action", label: "Action" },
];
const se = [
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum (0-6 Weeks After Birth)", value: "POSTPARTUM" },
  {
    key: "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
    value: "BEYOND",
  },
];
const RecipeType = () => {
  const navigate = useNavigate();
  const [couponList, setCouponList] = useState([]);
  const [render, setRender] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState();
  const [name, setName] = useState();
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const [blockId, setBlockId] = useState(null);



  const fetchData = async () => {
    try {
      setLoading(true);
      const query = {};
      const res = await getDataHandlerWithToken("listRecipeType", query);
      if (res.responseCode === 200) {
        // Access the nested recipeTypes array
        setCouponList(res?.result?.recipeTypes || []);
        setFilteredData(res?.result?.recipeTypes || []);
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.error("Error fetching coupon list:", error);
    } finally {
      setLoading(false);
    }
  };
  
  
  const handleEdit = (id) => {
    navigate(`/recipeType/EditRecipeType`, {state:{recipeTypeId:id}});
  };
  const handleBlock = (id, name, status) => {
    setBlockId(id);
    setStatus(status == "ACTIVE" ? "BLOCK" : "ACTIVE");
    setName(name);
    setBlockModalOpen(true);
  };

  useEffect(() => {
    fetchData();
  }, [render]);
  const handleClickOpen = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };
  const handleBlockConfirm = async () => {
    // Handle block action
    const id = blockId;
    const query = {
      recipeTypeId: id,
    };

    try {
      const res = await putDataHandlerWithToken(
        "blockUnblockRecipeType",
        {},
        query
      );
      
      if (res.responseCode === 200) {
        //setRender(!render);
        setBlockModalOpen(false);
        
        fetchData();
       
        toast.success(res.responseMessage);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }

    console.log("Block button clicked for row id:", id);
  };
  const handleBlockCancel = () => {
    setBlockModalOpen(false);
  };

  const formatDateTime = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  const handleView = (id) => {
    console.log(id);
    navigate(`/couponListing/viewCoupon`, { state: { couponId: id } });
  };

  const handleDelete = async (id) => {
    const query = { recipeTypeId: id };
    try {
      const res = await deleteDataHandler("deleteRecipeType", query);
      if (res.responseCode === 200) {
        toast.success(res.responseMessage);
        fetchData();

        const updatedCouponList = couponList?.filter(
          (coupon) => coupon.id !== id
        );
        setCouponList(updatedCouponList||[]);
        setFilteredData(updatedCouponList|| []);
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      toast.error("Failed to delete coupon");
      console.error("Error deleting coupon:", error);
    } finally {
      handleClose();
    }
  };

  const handleFilterChange = (filterCriteria) => {
    let filtered = couponList;

    if (filterCriteria.searchText) {
      filtered = filtered.filter((coupon) =>
        coupon.name
          .toLowerCase()
          .includes(filterCriteria.searchText.toLowerCase().trim())
      );
    }

    const parseCustomDate = (romDate) => {
      const year = romDate.$y;
      const month = romDate.$M;
      const day = romDate.$D;
      const hours = romDate.$H;
      const minutes = romDate.$m;
      const seconds = romDate.$s;
      return new Date(year, month, day, hours, minutes, seconds);
    };

    if (filterCriteria.fromDate) {
      const fromDate = parseCustomDate(filterCriteria.fromDate);
      filtered = filtered.filter(
        (coupon) => new Date(coupon.createdAt) >= fromDate
      );
    }

    if (filterCriteria.toDate) {
      const toDate = parseCustomDate(filterCriteria.toDate);
      filtered = filtered.filter(
        (coupon) => new Date(coupon.createdAt) <= toDate
      );
    }

    if (
      filterCriteria.selectedValue &&
      filterCriteria.selectedValue !== "All"
    ) {
      filtered = filtered.filter(
        (coupon) => coupon.stage === filterCriteria.selectedValue
      );
    }

    setFilteredData(filtered);
  };

  const handleDownloadCSV = () => {
    const dataWithoutId = couponList?.map(({ _id, ...rest }) => rest);
    const headers = Object.keys(dataWithoutId[0]);
    let csvContent = headers.join(",") + "\n";
    csvContent += dataWithoutId
      .map((row) => headers.map((header) => row[header]).join(","))
      .join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;

    // Create the filename
    const filename = `RecipeType_${formattedDate}.csv`;

    // Use FileSaver.js to save the Blob as a file
    saveAs(blob, filename);
  };

  return (
    <DashboardLayout sectionName="Subscription" showBackButtonSection={false}>
      <Box className="category-container">
        <Box className="heading-container-top">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ ...typographyStyles.h4 }}>
              Recipe Types
            </Typography>
          </Box>
          <Box className="button-container">
            <Box className="button-sub-container">
              <BasicButtons text="Download CSV" onClick={handleDownloadCSV} />
              <BasicButtons
                text="+Add Recipe Type"
                onClick={() => navigate("/recipeType/AddRecipeType")}
              />
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box className="table-container">
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <Loading />
            </Box>
          ) : filteredData?.length < 1 ? (
            <DataNotFound />
          ) : (
            <PaginationTable
            columns={columns}
            data={Array.isArray(couponList) ? couponList.map((row, index) => {
              const stageKeys = se
                .filter((option) => row.stage.includes(option.value))
                .map((item) => item.key);
              const stageText = stageKeys.join(", ") || "Unknown Stage";
              return {
                sr_no: index + 1,
                stage: (
                  <Tooltip title={stageText} arrow>
                    <span>
                      {stageText.length > 20
                        ? `${stageText.substring(0, 20)}...`
                        : stageText}
                    </span>
                  </Tooltip>
                ),
                recipe_type: row.recipeType,
                created_date_time: new Date(row.createdAt).toLocaleString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  }
                ),
                status: row.status,
                action: (
                  <Box className="row-button-container" sx={{ gap: "15%" }}>
                    <EditIcon
                      onClick={() => handleEdit(row._id)}
                      sx={{
                        color: "rgb(225,140,168)",
                        borderColor: "rgb(225,140,168)",
                        fontFamily: "'Poppins', sans-serif",
                        cursor: "pointer",
                      }}
                    />
                    {/* <DeleteIcon
                      onClick={() => handleClickOpen(row)}
                      sx={{
                        color: "rgb(225,140,168)",
                        borderColor: "rgb(225,140,168)",
                        fontFamily: "'Poppins', sans-serif",
                        cursor: "pointer",
                      }}
                    /> */}
                    <BlockIcon
                      onClick={() =>
                        handleBlock(row._id, row.recipeType, row.status)
                      }
                      sx={{
                        color: row.status === "ACTIVE" ? "green" : "red",
                        borderColor: "rgb(225,140,168)",
                        fontFamily: "'Poppins', sans-serif",
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                ),
              };
            }) : []}
          />
          
          )}
        </Box>
      </Box>
      <AlertDialog
        open={open}
        handleClose={handleClose}
        handleDelete={() => handleDelete(selectedRow?._id)}
        title="Delete Recipe Type?"
        description={`Are you sure you want to delete the recipe type  "${selectedRow?.recipeType}"? This action cannot be undone.`}
      />
    {blockModalOpen && (
        <BlockModal
          title="Recipe Type"
          status={status}
          name={name}
          onClose={handleBlockCancel}
          onBlock={handleBlockConfirm}
        />
      )}

    </DashboardLayout>
  );
};

export default RecipeType;
