import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Card, CardMedia, Divider, Typography } from "@mui/material";
import typographyStyles from "../../../theme/Typography";
import InputField from "../../../components/InputField";
import BasicButtons from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import {
  postDataHandlerWithToken,
  postDataHandler,
} from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";
import "../../../scss/main.css";
import ImageCropper from "../../../utils/ImageCropper";

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
};

const AddKeyFeatures = () => {
  const navigate = useNavigate();
  const [iconPreview, setIconPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const validationSchema = Yup.object({
    featureTitle: Yup.string()

      .max(50, "Feature title must be 50 characters or less.")
      .required("Please enter the feature title."),
    featureIcon: Yup.mixed().required("Please upload the icon."),
  });
  const formik = useFormik({
    initialValues: {
      featureTitle: "",
      featureIcon: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values);
      try {
        setLoading(true);

        const iconUrl = croppedImage;

        const data = {
          title: values.featureTitle,
          imageUrl: iconUrl,
          contentType: "KEYFEATURES",
        };

        const response = await postDataHandlerWithToken(
          "addStaticContent",
          data
        );
        if (response.responseCode == 200) {
          toast.success("Feature added successfully");
          navigate("/staticcontent/listKeyFeatures");
        }
      } catch (error) {
        console.error("Error adding feature:", error);
        toast.error("Failed to add feature. Please try again later.");
      } finally {
        setLoading(false);
      }
    },
  });

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    setFieldValue,
  } = formik;

  const handleIconChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setFieldValue("featureIcon", file);
    }
  };
  const handleCropComplete = (cloudinaryUrl) => {
    setCroppedImage(cloudinaryUrl);

    setSelectedImage(null);
  };
  return (
    <DashboardLayout
      sectionName="Static Content Management"
      title="Add Key Feature"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>
            Add Key Feature
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <form className="add-edit-content-section" onSubmit={handleSubmit}>
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Feature Title:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="featureTitle"
                  placeholder="Enter the feature title"
                  value={values.featureTitle}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.featureTitle && touched.featureTitle ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.featureTitle}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Upload Icon:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="file"
                  name="featureIcon"
                  onBlur={handleBlur}
                  onChange={handleIconChange}
                  acceptedFile="image/*"
                />
                {errors.featureIcon && touched.featureIcon ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.featureIcon}
                  </p>
                ) : null}
                <br />
                {iconPreview && (
                  <Box sx={{ marginTop: "10px" }}>
                    <Card sx={{ maxWidth: 200, maxHeight: 200 }}>
                      <CardMedia component="img" image={iconPreview} />
                    </Card>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            className="add-edit-button-section"
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <BasicButtons
              text="Add"
              name="submit"
              type="submit"
              disabled={loading}
              loading={loading}
              width={"20%"}
            />
          </Box>
        </form>
      </Box>
      <ImageCropper
        image={selectedImage}
        aspectRatio={1}
        onCropComplete={handleCropComplete}
        onClose={() => setSelectedImage(null)}
      />
    </DashboardLayout>
  );
};

export default AddKeyFeatures;
