import React, { useState, useEffect } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Button, Tooltip, Typography, Divider } from "@mui/material";

import FilterBox from "../../../components/FilterBox";
import BasicButtons from "../../../components/Button";
import PaginationTable from "../../../components/PaginationTable";
import {
  getDataHandlerWithToken,
  putDataHandlerWithToken,
} from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import { saveAs } from "file-saver";

import "../../../scss/main.css";
import { useLocation, useNavigate } from "react-router-dom";
import BlockModal from "../../../Modal/BlockModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import typographyStyles from "../../../theme/Typography";
import LoadingPage from "../LoadingPage/LoadingPage";
import Loading from "../../../components/Loading";
import backBtn from "../../../assets/backBtn.svg";
import DataNotFound from "../../../components/DataNotFound";

const columns = [
  { id: "sr_no", label: "Sr.No." },
  { id: "trimester_week", label: "Trimester Week" },
  { id: "modified_date_time", label: "Last Modified Date & Time" },
  { id: "action", label: "Action" },
];

const TrimesterWeekListing = () => {
  const [trimesterWeek, setTrimesterWeek] = useState([]);
  const [render, setRender] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const [blockId, setBlockId] = useState(null);
  const [status, setStatus] = useState();
  const [name, setName] = useState();
  const [loading, setLoading] = useState(true);
  const location=useLocation();
  
 

  const Navigate = useNavigate();

  const handleView = (number) => {
    Navigate("/pregnancyTrackerListing/TrimesterWeeks/ViewWeek", { state: { weekNumber: number } });
   
  };

  const handleEdit = (number) => {
    // Handle edit action
    
      Navigate("/pregnancyTrackerListing/TrimesterWeeks/addEditWeek", { state: { weekNumber: number } });
  };
  const handleBlock = (id, name, status) => {
    setBlockId(id);
    setStatus(status == "ACTIVE" ? "BLOCK" : "ACTIVE");
    setName(name);
    setBlockModalOpen(true);
  };

  const handleBlockConfirm = async () => {
    // Handle block action
    const id = blockId;
    const query = {
      programId: id,
    };

    try {
      const res = await putDataHandlerWithToken(
        "blockUnblockProgram",
        {},
        query
      );
      console.log(res);
      if (res.responseCode === 200) {
        //setRender(!render);
        setBlockModalOpen(false);
        const updatedtrimsterWeek = trimesterWeek.map((program) =>
          program._id === id ? { ...program, status: status } : program
        );
        setTrimesterWeek(updatedtrimsterWeek);
        setFilteredData(updatedtrimsterWeek);
        toast.success(res.responseMessage);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }

    console.log("Block button clicked for row id:", id);
  };
  const handleBlockCancel = () => {
    setBlockModalOpen(false);
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      const query = {
        trimesterId:location?.state?.trimesterId ,
      };
      const res = await getDataHandlerWithToken("listWeekByTrimester", query);
      console.log("API response:", res);
      if (res.responseCode === 200) {
        setTrimesterWeek(res?.result || []);
        setFilteredData(res?.result);
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [render, toggle]);
  const handleFilterChange = (filterCriteria) => {
    let filtered = trimesterWeek;
    console.log(filterCriteria);

    if (filterCriteria.searchText) {
      filtered = filtered.filter((category) =>
        category.programName
          .toLowerCase()
          .includes(filterCriteria.searchText.toLowerCase().trim())
      );
      console.log("filtered: ", filtered);
    }

    const parseCustomDate = (romDate) => {
      const year = romDate.$y;
      const month = romDate.$M; // Months are 0-indexed in JavaScript Date
      const day = romDate.$D;
      const hours = romDate.$H;
      const minutes = romDate.$m;
      const seconds = romDate.$s;
      return new Date(year, month, day, hours, minutes, seconds);
    };

    if (filterCriteria.fromDate) {
      const fromDate = parseCustomDate(filterCriteria.fromDate);
      console.log("fromDate:", fromDate, " (", fromDate.toISOString(), ")");

      filtered = filtered.filter((category) => {
        const createdAtDate = new Date(category.createdAt);
        return createdAtDate >= fromDate;
      });

      console.log("Filtered categories:", filtered);
    }

    if (filterCriteria.toDate) {
      const toDate = parseCustomDate(filterCriteria.toDate);
      console.log(toDate);

      filtered = filtered.filter((category) => {
        const createdAtDate = new Date(category.createdAt);
        return createdAtDate <= toDate;
      });

      console.log("Filtered categories:", filtered);
    }

    if (
      filterCriteria.selectedValue &&
      filterCriteria.selectedValue !== "All"
    ) {
      console.log(filterCriteria.selectedValue);
      filtered = filtered.filter(
        (category) => category.stage === filterCriteria.selectedValue
      );
    }

    setFilteredData(filtered);
    console.log(filteredData);
  };
  console.log("week List:", trimesterWeek);
 

  return (
    <DashboardLayout
     
    >
      <Box className="category-container">
        <Box className="heading-container-top">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px"
            }}
          >
             <Box>
              <img
                src={backBtn}
                alt="backBtn"
                style={{
                  // // width: "2.813",
                  // height: "5.813",
                  cursor: "pointer",
                  gap: "10px",
                }}
                onClick={() => Navigate(-1)}
              />
            </Box>
            <Typography sx={{ ...typographyStyles.h4 }}>
              Week Listing
            </Typography>
          </Box>
         
        </Box>
        <Divider />
        <Box className="table-container">
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh", // Adjust height as needed
              }}
            >
              <Loading />
            </Box>
          ) : filteredData?.length < 1 ? (
            <DataNotFound/>
          ) :  (
            <PaginationTable
              columns={columns}
              data={filteredData?.map((obj, index) => ({
                sr_no: index + 1,
               

                trimester_week: (
                  obj.weekNumber
                ),
                modified_date_time: new Date(obj.updatedAt).toLocaleString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",

                    hour12: true,
                  }
                ),

                action: (
                  <Box className="row-button-container" sx={{ gap: "15%" }}>
                    <VisibilityIcon
                      variant="outlined"
                      onClick={() => handleView(obj.weekNumber)}
                      sx={{
                        color: "rgb(225,140,168)",
                        borderColor: "rgb(225,140,168)",
                        cursor: "pointer",
                      }}
                    />

                    <EditIcon
                      onClick={() => handleEdit(obj.weekNumber)}
                      sx={{
                        color: "rgb(225,140,168)",
                        borderColor: "rgb(225,140,168)",
                        fontFamily: "'Poppins', sans-serif",
                        cursor: "pointer",
                      }}
                    />

                    
                  </Box>
                ),
              }))}
            />
          )}
        </Box>
      </Box>
      {/* {blockModalOpen && (
        <BlockModal
          title="PROGRAM"
          status={status}
          name={name}
          onClose={handleBlockCancel}
          onBlock={handleBlockConfirm}
        />
      )} */}
    </DashboardLayout>
  );
};

export default TrimesterWeekListing;
