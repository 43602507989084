import React, { useState, useEffect } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Card,
  CardMedia,
} from "@mui/material";

import { useNavigate, useLocation, useParams } from "react-router-dom";
import { getDataHandlerWithToken } from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";
import typographyStyles from "../../../theme/Typography";


const addEditStyle = {
  addEditLabel: {
    fontWeight: "bold",
    
  },
  addEditInfo: {
    fontWeight: "normal",
    wordBreak: "break-word", // Ensure text wraps within the cell
    overflow: "hidden", // Hide overflow content
    textOverflow: "ellipsis", // Show ellipsis (...) for overflowing text
    
  },
};

const stripHtml = (html) => {
  let doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
};

const ViewWeek = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const [weekData, setWeekData] = useState(null);

  const fetchData = async () => {
    try {
      const query = {
        weekNumber: location?.state?.weekNumber,
      };

      const res = await getDataHandlerWithToken("viewWeek", query);
      if (res.responseCode === 200) {
        const data = res.result.description[0];
        setWeekData(data);
        
      }
    } catch (error) {
      toast.error(error.message || "Error fetching data");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>View Week Details</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <Box className="add-edit-content-section">
          <Box className="add-edit-content">
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Week Number:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {location?.state?.weekNumber}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      TextDescription:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      
                      {weekData?.TextDescription ? (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: weekData?.TextDescription,
                                    }}
                                    style={{ padding: "10px" }}
                                  />
                                ) : (
                                  <Typography>
                                    No text description available
                                  </Typography>
                                )}

                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Week Thumbnail:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                    <Card
                                  sx={{
                                    maxWidth: 200,
                                    maxHeight: 200,
                                    marginTop: "3px",
                                  }}
                                >
                                  <CardMedia
                                    component="img"
                                    image={weekData?.weekThumbnail}
                                  />
                                </Card>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      ContentDetails:
                    </TableCell>
                    
                    <TableCell sx={addEditStyle.addEditInfo}>
                      <table
                        style={{ width: "100%", borderCollapse: "collapse" }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                padding: "12px",
                                border: "1px solid #ddd",
                                backgroundColor: "rgb(225,140,168)",
                                width:"20%"

                              }}
                            >
                              Title
                            </th>
                            <th
                              style={{
                                padding: "12px",
                                border: "1px solid #ddd",
                                backgroundColor: "rgb(225,140,168)",
                                textAlign: "center",
                                width:"20%"

                              }}
                            >
                              Image
                            </th>
                            <th
                              style={{
                                padding: "12px",
                                border: "1px solid #ddd",
                                backgroundColor: "rgb(225,140,168)",
                                textAlign: "center",
                                width:"60%"
                                
                              }}
                            >
                              Description
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {weekData?.ContentDetails?.map((item, index) => (
                            <tr
                              key={index}
                              style={
                                index % 2 === 0
                                  ? { backgroundColor: "#f0f0f0" }
                                  : {}
                              }
                            >
                              <td
                                style={{
                                  padding: "12px",
                                  border: "1px solid #ddd",
                                }}
                              >
                                {item.title}
                              </td>
                              <td
                                style={{
                                  padding: "12px",
                                  border: "1px solid #ddd",
                                  textAlign: "center",
                                }}
                              >
                                <Card
                                  sx={{
                                    maxWidth: 200,
                                    maxHeight: 200,
                                    marginTop: "3px",
                                  }}
                                >
                                  <CardMedia
                                    component="img"
                                    image={item.image}
                                  />
                                </Card>
                              </td>
                              <td
                                style={{
                                  padding: "12px",
                                  border: "1px solid #ddd",
                                  textAlign: "center",
                                }}
                              >
                                
                                {item.description ? (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item.description,
                                    }}
                                    style={{ padding: "10px" }}
                                  />
                                ) : (
                                  <Typography>
                                    No description available
                                  </Typography>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      AccordianFeatures:
                    </TableCell>

                    <TableCell sx={addEditStyle.addEditInfo}>
                      <table
                        style={{ width: "100%", borderCollapse: "collapse" }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                padding: "12px",
                                border: "1px solid #ddd",
                                backgroundColor: "rgb(225,140,168)",
                                width:"30%"
                              }}
                            >
                              Title
                            </th>
                            <th
                              style={{
                                padding: "12px",
                                border: "1px solid #ddd",
                                backgroundColor: "rgb(225,140,168)",
                                textAlign: "center",
                              }}
                            >
                              Description
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {weekData?.AccordianFeatures?.map((item, index) => (
                            <tr
                              key={index}
                              style={
                                index % 2 === 0
                                  ? { backgroundColor: "#f0f0f0" }
                                  : {}
                              }
                            >
                              <td
                                style={{
                                  padding: "12px",
                                  border: "1px solid #ddd",
                                }}
                              >
                                {item.title}
                              </td>

                              <td
                                style={{
                                  padding: "12px",
                                  border: "1px solid #ddd",
                                  textAlign: "center",
                                }}
                              >
                                 {item.description ? (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item.description,
                                    }}
                                    style={{ padding: "10px" }}
                                  />
                                ) : (
                                  <Typography>
                                    No description available
                                  </Typography>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Symptoms:
                    </TableCell>

                    <TableCell sx={addEditStyle.addEditInfo}>
                      <table
                        style={{ width: "100%", borderCollapse: "collapse" }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                padding: "12px",
                                border: "1px solid #ddd",
                                backgroundColor: "rgb(225,140,168)",
                                width:"50%"

                              }}
                            >
                              Title
                            </th>
                            <th
                              style={{
                                padding: "12px",
                                border: "1px solid #ddd",
                                backgroundColor: "rgb(225,140,168)",
                                textAlign: "center",
                              }}
                            >
                              Image
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {weekData?.Symptoms?.map((item, index) => (
                            <tr
                              key={index}
                              style={
                                index % 2 === 0
                                  ? { backgroundColor: "#f0f0f0" }
                                  : {}
                              }
                            >
                              <td
                                style={{
                                  padding: "12px",
                                  border: "1px solid #ddd",
                                }}
                              >
                                {item.title}
                              </td>
                              <td
                                style={{
                                  padding: "12px",
                                  border: "1px solid #ddd",
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                }}
                              >
                                <Card
                                  sx={{
                                    maxWidth: 200,
                                    maxHeight: 200,
                                    marginTop: "3px",
                                  }}
                                >
                                  <CardMedia
                                    component="img"
                                    image={item.image}
                                  />
                                </Card>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>Tips:</TableCell>

                   

                    <TableCell sx={addEditStyle.addEditInfo}>
                      <table
                        style={{ width: "100%", borderCollapse: "collapse" }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                padding: "12px",
                                border: "1px solid #ddd",
                                backgroundColor: "rgb(225,140,168)",
                                width:"40%"

                              }}
                            >
                              Sr.No.
                            </th>
                            <th
                              style={{
                                padding: "12px",
                                border: "1px solid #ddd",
                                backgroundColor: "rgb(225,140,168)",
                                textAlign: "center",
                                width:"60%"

                              }}
                            >
                              Tips
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {weekData?.Tips?.map((item, index) => (
                            <tr
                              key={index}
                              style={
                                index % 2 === 0
                                  ? { backgroundColor: "#f0f0f0" }
                                  : {}
                              }
                            >
                              <td
                                style={{
                                  padding: "12px",
                                  border: "1px solid #ddd",
                                }}
                              >
                                 <p
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "24px",
                              height: "24px",
                              borderRadius: "50%",
                              backgroundColor: "#6cb5f9",
                              color: "#fff",
                              fontSize: "14px",
                              marginRight: "10px",
                            }}
                          >
                            {index + 1}
                          </p>
                              </td>

                              <td
                                style={{
                                  padding: "12px",
                                  border: "1px solid #ddd",
                                  textAlign: "center",
                                }}
                              >
                                {item.title}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>FAQ:</TableCell>

                    <TableCell sx={addEditStyle.addEditInfo}>
                      <table
                        style={{ width: "100%", borderCollapse: "collapse" }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                padding: "12px",
                                border: "1px solid #ddd",
                                backgroundColor: "rgb(225,140,168)",
                                width:"40%"

                              }}
                            >
                              Question
                            </th>
                            <th
                              style={{
                                padding: "12px",
                                border: "1px solid #ddd",
                                backgroundColor: "rgb(225,140,168)",
                                textAlign: "center",
                                width:"60%"

                              }}
                            >
                              Answer
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {weekData?.FAQ?.map((item, index) => (
                            <tr
                              key={index}
                              style={
                                index % 2 === 0
                                  ? { backgroundColor: "#f0f0f0" }
                                  : {}
                              }
                            >
                              <td
                                style={{
                                  padding: "12px",
                                  border: "1px solid #ddd",
                                }}
                              >
                                {item.question}
                              </td>

                              <td
                                style={{
                                  padding: "12px",
                                  border: "1px solid #ddd",
                                  textAlign: "center",
                                }}
                              >
                                {stripHtml(item.answer)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>Video:</TableCell>

                    <TableCell sx={addEditStyle.addEditInfo}>
                      <table
                        style={{ width: "100%", borderCollapse: "collapse" }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                padding: "12px",
                                border: "1px solid #ddd",
                                backgroundColor: "rgb(225,140,168)",
                              }}
                            >
                              Video:
                            </th>
                            <th
                              style={{
                                padding: "12px",
                                border: "1px solid #ddd",
                                backgroundColor: "rgb(225,140,168)",
                                textAlign: "center",
                              }}
                            >
                              Thumbnail:
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              style={{
                                padding: "12px",
                                border: "1px solid #ddd",
                              }}
                            >
                              <Card
                                sx={{
                                  maxWidth: 200,
                                  maxHeight: 200,
                                  marginTop: "3px",
                                }}
                              >
                                <CardMedia
                                  component="video"
                                  src={weekData?.Videos?.video}
                                  controls
                                />
                              </Card>
                            </td>

                            <td
                              style={{
                                padding: "12px",
                                border: "1px solid #ddd",
                                textAlign: "center",
                              }}
                            >
                              <Card
                                sx={{
                                  maxWidth: 200,
                                  maxHeight: 200,
                                  marginTop: "3px",
                                }}
                              >
                                <CardMedia
                                  component="img"
                                  image={weekData?.Videos?.image}
                                />
                              </Card>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Images:
                    </TableCell>
                   
                    <TableCell sx={addEditStyle.addEditInfo}>
                      <table
                        style={{ width: "100%", borderCollapse: "collapse" }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                padding: "12px",
                                border: "1px solid #ddd",
                                backgroundColor: "rgb(225,140,168)",
                              }}
                            >
                              Sr. Number
                            </th>
                            <th
                              style={{
                                padding: "12px",
                                border: "1px solid #ddd",
                                backgroundColor: "rgb(225,140,168)",
                                textAlign: "center",
                              }}
                            >
                              Image
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {weekData?.Images?.map((item, index) => (
                            <tr
                              key={index}
                              style={
                                index % 2 === 0
                                  ? { backgroundColor: "#f0f0f0" }
                                  : {}
                              }
                            >
                              <td
                                style={{
                                  padding: "12px",
                                  border: "1px solid #ddd",
                                }}
                              >
                                {index + 1}
                              </td>
                              <td
                                style={{
                                  padding: "12px",
                                  border: "1px solid #ddd",
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                }}
                              >
                                <Card
                                  sx={{
                                    maxWidth: 200,
                                    maxHeight: 200,
                                    marginTop: "3px",
                                  }}
                                >
                                  <CardMedia component="img" image={item} />
                                </Card>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default ViewWeek;
