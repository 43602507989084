import React from "react";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";

const buttonStyle = {
  buttonContent: {
    textTransform: "none",
    backgroundColor: "rgb(225, 140, 168)",
    color: "white",
    padding: "10px 20px",
    fontWeight: "700",
    fontSize: "15px",
    fontFamily: "'Poppins', sans-serif",
    boxShadow: "0 0 0.938rem 0 rgba(0, 0, 0, 0.2)",
    whiteSpace: "nowrap", // Prevents text from wrapping
    transition: "background-color 0.3s ease", // Smooth transition
  },
  buttonDisabled: {
    backgroundColor: "rgb(200, 200, 200)", // Adjust the color when disabled
    color: "rgba(255, 255, 255, 0.7)",
    boxShadow: "none",
  },
};

export default function BasicButtons({
  name,
  onClick,
  type,
  text,
  disabled,
  loading,
  width,
}) {
  return (
    <Button
      variant="contained"
      style={{
        ...buttonStyle.buttonContent,
        ...(disabled ? buttonStyle.buttonDisabled : {}),
        width: width || "100%",
      }}
      onClick={onClick}
      type={type}
      name={name}
      disabled={disabled}
    >
      {text} {loading && <CircularProgress size={20} sx={{ color: "white" }} />}
    </Button>
  );
}
