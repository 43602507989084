import React from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Button, Typography, Divider } from "@mui/material";
import PaginationTable from "../../../components/PaginationTable";
import { useNavigate } from "react-router-dom";
import typographyStyles from "../../../theme/Typography";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";

const staticContentStyle = {
  staticContainer: {
    // border: "2px solid blue",
    marginTop: "20px",
    padding: "0 25%",
  },
};

const columns = [
  { id: "title", label: "Title" },
  { id: "action", label: "Action" },
];

const data = [
  {
    title: "Privacy Policy",
    viewEndpoint: "/staticcontent/privacypolicy",
    editEndpoint: "/staticcontent/editprivacypolicy",
  },
  {
    title: "Terms & Conditions",
    viewEndpoint: "/staticcontent/termsandconditions",
    editEndpoint: "/staticcontent/edittermsandconditions",
  },
  { title: "FAQ", viewEndpoint: "/staticcontent/faqs" },
  {
    title: "About Us",
    viewEndpoint: "/staticcontent/aboutus",
    editEndpoint: "/staticcontent/editaboutus",
  },
  {
    title: "Banner Management",
    viewEndpoint: "/staticcontent/bannermanagement",
  },
  { title: "Team", viewEndpoint: "/staticcontent/team" },
  {
    title: "Cards Banner",
    viewEndpoint: "/staticcontent/cardsBanner",
  },
  {
    title: "Key Features and Benefits",
    viewEndpoint: "/staticcontent/listKeyFeatures",
  },
  {
    title: "Clinical Results",
    viewEndpoint: "/staticcontent/listClinicalResult",
  },
  {
    title: "Statistics",
    viewEndpoint: "/staticcontent/listStatistics",
  },
  {
    title: "Testimonial",
    viewEndpoint: "/staticcontent/testimonialmanagement",
  },
  
];

const StaticContentListing = () => {
  const Navigate = useNavigate();

  const handleView = (endPoint) => {
    // Handle block action
    // console.log("Block button clicked for row id:", id);
    Navigate(endPoint);
  };

  const handleEdit = (endPoint) => {
    // Handle edit action
    // console.log("Edit button clicked for row id:", id);
    Navigate(endPoint);
  };

  return (
    <DashboardLayout>
      <Box className="heading-container-top">
        <Box
          sx={{
            padding: 1,
          }}
        >
          <Typography sx={{ ...typographyStyles.h4 }}>
            Static Content Management
          </Typography>
        </Box>
      </Box>
      <Divider />

      <Box sx={staticContentStyle.staticContainer}>
        <PaginationTable
          columns={columns}
          data={data.map((row, index) => ({
            ...row,
            action: (
              <Box className="row-button-container">
                <VisibilityIcon
                  variant="outlined"
                  onClick={() => handleView(row.viewEndpoint)}
                  sx={{
                    color: "rgb(225,140,168)",
                    borderColor: "rgb(225,140,168)",
                    fontFamily: "'Poppins', sans-serif",
                    cursor: "pointer",
                  }}
                />

                {row.editEndpoint && (
                  <EditIcon
                    variant="outlined"
                    onClick={() => handleEdit(row.editEndpoint)}
                    sx={{
                      color: "rgb(225,140,168)",
                      borderColor: "rgb(225,140,168)",
                      fontFamily: "'Poppins', sans-serif",
                      cursor: "pointer",
                    }}
                  />
                )}
              </Box>
            ),
          }))}
        />
      </Box>
    </DashboardLayout>
  );
};

export default StaticContentListing;
