import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Typography, Divider, Card, CardMedia } from "@mui/material";
import typographyStyles from "../../../theme/Typography";
import BasicButtons from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import InputField from "../../../components/InputField";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import "../../../scss/main.css";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  getDataHandlerWithToken,
  postDataHandlerWithToken,
  postDataHandler,
} from "../../../apiconfig/service";
import backBtn from "../../../assets/backBtn.svg";

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
  radioContainer: {
    display: "flex",
  },
};

const dropdownOptions = [
  "Trying to Conceive",
  "Pregnant",
  "Postpartum (0-6 Weeks After Birth)",
  "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
];

const se = [
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum (0-6 Weeks After Birth)", value: "POSTPARTUM" },
  {
    key: "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
    value: "BEYOND",
  },
];

const EditActivation = () => {
  const [selectedValue, setSelectedValue] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [editorContent, setEditorContent] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mydata, setMydata] = useState([]);
  const [charCount, setCharCount] = useState(0);
  const charLimit = 500; // Set your character limit here

  const navigate = useNavigate();
  const { id } = useParams();

  const handleDropdownChange = (value) => {
    setSelectedValue(value);
    formik.setFieldValue("selectRange", value);
    setSelectedCategory("");
    setCategoryId("");
    console.log("Selected Dropdown Value:", value);
    filterCategoriesByStage(value);
  };

  const handleThumbnailChange = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    console.log("Selected File:", file);

    try {
      setLoading(true);
      const res = await postDataHandler("uploadFile", formData);
      console.log("File Upload Response:", res);
      if (res.responseCode === 200) {
        setThumbnail(res.result);
        formik.setFieldValue("categoryImage", res.result);
      }
    } catch (error) {
      console.log("File Upload Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const filterCategoriesByStage = (selectedValue) => {
    const stageValue = se.find((option) => option.key === selectedValue)?.value;
    if (stageValue) {
      const filteredCategories = mydata.filter(
        (category) => category.stage === stageValue
      );
      setCategoryList(filteredCategories);
    }
  };

  useEffect(() => {
    filterCategoriesByStage(selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const res = await getDataHandlerWithToken(`getActivateExercise`, {
          id,
        });
        if (res.responseCode === 200) {
          const data = res.result;
          setSelectedValue(
            se.find((option) => option.value === data.stage).key
          );
          setEditorContent(data.learn);
          setThumbnail(data.image);
          formik.setFieldValue("selectRange", data.stage);
          formik.setFieldValue("description", data.learn);
          formik.setFieldValue("categoryImage", data.image);
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        console.error("Failed to fetch data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const validationSchema = Yup.object({
    selectRange: Yup.string().required("Please select a stage."),
    categoryImage: Yup.mixed().required("Please upload file."),
    description: Yup.string()
      .required("Please enter description.")
      .max(500, "Description can not be more than 500 characters."),
  });

  const formik = useFormik({
    initialValues: {
      selectRange: "",
      categoryImage: null,
      description: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const res = await postDataHandlerWithToken(
          "addUpdateActivateExercise",
          {
            exerciseId: id,
            stage: se.find((option) => option.key === selectedValue).value,
            learn: editorContent,
            image: values.categoryImage,
          }
        );
        console.log("Edit Activation Result:", res);
        if (res.responseCode === 200) {
          toast.success(res.responseMessage);
          navigate("/activatelisting");
        } else {
          toast.error(res.data.responseMessage);
          console.error(res);
        }
      } catch (error) {
        console.error("Failed to edit activation : ", error);
      } finally {
        setLoading(false);
      }
    },
  });

  const { handleBlur, errors, touched } = formik;

  return (
    <DashboardLayout
      sectionName="Content - Program Management"
      title="Edit Activation"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{
                cursor: "pointer",
              }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>
            Edit Activation
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <form
          className="add-edit-content-section"
          onSubmit={formik.handleSubmit}
        >
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Select Stage:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <Dropdown
                  name="selectRange"
                  onBlur={handleBlur}
                  sx={addEditStyle.categoryInputField}
                  options={dropdownOptions}
                  value={selectedValue}
                  onChange={handleDropdownChange}
                  placeholder="Select stage"
                />
                {errors.selectRange && touched.selectRange ? (
                  <p style={{ color: "red", marginTop: "5px", float: "left" }}>
                    {errors.selectRange}
                  </p>
                ) : null}
              </Box>
            </Box>

            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Learn How to Activate:
                </Typography>
              </Box>
              <Box
                className="add-edit-input-field ckeditor-container"
                sx={{ width: "100%" }}
              >
                <CKEditor
                  editor={ClassicEditor}
                  data={editorContent}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    console.log("Editor Content:", data);
                    setEditorContent(data);
                    formik.setFieldValue("description", data);
                    const plainText = data.replace(/<[^>]*>/g, "");
                    setCharCount(plainText.length);
                  }}
                  config={{
                    toolbar: [
                      "heading",
                      "|",
                      "bold",
                      "italic",
                      "link",
                      "bulletedList",
                      "numberedList",
                      "blockQuote",
                    ],
                  }}
                />
                {errors.description && touched.description ? (
                  <p style={{ color: "red", marginTop: "5px", float: "left" }}>
                    {errors.description}
                  </p>
                ) : null}
                 <Typography
                variant="subtitle2"
                  sx={{
                    
                    textAlign: "right",
                    mt: 1,
                    color: charCount > charLimit ? "red" : "textSecondary",
                  }}
                >
                  {charCount}/{charLimit} characters
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className="add-edit-input-container">
            <Box className="add-edit-input-label">
              <Typography sx={addEditStyle.addEditLabel}>
                Activate new GIF(Upload animation in mp4 format):
              </Typography>
            </Box>
            <Box className="add-edit-input-field">
              <InputField
                type="file"
                name="files"
                onChange={handleThumbnailChange}
                onBlur={handleBlur}
                acceptedFile="video/mp4"
              />

              {errors.categoryImage && touched.categoryImage && (
                <p style={{ color: "red", marginTop: "5px", float: "left" }}>
                  {errors.categoryImage}
                </p>
              )}
              {thumbnail && (
                <Box>
                  <Card
                    sx={{ maxWidth: 250, maxHeight: "auto", margin: "5px" }}
                  >
                    <CardMedia component="video" image={thumbnail}  controls/>
                  </Card>
                </Box>
              )}
            </Box>
          </Box>
          <Box
            className="add-edit-button-section"
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <BasicButtons
              text={"Update"}
              disabled={loading}
              name="submit"
              type="submit"
              width={"20%"}
              loading={loading}
            />
          </Box>
        </form>
      </Box>
    </DashboardLayout>
  );
};

export default EditActivation;
