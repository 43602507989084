import React,{useState} from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Typography, Divider } from "@mui/material";
import typographyStyles from "../../../theme/Typography";
import InputField from "../../../components/InputField";
import BasicButtons from "../../../components/Button";
import { changePasswordSchema } from "../../../schema";
import { useFormik } from "formik";
import { Navigate, useNavigate } from "react-router-dom";
import {
 
  postDataHandlerWithToken,
} from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";

const changePasswordStyle = {
  changePasswordContainer: {
    // border: '2px solid green',
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
  },

  titleSection: {
    // border: '2px solid red',
  },

  changePasswordTitle: {
    ...typographyStyles.h4,
  },

  contentSection: {
    // border: '2px solid yellow',
    padding: "20px 25%",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },

  inputBox: {
    // border: '2px solid yellow',
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },

  changePasswordLabel: {
    ...typographyStyles.h6,
  },

  btnBox: {
    display: "flex",
    justifyContent: "center"
  }
};

const ChangePassword = () => {
  const [loading, setLoading]=useState(false)
  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const Navigation = useNavigate();
  const handleFormSubmit = async (values) => {
    // setErrorMessage("");
    const data = {
      currentPassword: values.oldPassword,
      newPassword: values.newPassword,
    };
    try {
      setLoading(true);

      const res = await postDataHandlerWithToken("changePassword", data);

      if (res?.responseCode === 200) {
        toast.success(res?.responseMessage);

        // auth.checkLogin(res?.token);

        Navigation("/myaccount");

        //setLoading(false);
      } else {
        //setErrorMessage(res?.data?.responseMessage);
        console.log(res);
        toast.error(res?.data?.responseMessage);

        //setIsLoading(false);
      }
      //console.log("removed");
    } catch (error) {
      //setIsLoading(false);
    }finally{
      setLoading(false)
    }
  };

  const { values, errors, handleBlur, handleChange, touched, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: changePasswordSchema,
      onSubmit: (values) => handleFormSubmit(values),
    });

  return (
    <DashboardLayout>
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap:"10px"
          }}
        >
           <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{
                // // width: "2.813",
                // height: "5.813",
                cursor: "pointer",
              }}
              onClick={() => Navigation(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>
            Change Password
          </Typography>

         
        </Box>
      </Box>
      <Divider />
      <Box sx={changePasswordStyle.changePasswordContainer}>
        <form
          style={changePasswordStyle.contentSection}
          onSubmit={handleSubmit}
        >
          <Box sx={changePasswordStyle.inputBox}>
            <Typography sx={changePasswordStyle.changePasswordLabel}>
              Old Password
            </Typography>
            <InputField
              className="authTextField"
              type="password"
              name="oldPassword"
              value={values.password}
              maxLength={20}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Enter the password"
              restrictCopyPaste={true}
            />
            {errors.oldPassword && touched.oldPassword ? (
              <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                {errors.oldPassword}
              </p>
            ) : null}
          </Box>
          <Box sx={changePasswordStyle.inputBox}>
            <Typography sx={changePasswordStyle.changePasswordLabel}>
              New Password
            </Typography>
            <InputField
              className="authTextField"
              type="password"
              name="newPassword"
              value={values.newPassword}
              maxLength={20}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Enter the password"
              restrictCopyPaste={true}
            />
            {errors.newPassword && touched.newPassword ? (
              <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                {errors.newPassword}
              </p>
            ) : null}
          </Box>
          <Box sx={changePasswordStyle.inputBox}>
            <Typography sx={changePasswordStyle.changePasswordLabel}>
              Confirm Password
            </Typography>
            <InputField
              className="authTextField"
              type="password"
              name="confirmPassword"
              value={values.confirmPassword}
              maxLength={20}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Enter the password"
              restrictCopyPaste={true}
            />
            {errors.confirmPassword && touched.confirmPassword ? (
              <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                {errors.confirmPassword}
              </p>
            ) : null}
          </Box>
          <Box sx={changePasswordStyle.btnBox}>
            <BasicButtons text="Save" type="submit" loading={loading} name="submit" width={"25%"} />
          </Box>
        </form>
      </Box>
    </DashboardLayout>
  );
};

export default ChangePassword;
