import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  Typography,
  Divider,
  Grid,
  Card,
  CardMedia,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import "../../../scss/main.css";
import typographyStyles from "../../../theme/Typography";
import ViewImg from "../../../assets/viewImg.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { getDataHandlerWithToken } from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },

  addEditLabel: {
    ...typographyStyles.h6,
    whiteSpace: "nowrap",
  },

 
  
  addEditInfo: {
    fontWeight: "normal",
    wordBreak: "break-word", // Ensure text wraps within the cell
    overflow: "hidden", // Hide overflow content
    textOverflow: "ellipsis", // Show ellipsis (...) for overflowing text
    
  },

  radioContainer: {
    display: "flex",
  },

  // imagePreview: {
  //     marginTop: '10px',
  //     objectFit: 'cover',
  //     border: '2px solid gray',
  // }
};
const stripHtml = (html) => {
  let doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
};

const ViewSubcategory = () => {
  const [subCategory, setSubcategory] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const fetchProgram = async () => {
    const query = {
      subcategoryId: location.state.subcategoryId,
    };
    try {
      const res = await getDataHandlerWithToken(
        "viewWorkoutSubCategory",
        query
      );
      console.log(res?.result);
      if (res.responseCode === 200) {
        setSubcategory(res?.result);
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchProgram();
  }, []);

  return (
    <DashboardLayout
      sectionName="Content - Program + Topic Management"
      title="Topic General Detials"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{
                // // width: "2.813",
                // height: "5.813",
                cursor: "pointer",
              }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>
            View Workout Subcategory
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <Box className="add-edit-content-section">
          <Box className="add-edit-content">
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Workout Subcategory Name:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {subCategory?.workoutSubCategoryName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Thumbnail Image:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {subCategory?.image?.map((preview, index) => (
                        <Grid item xs={3} key={index}>
                          <Card sx={{ maxWidth: 300,maxHeight: "auto" }}>
                            <CardMedia
                              component="img"
                              image={preview}
                              alt={`Preview ${index}`}
                            />
                          </Card>
                        </Grid>
                      ))}
                    </TableCell>
                  </TableRow>
                  {/* <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Reminder
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {subCategory?.reminder}
                    </TableCell>
                  </TableRow> */}
                  {/* <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Equipments Required
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {subCategory?.equipmentRequired}
                    </TableCell>
                  </TableRow> */}
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Time Required(in min.):
                      
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {subCategory?.time}
                    </TableCell>
                  </TableRow>
                  

                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                    Exercises:
                    </TableCell>
                    
                    <TableCell sx={addEditStyle.addEditInfo}>
                      <table
                        style={{ width: "100%", borderCollapse: "collapse" }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                padding: "12px",
                                border: "1px solid #ddd",
                                backgroundColor: "rgb(225,140,168)",
                              }}
                            >
                             Exercise Title
                            </th>
                            <th
                              style={{
                                padding: "12px",
                                border: "1px solid #ddd",
                                backgroundColor: "rgb(225,140,168)",
                                textAlign: "center",
                              }}
                            >
                             
                             Video
                            </th>
                            <th
                              style={{
                                padding: "12px",
                                border: "1px solid #ddd",
                                backgroundColor: "rgb(225,140,168)",
                                textAlign: "center",
                              }}
                            >
                              Thumbnail
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {subCategory?.exercises?.map((item, index) => (
                            <tr
                              key={index}
                              style={
                                index % 2 === 0
                                  ? { backgroundColor: "#f0f0f0" }
                                  : {}
                              }
                            >
                              <td
                                style={{
                                  padding: "12px",
                                  border: "1px solid #ddd",
                                }}
                              >
                                {item.title}
                              </td>
                              <td
                                style={{
                                  padding: "12px",
                                  border: "1px solid #ddd",
                                  textAlign: "center",
                                }}
                              >
                                <Card
                                  sx={{
                                    maxWidth: 200,
                                    maxHeight: 200,
                                    marginTop: "3px",
                                  }}
                                >
                                  <CardMedia
                                    component="video"
                                    image={item.video}
                                    alt={"video"}
                                    controls
                                  />
                                </Card>
                              </td>
                              <td
                                style={{
                                  padding: "12px",
                                  border: "1px solid #ddd",
                                  textAlign: "center",
                                }}
                              >
                                <Card
                                  sx={{
                                    maxWidth: 200,
                                    maxHeight: 200,
                                    marginTop: "3px",
                                  }}
                                >
                                  <CardMedia
                                    component="img"
                                    image={item.image}
                                    alt={"Thumbnail image"}
                                    
                                  />
                                </Card>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </TableCell>
                  </TableRow>
                  

                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                    Videos:
                    </TableCell>
                    
                    <TableCell sx={addEditStyle.addEditInfo}>
                      <table
                        style={{ width: "100%", borderCollapse: "collapse" }}
                      >
                        <thead>
                          <tr>
                          <th
                              style={{
                                padding: "12px",
                                border: "1px solid #ddd",
                                backgroundColor: "rgb(225,140,168)",
                              }}
                            >
                             Sr No.
                            </th>
                            <th
                              style={{
                                padding: "12px",
                                border: "1px solid #ddd",
                                backgroundColor: "rgb(225,140,168)",
                              }}
                            >
                             Video
                            </th>
                            <th
                              style={{
                                padding: "12px",
                                border: "1px solid #ddd",
                                backgroundColor: "rgb(225,140,168)",
                                textAlign: "center",
                              }}
                            >
                             
                            Thumbnail
                            </th>
                           
                          </tr>
                        </thead>
                        <tbody>
                          {subCategory?.videos?.map((item, index) => (
                            <tr
                              key={index}
                              style={
                                index % 2 === 0
                                  ? { backgroundColor: "#f0f0f0" }
                                  : {}
                              }
                            >
                              <td
                                style={{
                                  padding: "12px",
                                  border: "1px solid #ddd",
                                }}
                              >
                                {index+1}
                              </td>
                              <td
                                style={{
                                  padding: "12px",
                                  border: "1px solid #ddd",
                                  textAlign: "center",
                                }}
                              >
                                <Card
                                  sx={{
                                    maxWidth: 200,
                                    maxHeight: 200,
                                    marginTop: "3px",
                                  }}
                                >
                                  <CardMedia
                                    component="video"
                                    image={item.video}
                                    alt={"video"}
                                    controls
                                  />
                                </Card>
                              </td>
                              <td
                                style={{
                                  padding: "12px",
                                  border: "1px solid #ddd",
                                  textAlign: "center",
                                }}
                              >
                                <Card
                                  sx={{
                                    maxWidth: 200,
                                    maxHeight: 200,
                                    marginTop: "3px",
                                  }}
                                >
                                  <CardMedia
                                    component="img"
                                    image={item.image}
                                    alt={"Thumbnail image"}
                                    
                                  />
                                </Card>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Description:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      

                      {subCategory?.description ? (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: subCategory?.description,
                                    }}
                                    style={{ padding: "10px" }}
                                  />
                                ) : (
                                  <Typography>
                                    No description available
                                  </Typography>
                                )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Exercise instructions:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      

                      {subCategory?.exercisesInstructions ? (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: subCategory?.exercisesInstructions,
                                    }}
                                    style={{ padding: "10px" }}
                                  />
                                ) : (
                                  <Typography>
                                    No exercise instructions available
                                  </Typography>
                                )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default ViewSubcategory;
