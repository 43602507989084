import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Card, CardMedia, Divider, Typography } from "@mui/material";
import typographyStyles from "../../../theme/Typography";
import InputField from "../../../components/InputField";
import BasicButtons from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import {
  postDataHandlerWithToken,
  postDataHandler,
} from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";
import "../../../scss/main.css";
import ImageCropper from "../../../utils/ImageCropper";

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
};

const AddClinicalResult = () => {
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);


  const formik = useFormik({
    initialValues: {
      percentages: "",
      title: "",
      description: "",
      image: "",
    },
    validationSchema: Yup.object({
      percentages: Yup.number()
        .min(0, "Percentage must be between 0 and 100")
        .max(100, "Percentage must be between 0 and 100")
        .required("Please enter the percentage."),
      title: Yup.string()
        .min(5, "Title must be at least 5 characters.")
        .max(40, "Title must be 40 characters or less.")
        .required("Please enter the title."),
      description: Yup.string()
        .min(5, "Description must be at least 5 characters.")
        .max(60, "Description must be 60 characters or less.")
        .required("Please enter the description."),
      image: Yup.mixed().required("Please upload an image."),
    }),
    onSubmit: async (values) => {
      console.log(values);
      try {
        setLoading(true);
        

        const imageUrl = croppedImage;

        const data = {
          percentages: values.percentages,
          title: values.title,
          description: values.description,
          imageUrl: imageUrl,
        };

        const response = await postDataHandlerWithToken(
          "addClinicalResult",
          data
        );
        if (response.responseCode == 200) {
          toast.success("Clinical result added successfully");
          navigate("/staticcontent/listClinicalResult");
        }
      } catch (error) {
        console.error("Error adding clinical result:", error);
        toast.error("Failed to add clinical result. Please try again later.");
      } finally {
        setLoading(false);
      }
    },
  });

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    setFieldValue,
  } = formik;

  const handleImageChange = (event) => {
   
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setFieldValue("image", file);
    }
  };
  const handleCropComplete = (cloudinaryUrl) => {
    setCroppedImage(cloudinaryUrl);
    setImagePreview(cloudinaryUrl);
    setSelectedImage(null);
  };

  return (
    <DashboardLayout
      sectionName="Clinical Results Management"
      title="Add Clinical Result"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>
            Add Clinical Result
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <form className="add-edit-content-section" onSubmit={handleSubmit}>
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Percentage:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="number"
                  name="percentages"
                  placeholder="Enter the percentage"
                  value={values.percentages}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.percentages && touched.percentages ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.percentages}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>Title:</Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="title"
                  placeholder="Enter the title"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={40}
                />
                {errors.title && touched.title ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.title}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Description:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="description"
                  placeholder="Enter the description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={500}
                />
                {errors.description && touched.description ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.description}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Upload Image:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="file"
                  name="image"
                  onBlur={handleBlur}
                  onChange={handleImageChange}
                  acceptedFile="image/*"
                />
                {errors.image && touched.image ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.image}
                  </p>
                ) : null}
                {imagePreview && (
                  <Box sx={{ marginTop: "10px" }}>
                    <Card sx={{ maxWidth: 200, maxHeight: 200 }}>
                      <CardMedia component="img" image={imagePreview} />
                    </Card>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            className="add-edit-button-section"
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <BasicButtons
              text="Add"
              name="submit"
              type="submit"
              disabled={loading}
              loading={loading}
              width={"20%"}
            />
          </Box>
        </form>
      </Box>
      <ImageCropper
        image={selectedImage}
        aspectRatio={9/16}
        onCropComplete={handleCropComplete}
        onClose={() => setSelectedImage(null)}
      />
    </DashboardLayout>
  );
};

export default AddClinicalResult;
