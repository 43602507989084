// const url = "http://172.16.6.42:2121/api/v1";
// export const baseUrl = "https://node-health2mama.mobiloitte.io/";
// const url = `${baseUrl}/api/v1`;
const url = "https://node-health2mama.mobiloitte.io/api/v1";
// const url = "http://localhost:2121/api/v1";

const ApiConfig = {
  url,
  adminLogin: `${url}/admin/adminLogin`,
  verifyOTPAdmin: `${url}/admin/verifyOTP`,
  resetPassword: `${url}/admin/resetPassword`,
  changePassword: `${url}/admin/changePassword`,
  resendOTPAdmin: `${url}/admin/resendOTP`,
  updateProfile: `${url}/admin/updateProfile`,
  viewProfile: `${url}/admin/viewProfile`,

  //static content management
  addStaticContent: `${url}/staticContent/addStaticContent`,
  getAllstaticContentByType: `${url}/staticContent/getAllstaticContentByType`,
  updateStaticContent: `${url}/staticContent/updateStaticContent`,
  deleteStaticContent: `${url}/staticContent/deleteStaticContent`,
  addClinicalResult: `${url}/staticContent/addClinicalResult`,
  updateClinicalResult: `${url}/staticContent/updateClinicalResult`,
  deleteClinicalResult: `${url}/staticContent/deleteClinicalResult`,
  getAllClinicalresults: `${url}/staticContent/getAllClinicalresults`,
  getClinicalResultById: `${url}/staticContent/getClinicalResultById`,
  getAllStatistics: `${url}/staticContent/getAllStatistics`,
  updateStatistic: `${url}/staticContent/updateStatistic`,
  addTestimonial: `${url}/staticContent/addTestimonial`,
  getAllTestimonials: `${url}/staticContent/getAllTestimonials`,
  getTestimonial: `${url}/staticContent/getTestimonial`,
  updateTestimonial: `${url}/staticContent/updateTestimonial`,
  deleteTestimonial: `${url}/staticContent/deleteTestimonial`,
  togglePublishTestimonial: `${url}/staticContent/togglePublishTestimonial`,
  addUpdateTeam: `${url}/staticContent/addUpdateTeam`,
  getAllTeam: `${url}/staticContent/getAllTeam`,
  deleteTeamMember: `${url}/staticContent/deleteTeamMember`,
  //category section
  addUpdateCategory: `${url}/admin/addUpdateCategory`,
  deleteCategory: `${url}/admin/deleteCategory`,
  viewCategory: `${url}/admin/viewCategory`,
  listCategory: `${url}/admin/listCategory`,
  blockUnblockCategory: `${url}/admin/blockUnblockCategory`,
  //program+topic
  listProgram: `${url}/admin/listProgram`,
  viewProgram: `${url}/admin/viewProgram`,
  listTopic: `${url}/admin/listTopic`,
  addUpdateProgram: `${url}/admin/addUpdateProgram`,
  blockUnblockProgram: `${url}/admin/blockUnblockProgram`,
  blockUnblockTopic: `${url}/admin/blockUnblockTopic`,
  viewTopic: `${url}/admin/viewTopic`,
  addUpdateTopic: `${url}/admin/addUpdateTopic`,
  //upload file
  uploadFile: `${url}/user/uploadFile`,
  uploadVideo: `${url}/user/uploadVideoFile`,

  //banner
  addUpdateBanner: `${url}/admin/addUpdateBanner`,
  getAllBanners: `${url}/admin/listBanners`,
  getBanner: `${url}/admin/viewBanner`,

  //Workout
  addUpdateWorkoutCategory: `${url}/admin/addUpdateWorkoutCategory`,
  deleteWrokoutCategory: `${url}/admin/deleteWrokoutCategory`,
  viewWorkoutCategory: `${url}/admin/viewWorkoutCategory`,
  listWorkoutCategory: `${url}/admin/listWorkoutCategory`,
  blockUnblockWorkoutCategory: `${url}/admin/blockUnblockWorkoutCategory`,

  //workout Subcategory
  addUpdateWorkoutSubCategory: `${url}/admin/addUpdateWorkoutSubCategory`,
  deleteWrokoutSubCategory: `${url}/admin/deleteWrokoutSubCategory`,
  viewWorkoutSubCategory: `${url}/admin/viewWorkoutSubCategory`,
  listWorkoutSubCategory: `${url}/admin/listWorkoutSubCategory`,
  blockUnblockWorkoutSubCategory: `${url}/admin/blockUnblockWorkoutSubCategory`,

  //Exercises
  addUpdateExercise: `${url}/admin/addUpdateExercise`,
  deleteExercise: `${url}/admin/deleteExercise`,
  viewExercise: `${url}/admin/viewExercise`,
  listExercises: `${url}/admin/listExercises`,
  blockUnblockExercise: `${url}/admin/blockUnblockExercise`,

  //dietary
  blockUnblockDietary: `${url}/admin/blockUnblockDietary`,
  addUpdateDietary: `${url}/admin/addUpdateDietary`,
  listDietary: `${url}/admin/listDietary`,
  viewDietary: `${url}/admin/viewDietary`,

  //Cuisine
  addUpdateCuisine: `${url}/admin/addUpdateCuisine`,
  listCuisines: `${url}/admin/listCuisines`,
  blockUnblockCuisine: `${url}/admin/blockUnblockCuisine`,
  viewCuisine: `${url}/admin/viewCuisine`,

  getAllBanners: `${url}/admin/listBanners`,
  getBanner: `${url}/admin/viewBanner`,
  deleteBanner: `${url}/admin/deleteBanner`,

  //dietType

  addUpdateDiet: `${url}/admin/addUpdateDiet`,
  getDietList: `${url}/admin/listDiets`,
  getDiet: `${url}/admin/viewDiet`,
  blockUnblockdiet: `${url}/admin/blockUnblockDiet`,

  //recipelisting

  getrecipeList: `${url}/admin/listRecipes`,
  blockrecipe: `${url}/admin/blockUnblockRecipe`,
  addUpdaterecipe: `${url}/admin/addUpdateRecipe`,
  addUpdaterecipe: `${url}/admin/addUpdateRecipe`,
  viewRecipe: `${url}/admin/viewRecipe`,

  //Forum
  listForums: `${url}/admin/listForums`,
  blockUnblockForum: `${url}/admin/blockUnblockForum`,
  viewForum: `${url}/admin/viewForum`,

  //Services and Specialization
  getAllServicesOrSpecializations: `${url}/admin/getAllServicesOrSpecializations`,

  //Mum Management
  getMumList: `${url}/admin/listMums`,
  blockUnblockMum: `${url}/admin/blockUnblockMum`,

  //Consults managment
  addUpdateConsult: `${url}/admin/addUpdateConsult`,
  listConsult: `${url}/admin/listConsult`,
  viewConsult: `${url}/admin/viewConsult`,
  blockUnblockConsult: `${url}/admin/blockUnblockConsult`,

  //Booking Management
  bookingList: `${url}/admin/listBooking`,
  viewBooking: `${url}/admin/viewBooking`,
  viewMum: `${url}/admin/viewMum`,

  addUpdateServiceOrSpecialization: `${url}/admin/addUpdateServiceOrSpecialization`,
  getServiceOrSpecialization: `${url}/admin/getServiceOrSpecialization`,
  blockUnblockServiceAndSpecialization: `${url}/admin/blockUnblockServiceAndSpecialization`,
  //coupen-management
  createCoupon: `${url}/stripe/createCoupon`,
  viewCoupon: `${url}/stripe/viewCoupon`,
  deleteCoupon: `${url}/stripe/deleteCoupon`,
  listCoupons: `${url}/stripe/listCoupons`,
  viewCoupon: `${url}/stripe/viewCoupon`,

  //subscription management
  viewSubscription: `${url}/admin/viewSubscription`,
  listSubscriptions: `${url}/admin/listSubscriptions`,
  addUpdateSubscription: `${url}/admin/addUpdateSubscription`,
  blockUnblockSubscription: `${url}/admin/blockUnblockSubscription`,

  //Pregnancy Tracker Management
  getTrimester: `${url}/admin/getTrimester`,
  listWeekByTrimester: `${url}/admin/listWeekByTrimester`,
  updateWeek: `${url}/admin/updateWeek`,
  viewWeek: `${url}/admin/viewWeek`,
  addUpdateActivateExercise: `${url}/admin/addUpdateActivateExercise`,
  listActivateExercise: `${url}/admin/listActivateExercise`,
  getActivateExercise: `${url}/admin/getActivateExercise`,

  //options
  addUpdateOption: `${url}/admin/addUpdateOption`,
  blockUnblockOption: `${url}/admin/blockUnblockOption`,
  listOptions: `${url}/admin/listOptions`,

  //ticket management
  listContactUs: `${url}/admin/listContactUs`,

  //send ticket reply mail
  sendEmail: `${url}/admin/sendEmail`,

  //recipetype
  addUpdateRecipeType: `${url}/admin/addUpdateRecipeType`,
  viewRecipeType: `${url}/admin/viewRecipeType`,
  deleteRecipeType: `${url}/admin/deleteRecipeType`,
  listRecipeType: `${url}/admin/listRecipeType`,
  blockUnblockRecipeType: `${url}/admin/blockUnblockRecipeType`,

  //////////////////
  getDashboardData: `${url}/admin/getDashboardData`,
};
export default ApiConfig;
