import React, { useState, useEffect } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Divider
} from "@mui/material";
import typographyStyles from "../../../theme/Typography";
import BasicButtons from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import InputField from "../../../components/InputField";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { addNewCategorySchema, dietarySchema } from "../../../schema";
import backBtn from "../../../assets/backBtn.svg";


import "../../../scss/main.css";
import { toast } from "react-hot-toast";
import { getDataHandlerWithToken, postDataHandlerWithToken } from "../../../apiconfig/service";
import MultiSelectDropdown from "../../../components/MultiSelectDropdown";

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },

  addEditLabel: {
    ...typographyStyles.h6,
  },

  radioContainer: {
    display: "flex",
  },
};

const dropdownOptions = [ 'Trying to Conceive', 'Pregnant', 'Postpartum (0-6 Weeks After Birth)', 'Beyond -(6 Weeks + After Birth (Weeks, months, Years))'];
const se=[{key:"Trying to Conceive",value:"TRYINGTOCONCEIVE"},{key:"Pregnant",value:"PREGNANT"},{key:"Postpartum (0-6 Weeks After Birth)",value:"POSTPARTUM"},{key:"Beyond -(6 Weeks + After Birth (Weeks, months, Years))" , value:"BEYOND"}]

const EditDietary = () => {
  const [dietary, setDietary] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const fetchDietary = async () => {
    const query = {
      dietaryId: location.state?.dietaryId,
    };
    try {
      const res = await getDataHandlerWithToken("viewDietary", query);
      if (res.responseCode === 200) {
        setDietary(res?.result);
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDietary();
  }, []);

  const initialValues = {
    selectStage: dietary?.stage || [], // Assuming dietary.stage is an array of selected stages
    dietaryName: dietary?.dietaryName || "",
    // saveAs: dietary?.saveAs || "", // Changed to match schema
  };

  const handleFormSubmit = async (values) => {
    const data = {
      dietaryId: location?.state?.dietaryId,
      stage: values.selectStage,
      dietaryName: values.dietaryName,
      // saveAs: values.saveAs, // Changed to match schema
    };
    try {
      setLoading(true);
      const res = await postDataHandlerWithToken("addUpdateDietary", data);
      if (res?.responseCode === 200) {
        toast.success(res?.responseMessage);
        navigate("/dietarylisting");
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: dietarySchema,
    enableReinitialize: true,
    onSubmit: (values) => handleFormSubmit(values),
  });

  const { values, errors, handleBlur, handleChange, touched, handleSubmit, setFieldValue } = formik;

  const handleCategoryTypeChange = (event) => {
    setFieldValue("saveAs", event.target.value); // Changed to match schema
  };

  return (
    <DashboardLayout sectionName="Dietary Preference Management" title="Edit Dietary">
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>Edit dietary</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <form className="add-edit-content-section" onSubmit={handleSubmit}>
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>Select Stage</Typography>
              </Box>
              <Box className="add-edit-input-field">
                <MultiSelectDropdown
                  name="selectStage"
                  onBlur={handleBlur}
                  sx={addEditStyle.categoryInputField}
                  options={se}
                  value={values.selectStage}
                  onChange={(value) =>
                    handleChange({ target: { name: "selectStage", value } })
                  }
                />
                {errors.selectStage && touched.selectStage ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.selectStage}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>Dietary Preference Name</Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="dietaryName"
                  value={values.dietaryName}
                  maxLength={50} // Increased to match schema
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter dietary name"
                />
                {errors.dietaryName && touched.dietaryName ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.dietaryName}
                  </p>
                ) : null}
              </Box>
            </Box>
            {/* <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>Save As*</Typography>
              </Box>
              <Box className="add-edit-input-field">
                <FormControl component="fieldset">
                  <RadioGroup
                    name="saveAs" // Changed to match schema
                    value={values.saveAs}
                    onChange={handleCategoryTypeChange}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <FormControlLabel
                      value="FREE"
                      className="radio"
                      control={<Radio />}
                      label="Free"
                    />
                    <FormControlLabel
                      className="radio"
                      value="LOCKED"
                      control={<Radio />}
                      label="Locked (will be accessed after subscription)"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box> */}
          </Box>
          <Box className="add-edit-button-section" sx={{ alignItems: "center", justifyContent: "center" }}>
            <BasicButtons text="Update Dietary" name="submit" type="submit" loading={loading} width={"20%"} />
          </Box>
        </form>
      </Box>
    </DashboardLayout>
  );
};




export default EditDietary;
