import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  Typography,
  Button,
  Divider,
  Grid,
  Card,
  CardMedia,
  IconButton,
} from "@mui/material";
import * as Yup from "yup";
import typographyStyles from "../../../theme/Typography";
import BasicButtons from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import InputField from "../../../components/InputField";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import "../../../scss/main.css";
import {
  getDataHandlerWithToken,
  postDataHandlerWithToken,
} from "../../../apiconfig/service";
import backBtn from "../../../assets/backBtn.svg";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { CircularProgress } from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { commonGridStyle } from "../../../scss/style";

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
  addButton: {
    textTransform: "none",
    backgroundColor: "rgb(225,140,168)",
    color: "white",
    fontWeight: "600",
    fontSize: "18px",
    fontFamily: "'Poppins', sans-serif",
    boxShadow: "0 0 0.938rem 0 rgba(0, 0, 0, 0.2)",
    marginTop: "2px",
  },
  subtitle: {
    fontWeight: "bold",
    marginBottom: "4px",
    fontSize: "0.875rem", // Adjust as needed
    color: "#333", // Adjust color as needed
  },

  addRemoveButton: {
    textTransform: "none",
    backgroundColor: "rgb(225,140,168)",
    color: "white",
    fontWeight: "400",
    fontSize: "15px",
    fontFamily: "'Poppins', sans-serif",
    boxShadow: "0 0 0.938rem 0 rgba(0, 0, 0, 0.2)",
    marginTop: "2px",
    marginLeft: "2px",
    "&:hover": {
      backgroundColor: "white",
      color: "rgb(225,140,168)",
    },
  },
};

const AddWeekDetails = () => {
  const [loading, setLoading] = useState(false);
  const [uploadedFileURLs, setUploadedFileURLs] = useState([]);
  const [thumbnail, setThumbnail] = useState(null);

  const [imagePreviews, setImagePreviews] = useState([]);

  const [videos, setVideos] = useState({ video: "", image: "" });
  const [loadingImage, setLoadingImage] = useState(false);
  const [loadingVideo, setLoadingVideo] = useState(false);

  const [accordionTitleDescriptions, setAccordionTitleDescriptions] = useState([
    { title: "", description: "" },
  ]);
  const [contentDetails, setContentDetails] = useState([
    { title: "", description: "", image: "" },
  ]);
  const [symptoms, setSymptoms] = useState([{ title: "", image: "" }]);
  const [tips, setTips] = useState([{ title: "" }]);
  const [faqs, setFaqs] = useState([{ question: "", answer: "" }]);
  const [textDescription, setTextDecription] = useState("");

  const location = useLocation();
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    TextDescription: Yup.string()

      .trim()

      .max(1000, "Text description cannot exceed 1200 characters."),

    accordionTitleDescriptions: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required("Please enter the title."),
        description: Yup.string()
          .max(500, "Description cannot exceed 1200 characters.")
          .required("Please enter description."),
      })
    ),

    contentDetails: Yup.array().of(
      Yup.object({
        title: Yup.string().required("Please enter the title."),
        description: Yup.string()
          .max(500, "Description cannot exceed 1200 characters.")
          .required("Please enter description."),
        image: Yup.mixed().required("Please upload image."),
      })
    ),
  });

  
  const removeIds = (array) => {
    return array.map(({ _id, ...rest }) => rest);
  };

  const fetchData = async () => {
    try {
      const query = {
        weekNumber: location?.state?.weekNumber,
      };
      const res = await getDataHandlerWithToken("viewWeek", query);
      if (res.responseCode === 200) {
        const data = res.result;
        const sanitizedContentDetails = removeIds(
          data?.description[0]?.ContentDetails || [
            { title: "", description: "", image: "" },
          ]
        );
        const sanitizedAccordianFeatures = removeIds(
          data.description[0]?.AccordianFeatures || [
            { title: "", description: "" },
          ]
        );
        const sanitizedSymptoms = removeIds(
          data.description[0]?.Symptoms || [{ title: "", image: "" }]
        );
        const sanitizedTips = removeIds(
          data.description[0]?.Tips || [{ title: "" }]
        );
        const sanitizedFaqs = removeIds(
          data.description[0]?.FAQ || [{ question: "", answer: "" }]
        );

        setUploadedFileURLs(data.description[0]?.Images || []);
        setVideos(data.description[0]?.Videos || {});
        setContentDetails(sanitizedContentDetails);
        setAccordionTitleDescriptions(sanitizedAccordianFeatures);
        setSymptoms(sanitizedSymptoms);
        setTips(sanitizedTips);
        setFaqs(sanitizedFaqs);
        setThumbnail(data.description[0]?.weekThumbnail || ""); // Set thumbnail state
        setTextDecription(data.description[0]?.TextDescription || "");

        formik.setValues({
          image: data.description[0]?.Images || [],
          video: data.description[0]?.Videos || {},
          description: data.description[0]?.TextDescription || "",
          contentDetails: sanitizedContentDetails,
          accordionTitleDescriptions: sanitizedAccordianFeatures,
          symptoms: sanitizedSymptoms,
          tips: sanitizedTips,
          faqs: sanitizedFaqs,
          weekThumbnail: data.description[0]?.weekThumbnail || "", // Set formik weekThumbnail value
        });

        formik.setFieldValue(
          "description",
          data.description[0]?.TextDescription
        );
      }
    } catch (error) {
      toast.error(error.message || "Error fetching data");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const handleThumbnailChange = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    try {
      setLoading(true);
      const res = await postDataHandlerWithToken("uploadFile", formData);
      if (res.responseCode === 200) {
        setThumbnail(res.result);
        formik.setFieldValue("weekThumbnail", res.result);
      }
    } catch (error) {
      console.error("Error uploading thumbnail:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileSelect = async (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      if (file.type.startsWith("image/")) {
        try {
          setLoadingImage(true);
          // Read the file as a data URL for immediate preview
          const reader = new FileReader();

          // Prepare form data for uploading
          const formData = new FormData();
          formData.append("file", file);

          // Upload the file
          const res = await postDataHandlerWithToken("uploadFile", formData);
          console.log("im");

          if (res?.responseCode === 200) {
            reader.onload = () => {
              setImagePreviews((prevPreviews) => [
                ...prevPreviews,
                reader.result,
              ]);
            };
            reader.readAsDataURL(file);
            setUploadedFileURLs((prevURLs) => [...prevURLs, res.result]);
            formik.setFieldValue("image", [...formik.values.image, res.result]);
          } else {
            toast.error(res?.responseMessage || "File upload failed");
          }
        } catch (error) {
          console.error("Error uploading file:", error);
          toast.error("Error uploading file");
        } finally {
          setLoadingImage(false);
          console.log(formik.values.image);
        }
      } else {
        alert("Please select an image file.");
      }
    }
  };

  const handleFileSelectImage = async (event, index) => {
    const file = event.target.files[0];
    if (file) {
      setLoadingImage(true);
      const reader = new FileReader();
      const formData = new FormData();
      formData.append("file", file);
      const res = await postDataHandlerWithToken("uploadFile", formData);
      console.log(res);

      if (res.responseCode === 200) {
        reader.onload = () => {
          const newContentDetails = [...contentDetails];
          newContentDetails[index].image = res.result;
          setContentDetails(newContentDetails);
          setLoadingImage(false);
        };

        reader.readAsDataURL(file);
      }
    }
  };
  const handleRemoveStepContent = (index, array, setArray, type) => {
    const newArray = array.filter((_, i) => i !== index);
    setArray(newArray);
  };

  const handleAddValueContent = (array, setArray) => {
    setArray([...array, { title: "", description: "", image: "" }]);
  };

  const handleFileSelectSymptoms = async (event, index) => {
    const file = event.target.files[0];
    if (file) {
      setLoadingImage(true);
      const reader = new FileReader();
      const formData = new FormData();
      formData.append("file", file);
      const res = await postDataHandlerWithToken("uploadFile", formData);
      if (res.responseCode === 200) {
        reader.onloadend = () => {
          const newSymptoms = [...symptoms];
          newSymptoms[index].image = res.result;
          setSymptoms(newSymptoms);
          setLoadingImage(false);
        };
        reader.readAsDataURL(file);
      }
    }
  };
  const handleRemoveStepSymptoms = (index, array, setArray, type) => {
    const newArray = array.filter((_, i) => i !== index);
    setArray(newArray);
  };

  const handleAddValueSymtoms = (array, setArray) => {
    setArray([...array, { title: "", image: "" }]);
  };

  const handleRemoveStep = (index, dataType, setDataType, field) => {
    const newData = [...dataType];
    newData.splice(index, 1);
    setDataType(newData);
    if (field.length > 0) {
      formik.setFieldValue(field, [...newData]);
    }

    //console.log(uploadedFileURLs);
  };

  const handleVideoSelect = async (event, index, type, field) => {
    const file = event.currentTarget.files[0];
    if (file) {
      try {
        setLoadingVideo(true);
        const reader = new FileReader();
        const formData = new FormData();
        formData.append("file", file);

        const isImage = file.type.startsWith("image/");
        const res = await postDataHandlerWithToken(
          isImage ? "uploadFile" : "uploadVideo",
          formData
        );

        if (res?.responseCode === 200) {
          const newVideos = { ...videos, [type]: res.result };
          setVideos(newVideos);
          formik.setFieldValue(field, newVideos);
        } else {
          toast.error(res?.responseMessage || "File upload failed");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        toast.error("Error uploading file");
      } finally {
        setLoadingVideo(false);
      }
    }
  };

  const handleAddValueTapsAcco = (dataType, setDataType) => {
    setDataType([...dataType, {}]);
  };

  const handleFormSubmit = async (values) => {
    console.log("hhhh", values);
    const data = {
      weekNumber: location?.state?.weekNumber,
      image: uploadedFileURLs,
      description: textDescription,
      video: videos,
      contentDetails: contentDetails,
      accordionTitleDescriptions: accordionTitleDescriptions,
      symptoms: symptoms,
      tips: tips,
      faqs: faqs,
      weekThumbnail: thumbnail,
    };

    const transformedData = {
      weekNumber: data.weekNumber,
      description: [
        {
          weekThumbnail: data.weekThumbnail,
          Images: data.image, // assuming `uploadedFileURLs` is an array of image URLs
          TextDescription: data.description, // assuming `values.description` is a string
          Videos: videos,
          ContentDetails: data.contentDetails,
          AccordianFeatures: data.accordionTitleDescriptions,
          Symptoms: data.symptoms,
          Tips: data.tips, // assuming `tips` is an array of strings
          FAQ: data.faqs,
        },
      ],
    };

    // To log the transformed data
    console.log("Transformed Data:", transformedData);

    try {
      setLoading(true);
      const res = await postDataHandlerWithToken("updateWeek", transformedData);
      if (res?.responseCode === 200) {
        toast.success(res?.responseMessage);
        navigate(-1);
      } else {
        toast.error(res?.responseMessage);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form");
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      image: [],
      video: {},
      TextDescription: "",
      contentDetails: [{ title: "", description: "", image: "" }],
      accordionTitleDescriptions: [{ title: "", description: "" }],
      symptoms: [{ title: "", image: "" }],
      tips: [{ title: "" }],
      faqs: [{ question: "", answer: "" }],
      weekThumbnail: "", // Added weekThumbnail initialization
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("form");
      handleFormSubmit(values);
    },
  });

  return (
    <DashboardLayout>
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>
            Add Week Details
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <form
          className="add-edit-content-section"
          onSubmit={formik.handleSubmit}
        >
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Week Thumbnail:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="file"
                  name="weekThumbnail"
                  onChange={handleThumbnailChange}
                  onBlur={formik.handleBlur}
                />

                {thumbnail && (
                  <Box>
                    <Card
                      sx={{ maxWidth: 250, maxHeight: "auto", margin: "5px" }}
                    >
                      <CardMedia component="img" image={thumbnail} />
                    </Card>
                  </Box>
                )}
                {formik.errors.weekThumbnail &&
                  formik.touched.weekThumbnail && (
                    <p
                      style={{ color: "red", marginTop: "2px", float: "left" }}
                    >
                      {formik.errors.weekThumbnail}
                    </p>
                  )}
              </Box>
            </Box>
            <Divider sx={{ mb: "15px" }} />

            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Top Section Images:
                </Typography>
              </Box>
              <Box className="add-edit-input-field" sx={commonGridStyle}>
                <Button
                  sx={{ backgroundColor: "rgb(225, 140, 168)" }}
                  variant="contained"
                  component="label"
                  startIcon={<AddIcon />}
                >
                  Image{" "}
                  {loadingImage && (
                    <CircularProgress size={20} sx={{ color: "white" }} />
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={handleFileSelect}
                  />
                </Button>

                <Grid container spacing={2} sx={{ mt: "10px" }}>
                  {uploadedFileURLs.map((preview, index) => (
                    <Grid item xs={3} key={index}>
                      <Card sx={{ maxWidth: 250, maxHeight: "auto" }}>
                        <CardMedia
                          component="img"
                          image={preview}
                          alt={`Preview ${index}`}
                        />
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            // handleRemoveStep(
                            //   index,
                            //   imagePreviews,
                            //   setImagePreviews,
                            //   ""
                            // );
                            handleRemoveStep(
                              index,
                              uploadedFileURLs,
                              setUploadedFileURLs,
                              "image"
                            );
                          }}
                        >
                          <DeleteIcon sx={{ color: "rgb(225, 140, 168)" }} />
                        </IconButton>
                      </Card>
                    </Grid>
                  ))}
                </Grid>

                {formik.errors.image && formik.touched.image ? (
                  <p style={{ color: "red", marginTop: "20px", float: "left" }}>
                    {formik.errors.image}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Divider sx={{ mb: "15px" }} />

            <Box className="add-edit-input-container" sx={{ mt: "15px" }}>
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Description:
                </Typography>
              </Box>
              <Box
                className="add-edit-input-field ckeditor-container"
                sx={{ width: "100%" }}
              >
                <Grid item xs={6} md={12}>
                  <CKEditor
                    editor={ClassicEditor}
                    name="TextDescription"
                    data={textDescription}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      formik.setFieldValue("TextDescription", data);
                      setTextDecription(data);
                    }}
                    config={{
                      toolbar: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "underline",
                        "strikethrough",
                        "|",
                        "bulletedList",
                        "numberedList",
                        "blockQuote",
                        "|",

                        "undo",
                        "redo",
                      ],
                      // height: "200px",
                    }}
                    className="StaticEditor"
                  />

                  {formik.errors.TextDescription && (
                    <p
                      style={{ color: "red", marginTop: "2px", float: "left" }}
                    >
                      {formik.errors.TextDescription}
                    </p>
                  )}
                </Grid>
              </Box>
            </Box>
            <Divider sx={{ mb: "15px" }} />
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>Videos:</Typography>
              </Box>
              <Box className="add-edit-input-field">
                <Box mb={2}>
                  <Grid container alignItems="center" sx={commonGridStyle}>
                    <Grid item xs={5}>
                      {videos?.video?.length !== 0 ? (
                        <Card
                          sx={{
                            maxWidth: 250,
                            maxHeight: "auto",
                          }}
                        >
                          <CardMedia
                            component="video"
                            src={videos.video}
                            alt="Video Preview"
                            controls
                          />
                          <Button
                            sx={{
                              backgroundColor: "rgb(225, 140, 168)",
                              margin: "5px",
                            }}
                            variant="contained"
                            component="label"
                            startIcon={<EditIcon />}
                          >
                            Video
                            <input
                              type="file"
                              acceptedFile="video/*"
                              hidden
                              onChange={(e) =>
                                handleVideoSelect(e, 0, "video", "videos")
                              }
                            />
                          </Button>
                        </Card>
                      ) : (
                        <Button
                          sx={{
                            backgroundColor: "rgb(225, 140, 168)",
                            margin: "5px",
                          }}
                          variant="contained"
                          component="label"
                          startIcon={<AddIcon />}
                        >
                          Video
                          <input
                            type="file"
                            acceptedFile="video/*"
                            hidden
                            onChange={(e) =>
                              handleVideoSelect(e, 0, "video", "videos")
                            }
                          />
                        </Button>
                      )}
                    </Grid>

                    <Grid item xs={5} ml={2}>
                      {videos?.image?.length == 0 ? (
                        <Button
                          sx={{ backgroundColor: "rgb(225, 140, 168)" }}
                          variant="contained"
                          component="label"
                          startIcon={<AddIcon />}
                        >
                          Thumbnail
                          <input
                            type="file"
                            accept="image/*"
                            hidden
                            onChange={(e) =>
                              handleVideoSelect(e, 0, "image", "videos")
                            }
                          />
                        </Button>
                      ) : (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Card
                            sx={{
                              width: 250,
                              height: "auto",
                            }}
                          >
                            <CardMedia
                              component="img"
                              src={videos.image}
                              alt="Image Preview"
                            />

                            <Button
                              sx={{
                                backgroundColor: "rgb(225, 140, 168)",
                                margin: "5px",
                              }}
                              variant="contained"
                              component="label"
                              startIcon={<EditIcon />}
                            >
                              Thumbnail
                              <input
                                type="file"
                                accept="image/*"
                                hidden
                                onChange={(e) =>
                                  handleVideoSelect(e, 0, "image", "videos")
                                }
                              />
                            </Button>
                          </Card>
                        </Box>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    />
                  </Grid>
                </Box>
                {formik.errors.videos && formik.touched.videos ? (
                  <p style={{ color: "red", float: "left", marginTop: "10px" }}>
                    {formik.errors.videos}
                  </p>
                ) : null}
              </Box>
            </Box>

            <Divider sx={{ mb: "15px" }} />
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={{ ...typographyStyles.h6 }}>
                  Content Details:
                </Typography>
              </Box>
              <Box className="add-edit-input-field" sx={{ width: "100%" }}>
                {contentDetails?.map((pair, index) => (
                  <Box key={index} mb={2}>
                    <Grid
                      container
                      direction={"row"}
                      alignItems="center"
                      sx={commonGridStyle}
                    >
                      <Grid item xs={6} md={12} width="100%" mb={2}>
                        <InputField
                          label="Title"
                          placeholder="Enter Title"
                          value={pair.title}
                          onChange={(e) => {
                            const newContentDetails = [...contentDetails];
                            newContentDetails[index].title = e.target.value;
                            setContentDetails(newContentDetails);
                            formik.setFieldValue(
                              "contentDetails",
                              newContentDetails
                            );
                          }}
                          fullWidth
                        />
                        {formik.touched.contentDetails?.[index]?.title &&
                          formik.errors.contentDetails?.[index]?.title && (
                            <p
                              style={{
                                color: "red",
                                marginTop: "5px",
                                float: "left",
                              }}
                            >
                              {formik.errors.contentDetails[index].title}
                            </p>
                          )}
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={12}
                        className="add-edit-input-field ckeditor-container"
                        sx={{ width: "100%" }}
                      >
                        <CKEditor
                          editor={ClassicEditor}
                          data={pair.description || ""}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            const newContentDetails = [...contentDetails];
                            newContentDetails[index].description = data;
                            setContentDetails(newContentDetails);
                            formik.setFieldValue(
                              "contentDetails",
                              newContentDetails
                            );
                          }}
                          config={{
                            toolbar: [
                              "heading",
                              "|",
                              "bold",
                              "italic",
                              "underline",
                              "strikethrough",
                              "|",
                              "bulletedList",
                              "numberedList",
                              "blockQuote",
                              "|",

                              "undo",
                              "redo",
                            ],
                          }}
                          className="StaticEditor"
                        />

                        {formik.touched.contentDetails?.[index]?.description &&
                          formik.errors.contentDetails?.[index]
                            ?.description && (
                            <p
                              style={{
                                color: "red",
                                marginTop: "5px",
                                float: "left",
                              }}
                            >
                              {formik.errors.contentDetails[index].description}
                            </p>
                          )}
                      </Grid>
                      <Grid item xs={6} md={6} mt={2}>
                        {!pair.image ? (
                          <Button
                            sx={{ backgroundColor: "rgb(225, 140, 168)" }}
                            variant="contained"
                            component="label"
                            startIcon={<AddIcon />}
                          >
                            Add Image{" "}
                            {loadingImage && (
                              <CircularProgress
                                size={20}
                                sx={{ color: "white" }}
                              />
                            )}
                            <input
                              type="file"
                              accept="image/*"
                              hidden
                              onChange={(event) =>
                                handleFileSelectImage(event, index)
                              }
                            />
                          </Button>
                        ) : (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Card
                              sx={{
                                maxWidth: 250,
                                maxHeight: "auto",
                                position: "relative",
                              }}
                            >
                              <CardMedia
                                component="img"
                                image={pair.image}
                                alt="Preview"
                              />
                              <IconButton
                                aria-label="delete"
                                onClick={() => {
                                  const newContentDetails = [...contentDetails];
                                  newContentDetails[index].image = "";
                                  setContentDetails(newContentDetails);
                                }}
                                sx={{ color: "rgb(225, 140, 168)" }}
                              >
                                <DeleteIcon />
                              </IconButton>
                              <IconButton
                                aria-label="edit"
                                onClick={() =>
                                  document
                                    .getElementById(`image-upload-${index}`)
                                    .click()
                                }
                                sx={{ color: "rgb(225, 140, 168)" }}
                              >
                                <EditIcon />
                              </IconButton>
                            </Card>
                            <input
                              id={`image-upload-${index}`}
                              type="file"
                              accept="image/*"
                              hidden
                              onChange={(event) =>
                                handleFileSelectImage(event, index)
                              }
                            />
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        {formik.touched.contentDetails?.[index]?.image &&
                          formik.errors.contentDetails?.[index]?.image && (
                            <p
                              style={{
                                color: "red",
                                marginTop: "5px",
                                float: "left",
                              }}
                            >
                              {formik.errors.contentDetails[index].image}
                            </p>
                          )}
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            gap: "10px",
                          }}
                        >
                          {contentDetails?.length > 1 && (
                            <Grid
                              item
                              xs={2}
                              md={1}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              marginTop="25px"
                            >
                              <IconButton
                                onClick={() =>
                                  handleRemoveStepContent(
                                    index,
                                    contentDetails,
                                    setContentDetails,
                                    "contentDetails"
                                  )
                                }
                                sx={{ ...addEditStyle.addRemoveButton }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          )}
                          {index === contentDetails?.length - 1 && (
                            <Grid
                              item
                              xs={2}
                              md={1}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              marginTop="25px"
                            >
                              <IconButton
                                onClick={() =>
                                  handleAddValueContent(
                                    contentDetails,
                                    setContentDetails
                                  )
                                }
                                sx={addEditStyle.addRemoveButton}
                              >
                                <AddCircleOutlineRoundedIcon />
                              </IconButton>
                            </Grid>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </Box>
            </Box>

            <Divider sx={{ mb: "15px" }} />

            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Accordions: Titles and Descriptions:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                {accordionTitleDescriptions.map((pair, index) => (
                  <Box key={index} mb={2}>
                    <Grid container sx={commonGridStyle}>
                      <Grid item xs={6} md={12} width="100%" mb={2}>
                        <InputField
                          label="Title"
                          placeholder="Enter Title"
                          value={pair.title}
                          onChange={(e) => {
                            const newTitleDescriptions = [
                              ...accordionTitleDescriptions,
                            ];
                            newTitleDescriptions[index].title = e.target.value;
                            setAccordionTitleDescriptions(newTitleDescriptions);
                            formik.setFieldValue(
                              "accordionTitleDescriptions",
                              newTitleDescriptions
                            );
                          }}
                        />

                        {formik.touched.accordionTitleDescriptions?.[index]
                          ?.title &&
                          formik.errors.accordionTitleDescriptions?.[index]
                            ?.title && (
                            <p
                              style={{
                                color: "red",
                                marginTop: "5px",
                                float: "left",
                              }}
                            >
                              {
                                formik.errors.accordionTitleDescriptions?.[
                                  index
                                ]?.title
                              }
                            </p>
                          )}
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={12}
                        className="add-edit-input-field ckeditor-container"
                        sx={{ width: "100%" }}
                      >
                        <CKEditor
                          editor={ClassicEditor}
                          data={pair.description || ""}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            const newTitleDescriptions = [
                              ...accordionTitleDescriptions,
                            ];
                            newTitleDescriptions[index].description = data;
                            setAccordionTitleDescriptions(newTitleDescriptions);
                            formik.setFieldValue(
                              "accordionTitleDescriptions",
                              newTitleDescriptions
                            );
                          }}
                          config={{
                            toolbar: [
                              "heading",
                              "|",
                              "bold",
                              "italic",
                              "underline",
                              "strikethrough",
                              "|",
                              "bulletedList",
                              "numberedList",
                              "blockQuote",
                              "|",

                              "undo",
                              "redo",
                            ],
                          }}
                          className="StaticEditor"
                        />
                       
                        {formik.touched.accordionTitleDescriptions?.[index]
                          ?.description &&
                          formik.errors.accordionTitleDescriptions?.[index]
                            ?.description && (
                            <p
                              style={{
                                color: "red",
                                marginTop: "5px",
                                float: "left",
                              }}
                            >
                              {
                                formik.errors.accordionTitleDescriptions?.[
                                  index
                                ]?.description
                              }
                            </p>
                          )}
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            gap: "10px",
                            mt: "5px",
                          }}
                        >
                          {accordionTitleDescriptions.length > 1 && (
                            <IconButton
                              onClick={() =>
                                handleRemoveStep(
                                  index,
                                  accordionTitleDescriptions,
                                  setAccordionTitleDescriptions,
                                  "accordionTitleDescriptions"
                                )
                              }
                              sx={{ ...addEditStyle.addRemoveButton }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                          {index === accordionTitleDescriptions.length - 1 && (
                            <IconButton
                              onClick={() =>
                                handleAddValueTapsAcco(
                                  accordionTitleDescriptions,
                                  setAccordionTitleDescriptions
                                )
                              }
                              sx={addEditStyle.addRemoveButton}
                            >
                              <AddCircleOutlineRoundedIcon />
                            </IconButton>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </Box>
            </Box>
            <Divider sx={{ mb: "15px" }} />
            <Box className="add-edit-input-container" sx={{ width: "100%" }}>
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Symptoms:
                </Typography>
              </Box>
              <Box className="add-edit-input-field" sx={{ width: "100%" }}>
                {symptoms.map((pair, index) => (
                  <Box key={index} mb={2}>
                    <Grid container alignItems="center" sx={commonGridStyle}>
                      <Grid item xs={5}>
                        <InputField
                          label="Title"
                          placeholder="Enter Title"
                          value={pair.title}
                          onChange={(e) => {
                            const newSymptoms = [...symptoms];
                            newSymptoms[index].title = e.target.value;
                            setSymptoms(newSymptoms);
                          }}
                          fullWidth
                          error={Boolean(
                            formik.errors.symptoms?.[index]?.title
                          )}
                          helperText={formik.errors.symptoms?.[index]?.title}
                        />
                      </Grid>
                      <Grid item xs={5} padding={2}>
                        {!pair.image ? (
                          <Button
                            sx={{ backgroundColor: "rgb(225, 140, 168)" }}
                            variant="contained"
                            component="label"
                            startIcon={<AddIcon />}
                          >
                            Add Image{" "}
                            {loadingImage && (
                              <CircularProgress
                                size={20}
                                sx={{ color: "white" }}
                              />
                            )}
                            <input
                              type="file"
                              accept="image/*"
                              hidden
                              onChange={(event) =>
                                handleFileSelectSymptoms(event, index)
                              }
                            />
                          </Button>
                        ) : (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Card
                              sx={{
                                maxWidth: 300,
                                maxHeight: "auto",
                                position: "relative",
                              }}
                            >
                              <CardMedia
                                component="img"
                                image={pair.image}
                                alt="Preview"
                              />
                              <IconButton
                                aria-label="delete"
                                onClick={() => {
                                  const newSymptoms = [...symptoms];
                                  newSymptoms[index].image = "";
                                  setSymptoms(newSymptoms);
                                }}
                                sx={{ color: "rgb(225, 140, 168)" }}
                              >
                                <DeleteIcon />
                              </IconButton>
                              <IconButton
                                aria-label="edit"
                                onClick={() =>
                                  document
                                    .getElementById(`image-upload-${index}`)
                                    .click()
                                }
                                sx={{ ml: 1, color: "rgb(225, 140, 168)" }}
                              >
                                <EditIcon />
                              </IconButton>
                            </Card>

                            <input
                              id={`image-upload-${index}`}
                              type="file"
                              accept="image/*"
                              hidden
                              onChange={(event) =>
                                handleFileSelectSymptoms(event, index)
                              }
                            />
                          </Box>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {symptoms.length > 1 && ( // Show delete icon for all but the last item
                          <IconButton
                            onClick={() =>
                              handleRemoveStepSymptoms(
                                index,
                                symptoms,
                                setSymptoms,
                                "symptoms"
                              )
                            }
                            sx={addEditStyle.addRemoveButton}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                        {index === symptoms.length - 1 && ( // Show add icon only for the last item
                          <IconButton
                            onClick={() =>
                              handleAddValueSymtoms(symptoms, setSymptoms)
                            }
                            sx={addEditStyle.addRemoveButton}
                          >
                            <AddCircleOutlineRoundedIcon />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </Box>
            </Box>

            <Divider sx={{ mb: "15px" }} />
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>Tips:</Typography>
              </Box>
              <Box className="add-edit-input-field">
                {tips.map((pair, index) => (
                  <Box key={index} mb={2}>
                    <Grid
                      container
                      sx={{
                        width: "99%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      spacing={2}
                    >
                      <Grid item xs={6} md={10} width="101%">
                        <InputField
                          label="Title"
                          placeholder="Enter Title"
                          value={pair.title}
                          onChange={(e) => {
                            const newTips = [...tips];
                            newTips[index].title = e.target.value;
                            setTips(newTips);
                          }}
                          error={Boolean(formik.errors.tips?.[index]?.title)}
                          helperText={formik.errors.tips?.[index]?.title}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {tips.length > 1 && ( // Show delete icon for all but the last item
                          <IconButton
                            onClick={() =>
                              handleRemoveStep(index, tips, setTips, "tips")
                            }
                            sx={addEditStyle.addRemoveButton}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                        {index === tips.length - 1 && ( // Show add icon only for the last item
                          <IconButton
                            onClick={() =>
                              handleAddValueTapsAcco(tips, setTips)
                            } // Modified: use handleAddValue for titles and descriptions
                            sx={addEditStyle.addRemoveButton}
                          >
                            <AddCircleOutlineRoundedIcon />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </Box>
            </Box>
            <Divider sx={{ mb: "15px" }} />
            <Box className="add-edit-input-container" sx={{ width: "100%" }}>
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>FAQS :</Typography>
              </Box>
              <Box className="add-edit-input-field" sx={{ width: "100%" }}>
                {faqs.map((pair, index) => (
                  <Grid
                    container
                    key={index}
                    alignItems="center"
                    sx={{ mb: 1 }}
                    spacing={1}
                  >
                    <Grid item xs={5}>
                      <Typography sx={addEditStyle.subtitle}>
                        Question:
                      </Typography>
                      <InputField
                        placeholder="Enter Question"
                        value={pair.question}
                        onChange={(e) => {
                          const newFaqs = [...faqs];
                          newFaqs[index].question = e.target.value;
                          setFaqs(newFaqs);
                        }}
                        fullWidth
                        error={Boolean(formik.errors.faqs?.[index]?.question)}
                        helperText={formik.errors.faqs?.[index]?.question}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Typography sx={addEditStyle.subtitle}>
                        Answer:
                      </Typography>
                      <InputField
                        placeholder="Enter Answer"
                        value={pair.answer}
                        onChange={(e) => {
                          const newFaqs = [...faqs];
                          newFaqs[index].answer = e.target.value;
                          setFaqs(newFaqs);
                        }}
                        fullWidth
                        error={Boolean(formik.errors.faqs?.[index]?.answer)}
                        helperText={formik.errors.faqs?.[index]?.answer}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      mt={3}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {faqs.length > 1 && ( // Show delete icon for all but the last item
                        <IconButton
                          onClick={() =>
                            handleRemoveStep(index, faqs, setFaqs, "faqs")
                          }
                          sx={addEditStyle.addRemoveButton}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                      {index === faqs.length - 1 && ( // Show add icon only for the last item
                        <IconButton
                          onClick={() => handleAddValueTapsAcco(faqs, setFaqs)} // Modified: use handleAddValue for titles and descriptions
                          sx={addEditStyle.addRemoveButton}
                        >
                          <AddCircleOutlineRoundedIcon />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </Box>
              {formik.errors.faqs && formik.touched.faqs && (
                <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                  {formik.errors.faqs}
                </p>
              )}
            </Box>
          </Box>
          <Box
            className="add-edit-button-section"
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <BasicButtons
              text="Back"
              width={"25%"}
              onClick={() => navigate(-1)}
            />
            <BasicButtons
              text={"Add Week"}
              name="submit"
              type="submit"
              width={"25%"}
              disabled={loading}
              loading={loading}
            />
          </Box>
        </form>
      </Box>
    </DashboardLayout>
  );
};

export default AddWeekDetails;
