// src/components/ImageCropper.js
import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import ReactModal from 'react-modal';
import { toast } from 'react-hot-toast';
import BasicButtons from '../components/Button'
import { cropAndUploadImage } from './cropAndUploadImage'; // Adjust path as necessary
import { Box } from '@mui/material';

const ImageCropper = ({ image, aspectRatio, onCropComplete, onClose }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleCrop = useCallback(async () => {
    setLoading(true);
    try {
      const cloudinaryUrl = await cropAndUploadImage(image, croppedAreaPixels);
      onCropComplete(cloudinaryUrl);
    } catch (error) {
      toast.error('Error while cropping or uploading image');
    }
    setLoading(false);
  }, [croppedAreaPixels, image, onCropComplete]);

  const handleCancel = () => {
    onClose();
  };

  return (
    <ReactModal
    isOpen={Boolean(image)}
    onRequestClose={handleCancel}
    contentLabel="Crop Image"
    style={{
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent overlay
      },
      content: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%', // Adjust width as needed
        maxWidth: '600px', // Maximum width for the modal
        maxHeight: '80%', // Maximum height for the modal
        padding: '20px',
        boxSizing: 'border-box',
        border: 'none',
        borderRadius: '8px',
        backgroundColor: '#fff',
      },
    }}
  >
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <Cropper
        image={image}
        crop={crop}
        zoom={zoom}
        aspect={aspectRatio}
        onCropChange={setCrop}
        onZoomChange={setZoom}
        onCropComplete={(cropArea, cropPixels) => setCroppedAreaPixels(cropPixels)}
      />
       <Box
            sx={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              p: 2,
              gap: 5,
            }}
          >
            <BasicButtons text="Cancel" onClick={handleCancel} />
            <BasicButtons text="Crop" onClick={handleCrop} loading={loading} />
          </Box>
    </div>
  </ReactModal>
  );
};

export default ImageCropper;
