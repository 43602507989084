import React, { useState, useEffect } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Card,
  CardMedia,
} from "@mui/material";

import { useNavigate, useLocation, useParams } from "react-router-dom";
import { getDataHandlerWithToken } from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";
import typographyStyles from "../../../theme/Typography";


const addEditStyle = {
  addEditLabel: {
    fontWeight: "bold",
  },
  addEditInfo: {
    fontWeight: "normal",
    wordBreak: "break-word", // Ensure text wraps within the cell
    overflow: "hidden", // Hide overflow content
    textOverflow: "ellipsis", // Show ellipsis (...) for overflowing text
    
  },
};


const se = [
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum (0-6 Weeks After Birth)", value: "POSTPARTUM" },
  {
    key: "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
    value: "BEYOND",
  },
];

const ViewRecipe = () => {
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [recipe, setRecipe] = useState(null);
  const [cuisineOptions, setCuisineOptions] = useState([]);
  const [dietTypeOptions, setDietTypeOptions] = useState([]);
  const [dietaryPreferenceOptions, setDietaryPreferenceOptions] = useState([]);
  const [recipeTypeOptions, setRecipeTypeOptions] = useState([]);

  const [thumbnail, setThumbnail] = useState("");
  const [nutrition, setNutrition] = useState([]);

  useEffect(() => {
    const fetchCuisine = async (dataType, setData) => {
      try {
        const res = await getDataHandlerWithToken(dataType);
        if (res.responseCode === 200) {
          const transformedData = res.result.docs.map((item) => ({
            key: item.cuisineName,
            value: item._id,
          }));
          setData(transformedData || []);
        } else {
          toast.error(res.data.responseMessage);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchDietary = async (dataType, setData) => {
      try {
        const res = await getDataHandlerWithToken(dataType);
        if (res.responseCode === 200) {
          const transformedData = res.result.docs.map((item) => ({
            key: item.dietaryName,
            value: item._id,
          }));
          setData(transformedData || []);
        } else {
          toast.error(res.data.responseMessage);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchDiet = async (dataType, setData) => {
      try {
        const res = await getDataHandlerWithToken(dataType);
        if (res.responseCode === 200) {
          const transformedData = res.result.docs.map((item) => ({
            key: item.dietName,
            value: item._id,
          }));
          setData(transformedData || []);
        } else {
          toast.error(res.data.responseMessage);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchRecipe = async (dataType, setData) => {
      const recipeIdFromLocation = location.state?.recipeId;
      const query = { recipeId: recipeIdFromLocation };

      try {
        const res = await getDataHandlerWithToken(dataType, query);
        if (res.responseCode === 200) {
          setData(res.result || {});
          setThumbnail(res?.result.recipeThumbnailImage);
          setNutrition(res.result?.nutrition || []);
        } else {
          toast.error(res.data.responseMessage);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const fetchRecipeType = async (dataType, setData) => {
      try {
        const res = await getDataHandlerWithToken(dataType);
        console.log(res);
        if (res.responseCode === 200) {
          // Transform data to key-value pairs
          const transformedData = res?.result?.recipeTypes?.map((item) => ({
            key: item.recipeType, // Assuming 'name' is the property holding the name
            value: item._id, // Assuming 'id' is the property holding the id
          }));
          setData(transformedData || []);
        } else {
          toast.error(res.data.responseMessage);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };


    fetchDietary("listDietary", setDietaryPreferenceOptions);
    fetchCuisine("listCuisines", setCuisineOptions);
    fetchDiet("getDietList", setDietTypeOptions);
    fetchRecipe("viewRecipe", setRecipe);
    fetchRecipeType("listRecipeType", setRecipeTypeOptions)

  }, [location.state?.recipeId]);

  if (!recipe) {
    return (
      <DashboardLayout sectionName="View Recipe" title="Recipe Not Found">
        <Box sx={{ padding: "2rem" }}>
          <Typography variant="h5">Recipe not found</Typography>
        </Box>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout
      sectionName="Content-Recipe Management"
      title="Recipe Details"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>View Recipe</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <Box className="add-edit-content-section">
          <Box className="add-edit-content">
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Recipe Name:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {recipe?.recipeName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Recipe Thumbnail Image:
                    </TableCell>
                    <TableCell>
                     
                       <Card sx={{ maxWidth: 300, maxHeight: "auto" }}>
                        <CardMedia
                          component="img"
                          image={recipe?.recipeThumbnailImage}
                        />
                      </Card>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>Stage:</TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {recipe?.stage
                        ?.map((id) => {
                          const option = se.find((opt) => opt.value === id);
                          return option ? option.key : "";
                        })
                        .join(", ")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Recipe Type:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {recipe?.recipeType
                        ?.map((id) => {
                          const option = recipeTypeOptions.find(
                            (opt) => opt.value === id
                          );
                          return option ? option.key : "";
                        })
                        .join(", ")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Dietary Preference:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {recipe?.dietaryId
                        ?.map((id) => {
                          const option = dietaryPreferenceOptions.find(
                            (opt) => opt.value === id
                          );
                          return option ? option.key : "";
                        })
                        .join(", ")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Cuisine:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {recipe?.cuisineId
                        ?.map((id) => {
                          const option = cuisineOptions.find(
                            (opt) => opt.value === id
                          );
                          return option ? option.key : "";
                        })
                        .join(", ")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Diet Type:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {recipe?.dietId
                        ?.map((id) => {
                          const option = dietTypeOptions.find(
                            (opt) => opt.value === id
                          );
                          return option ? option.key : "";
                        })
                        .join(", ")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Preparation Time (in minutes):
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {recipe?.preparationTime}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      No. of servings:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {recipe?.servings}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Ingredients
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      
                      {recipe?.ingredientDetails ? (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: recipe?.ingredientDetails,
                                    }}
                                    style={{ padding: "10px" }}
                                  />
                                ) : (
                                  <Typography>
                                    No Ingredient details available.
                                  </Typography>
                                )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Nutrition:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      <table
                        style={{ width: "100%", borderCollapse: "collapse" }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                padding: "12px",
                                border: "1px solid #ddd",
                                backgroundColor: "rgb(225,140,168)",
                              }}
                            >
                              Nutrition
                            </th>
                            <th
                              style={{
                                padding: "12px",
                                border: "1px solid #ddd",
                                backgroundColor: "rgb(225,140,168)",
                                textAlign: "center",
                              }}
                            >
                              Value
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {recipe?.nutrition?.map((item, index) => (
                            <tr
                              key={index}
                              style={
                                index % 2 === 0
                                  ? { backgroundColor: "#f0f0f0" }
                                  : {}
                              }
                            >
                              <td
                                style={{
                                  padding: "12px",
                                  border: "1px solid #ddd",
                                }}
                              >
                                {item.key}
                              </td>
                              <td
                                style={{
                                  padding: "12px",
                                  border: "1px solid #ddd",
                                  textAlign: "center",
                                }}
                              >
                                {item.value}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </TableCell>
                  </TableRow>
                  
                  <TableRow>
                  <TableCell sx={addEditStyle.addEditLabel}>
                      Directions:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      <table
                        style={{ width: "100%", borderCollapse: "collapse" }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                padding: "12px",
                                border: "1px solid #ddd",
                                backgroundColor: "rgb(225,140,168)",
                              }}
                            >
                              Sr.No.
                            </th>
                            <th
                              style={{
                                padding: "12px",
                                border: "1px solid #ddd",
                                backgroundColor: "rgb(225,140,168)",
                                textAlign: "center",
                                width:"80%"
                              }}
                            >
                              Directions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {recipe?.steps.map((item, index) => (
                            <tr
                              key={index}
                              style={
                                index % 2 === 0
                                  ? { backgroundColor: "#f0f0f0" }
                                  : {}
                              }
                            >
                              <td
                                style={{
                                  padding: "12px",
                                  border: "1px solid #ddd",
                                }}
                              >
                                 <p
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "24px",
                              height: "24px",
                              borderRadius: "50%",
                              backgroundColor: "#6cb5f9",
                              color: "#fff",
                              fontSize: "14px",
                              marginRight: "10px",
                            }}
                          >
                            {index + 1}
                          </p>
                              </td>
                              <td
                                style={{
                                  padding: "12px",
                                  border: "1px solid #ddd",
                                  textAlign: "center",
                                }}
                              >
                                {item}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>Notes:</TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                    {recipe?.notes ? (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: recipe?.notes,
                                    }}
                                    style={{ padding: "10px" }}
                                  />
                                ) : (
                                  <Typography>
                                    No notes available.
                                  </Typography>
                                )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Status:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {recipe?.status}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default ViewRecipe;
