import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Typography, Divider } from "@mui/material";
import typographyStyles from "../../../theme/Typography";
import "../../../scss/main.css";
import { getDataHandler } from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";

const TermsAndConditions = () => {
  const navigate = useNavigate();
  const [termsAndConditions, setTermsAndConditions] = useState(null);

  useEffect(() => {
    // Fetch data when component mounts
    getDataHandler("getAllstaticContentByType", {
      contentType: "TERMSCONDITION",
    })
      .then((response) => {
        if (response?.responseCode === 200) {
          setTermsAndConditions(response.result[0]);
        } else {
          toast.error(
            response?.responseMessage || "Failed to fetch Terms and Conditions"
          );
        }
      })
      .catch((error) => {
        console.error("Error in fetching data", error);
        toast.error("Failed to fetch Terms and Conditions");
      });
  }, []);

  return (
    <DashboardLayout
      sectionName="Static Content Management"
      title="Terms & Conditions"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{
                cursor: "pointer",
              }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>
            Terms and Conditions
          </Typography>
        </Box>
      </Box>
      <Divider />

      <Box className="StaticScreenContainer" sx={{ padding: "20px" }}>
        <Box
          className="StaticScreenContent"
          sx={{
            border: "1px solid #ddd",
            borderRadius: "8px",
            padding: "16px",
            backgroundColor: "#f9f9f9",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            overflowX: "auto", // Add horizontal scroll if needed
            whiteSpace: "normal", // Ensure text wraps correctly
            wordWrap: "break-word", // Prevent overflow of long words
          }}
        >
          {termsAndConditions?.description ? (
            <Typography
              sx={{
                "& ul, & ol": {
                  paddingLeft: "20px", // Indent lists
                  marginBottom: "16px", // Add spacing below lists
                },
                "& li": {
                  lineHeight: "1.5", // Adjust line height for readability
                  marginBottom: "8px", // Add spacing between list items
                },
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(termsAndConditions.description),
                }}
              />
            </Typography>
          ) : (
            <Typography variant="body1">
              Loading Terms and Conditions...
            </Typography>
          )}
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default TermsAndConditions;
