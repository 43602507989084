import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DashboardLayout from '../../../layouts/DashboardLayout/DashboardLayout';
import { Box, Typography, Divider, Card, CardMedia } from '@mui/material';
import InputField from '../../../components/InputField';
import BasicButtons from '../../../components/Button';
import { useNavigate } from 'react-router-dom';
import { postDataHandlerWithToken } from '../../../apiconfig/service';
import { toast } from 'react-hot-toast';
import backBtn from '../../../assets/backBtn.svg';
import typographyStyles from "../../../theme/Typography";
import ImageCropper from '../../../utils/ImageCropper'; // Adjust path as necessary
const addEditStyle = {
  container: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    flexWrap: "wrap",
  },
  inputContainer: {
    flex: 1,
  },
  imageContainer: {
    maxWidth: "250px",
    maxHeight: "250px",
    margin: "5px",
  },
};

const AddBanner = () => {
  const navigate = useNavigate();
  const [thumbnailPreview, setThumbnailPreview] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const formik = useFormik({
    initialValues: {
      bannerTitle: '',
      categoryImage: null,
    },
    validationSchema: Yup.object({
      bannerTitle: Yup.string().required('Please enter the banner title.'),
      categoryImage: Yup.mixed().required('Please upload the image.'),
    }),
    onSubmit: async (values) => {
      try {
        const data = {
          bannerName: values.bannerTitle,
          image: croppedImage,
        };
    
        const response = await postDataHandlerWithToken('addUpdateBanner', data);
    
        if (response.responseCode === 200) {
          toast.success(response.responseMessage);
          navigate('/staticcontent/bannermanagement');
        } else {
          throw new Error('Failed to add banner');
        }
      } catch (error) {
       
        toast.error('Banner title should be unique.');
      }
    },
    
  });

  const handleThumbnailChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      formik.setFieldValue('categoryImage', file);
    }
  };

  const handleCropComplete = (cloudinaryUrl) => {
    setCroppedImage(cloudinaryUrl);
    setThumbnailPreview(cloudinaryUrl);
    setSelectedImage(null);
  };

  return (
    <DashboardLayout>
      <Box className="heading-container-top">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: 'pointer' }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>Add Banner</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <form className="add-edit-content-section" onSubmit={formik.handleSubmit}>
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>Banner title:</Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  className="authTextField"
                  type="text"
                  name="bannerTitle"
                  placeholder="Enter the banner title"
                  value={formik.values.bannerTitle}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  maxLength={50}
                />
                {formik.touched.bannerTitle && formik.errors.bannerTitle && (
                  <p style={{ color: 'red', marginTop: '2px', float: 'left' }}>
                    {formik.errors.bannerTitle}
                  </p>
                )}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>Select Image:</Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="file"
                  name="categoryImage"
                  onChange={handleThumbnailChange}
                  onBlur={formik.handleBlur}
                  acceptedFile="image/*"
                />
                {formik.touched.categoryImage && formik.errors.categoryImage && (
                  <p style={{ color: 'red', marginTop: '2px', float: 'left' }}>
                    {formik.errors.categoryImage}
                  </p>
                )}
                {thumbnailPreview && (
                  <Box sx={addEditStyle.imageContainer}>
                    <Card>
                      <CardMedia component="img" image={thumbnailPreview} />
                    </Card>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box className="StaticBtnContainer" sx={{ marginBottom: 2 }}>
            <BasicButtons text="Add" name="submit" type="submit" width={'20%'} />
          </Box>
        </form>
      </Box>
      <ImageCropper
        image={selectedImage}
        aspectRatio={16/9}
        onCropComplete={handleCropComplete}
        onClose={() => setSelectedImage(null)}
      />
    </DashboardLayout>
  );
};

export default AddBanner;
