import React, { useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Divider,
} from "@mui/material";
import typographyStyles from "../../../theme/Typography";
import BasicButtons from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import InputField from "../../../components/InputField";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { addWorkoutSchema } from "../../../schema";
import "../../../scss/main.css";
import toast from "react-hot-toast";
import { postDataHandlerWithToken } from "../../../apiconfig/service";
import backBtn from "../../../assets/backBtn.svg";
import MultiSelectDropdown from "../../../components/MultiSelectDropdown";

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
  radioContainer: {
    display: "flex",
  },
};

const dropdownOptions = [
  "Trying to Conceive",
  "Pregnant",
  "Postpartum (0-6 Weeks After Birth)",
  "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
];
const se = [
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum (0-6 Weeks After Birth)", value: "POSTPARTUM" },
  {
    key: "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
    value: "BEYOND",
  },
];

const AddWorkout = () => {
  const [categoryType, setCategoryType] = useState("FREE");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleCategoryTypeChange = (event) => {
    setCategoryType(event.target.value);
  };

  const initialValues = {
    selectStage: [],
    workoutCategoryName: "",
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: addWorkoutSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const res = await postDataHandlerWithToken("addUpdateWorkoutCategory", {
          stage:values.selectStage,
          workoutCategoryName: values.workoutCategoryName,
          saveAs: categoryType,
        });
        if (res.responseCode === 200) {
          toast.success(res.responseMessage);
          navigate("/workoutlisting");
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        console.error("Error while adding:", error);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <DashboardLayout
      sectionName="Workout Management - Category"
      title="Add Workout Category"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{
                // // width: "2.813",
                // height: "5.813",
                cursor: "pointer",
              }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>Add Workout Category</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <form className="add-edit-content-section" onSubmit={handleSubmit}>
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Select Stage:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
              <MultiSelectDropdown
                  name="selectStage"
                  onBlur={handleBlur}
                  sx={addEditStyle.categoryInputField}
                  options={se}
                  value={values.selectStage}
                  placeholder="Select stage"
                  onChange={(value) =>
                    handleChange({ target: { name: "selectStage", value } })
                  }
                />

                {errors.selectStage && touched.selectStage ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.selectStage}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Workout Category Name:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="workoutCategoryName"
                  value={values.workoutCategoryName}
                  maxLength={51}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter program name"
                />
                {errors.workoutCategoryName && touched.workoutCategoryName ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.workoutCategoryName}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>Save as:</Typography>
              </Box>
              <Box className="add-edit-input-field">
                <FormControl component="fieldset">
                  <RadioGroup
                    name="categoryType"
                    value={categoryType}
                    onChange={handleCategoryTypeChange}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <FormControlLabel
                      className="radio"
                      value="FREE"
                      control={<Radio />}
                      label="Free"
                    />
                    <FormControlLabel
                      className="radio"
                      value="LOCKED"
                      control={<Radio />}
                      label="Locked (will be accessed after subscription)"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </Box>
          <Box
            className="add-edit-button-section"
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <BasicButtons
              text="Add Workout Category"
              name="submit"
              type="submit"
              width={"30%"}
              loading={loading} 
            />
            {/* <BasicButtons
              text="Back"
              width={"25%"}
              onClick={() => {
                navigate("/workoutlisting");
              }}
            /> */}
          </Box>
        </form>
      </Box>
    </DashboardLayout>
  );
};

export default AddWorkout;
