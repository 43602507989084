import React, { useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  Typography,
  Button,
  Divider,
  Grid,
  Card,
  CardMedia,
  IconButton,
} from "@mui/material";
import * as Yup from "yup";
import typographyStyles from "../../../theme/Typography";
import BasicButtons from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import InputField from "../../../components/InputField";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import "../../../scss/main.css";
import { postDataHandlerWithToken } from "../../../apiconfig/service";
import backBtn from "../../../assets/backBtn.svg";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { CircularProgress } from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import EditIcon from "@mui/icons-material/Edit";
import { commonGridStyle } from "../../../scss/style";
import { workoutSubcategory } from "../../../schema";
import { TroubleshootSharp } from "@mui/icons-material";
import ImageCropper from "../../../utils/ImageCropper";

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
  addRemoveButton: {
    textTransform: "none",
    backgroundColor: "rgb(225,140,168)",
    color: "white",
    fontWeight: "400",
    fontSize: "15px",
    fontFamily: "'Poppins', sans-serif",
    boxShadow: "0 0 0.938rem 0 rgba(0, 0, 0, 0.2)",
    marginTop: "2px",
    marginLeft: "2px",
    "&:hover": {
      backgroundColor: "white",
      color: "rgb(225,140,168)",
    },
  },
};

const dropdownOptions = [
  "Trying to Conceive",
  "Pregnant",
  "Postpartum (0-6 Weeks After Birth)",
  "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
];

const se = [
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum (0-6 Weeks After Birth)", value: "POSTPARTUM" },
  {
    key: "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
    value: "BEYOND",
  },
];

const AddSubcategory = () => {
  const [selectedValue, setSelectedValue] = useState("Select Stage");
  const [categoryId, setCategoryId] = useState();
  const [loading, setLoading] = useState(false);
  const [uploadedFileURLs, setUploadedFileURLs] = useState([]);
 
  const [imagePreviews, setImagePreviews] = useState([]);
  
  const [videos, setVideos] = useState([{ video: "", image: "" }]);
  const [loadingImage, setLoadingImage] = useState(false);




  const [editorContent, setEditorContent] = useState("");
  const [exerciseEditorContent, setExerciseEditorContent] = useState("");
  const [exercises, setExercise] = useState([
    { title: "", video: "", image: "" },
  ]);

 
  const [selectedImage, setSelectedImage] = useState(null);

  const [videosThumbnailLoader, setVideosThumbnailLoader] = useState(false);
  const [videosLoader, setVideosLoader] = useState(false);
  const [exerciseLoader, setExerciseLoader] = useState(false);
  const [exerciseThumbnailLoader, setExerciseThumbnailLoader] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleDropdownChange = (value) => {
    setSelectedValue(value);
    setCategoryId("");
  };
  const [charCount1, setCharCount1] = useState(0);
  const [charCount2, setCharCount2] = useState(0);

  const validationSchema = workoutSubcategory;
  const handleCropComplete = (croppedUrl) => {
    // if (selectedField === "categoryImage") {
    //   setCategoryImageUrl(croppedUrl);
    //   setThumbnailPreview(croppedUrl);
    // } else if (selectedField === "bgImage") {
    //   setBgImageUrl(croppedUrl);
    //   setBgPreview(croppedUrl);
    // } else if (selectedField === "bgImage") {
    //   setBgImageUrl(croppedUrl);
    //   setBgPreview(croppedUrl);
    // }
    // setSelectedImage(null);
  };

  const handleFileSelect = async (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      if (file.type.startsWith("image/")) {
        try {
          setLoadingImage(true);
          // Read the file as a data URL for immediate preview
          const reader = new FileReader();

          // Prepare form data for uploading
          const formData = new FormData();
          formData.append("file", file);

          // Upload the file
          const res = await postDataHandlerWithToken("uploadFile", formData);

          if (res?.responseCode === 200) {
            reader.onload = () => {
              setImagePreviews((prevPreviews) => [
                ...prevPreviews,
                reader.result,
              ]);
            };
            reader.readAsDataURL(file);
            setUploadedFileURLs((prevURLs) => [...prevURLs, res.result]);
            formik.setFieldValue("image", [...formik.values.image, res.result]);
          } else {
            toast.error(res?.responseMessage || "File upload failed");
          }
        } catch (error) {
          console.error("Error uploading file:", error);
          toast.error("Error uploading file");
        } finally {
          setLoadingImage(false);
          console.log(formik.values.image);
        }
      } else {
        alert("Please select an image file.");
      }
    }
  };
  const handleVideoSelect = async (event, index, type, field, list) => {
    const file = event.currentTarget.files[0];
    if (file) {
      try {
       

        if (field === "videos") {
          type === "image"
            ? setVideosThumbnailLoader(true)
            : setVideosLoader(true);
        } else {
          type === "image"
            ? setExerciseThumbnailLoader(true)
            : setExerciseLoader(true);
        }

        const reader = new FileReader();
        const formData = new FormData();
        formData.append("file", file);

        const endpoint = file.type.startsWith("image/")
          ? "uploadFile"
          : "uploadVideo";
        const res = await postDataHandlerWithToken(endpoint, formData);

        if (res?.responseCode === 200) {
          const newNutrition =
            field === "videos" ? [...videos] : [...exercises];
          newNutrition[index][type] = res.result;
          field === "videos"
            ? setVideos(newNutrition)
            : setExercise(newNutrition);
          formik.setFieldValue(field, [...newNutrition]);
        } else {
          toast.error(res?.responseMessage || "File upload failed");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        toast.error("Error uploading file");
      } finally {
        if (field === "videos") {
          type === "image"
            ? setVideosThumbnailLoader(false)
            : setVideosLoader(false);
        } else {
          type === "image"
            ? setExerciseThumbnailLoader(false)
            : setExerciseLoader(false);
        }
      }
    }
  };

  const handleRemoveStep = (index, dataType, setDataType, field) => {
    const newData = [...dataType];
    newData.splice(index, 1);
    setDataType(newData);
    if (field.length > 0) {
      formik.setFieldValue(field, [...newData]);
    }

    //console.log(uploadedFileURLs);
  };
  const handleAddValue = (dataType, setDataType) => {
    setDataType([...dataType, { video: "", image: "" }]);
  };
  const handleAddExercise = (dataType, setDataType) => {
    setDataType([...dataType, { title: "", video: "", image: "" }]);
  };
  const handleFormSubmit = async (values) => {
    for (let i = 0; i < videos.length; i++) {
      if (!videos[i].video) {
        toast.error(`Videos item "video" cannot be empty.`);
        return;
      }
      if (!videos[i].image) {
        toast.error(`Videos item "thumbnail" cannot be empty.`);
        return;
      }
    }
    for (let i = 0; i < exercises.length; i++) {
      if (!exercises[i].title) {
        toast.error(`Exercises item "title" cannot be empty.`);
        return;
      }
      if (!exercises[i].video) {
        toast.error(`Exercises item "video" cannot be empty.`);
        return;
      }
      if (!exercises[i].image) {
        toast.error(`Exercises item "thumbnail" cannot be empty.`);
        return;
      }
    }
    console.log("hhhh");
    const data = {
      stage: se.find((option) => option.key == values.selectStage).value,
      workoutCategoryId: location?.state?.categoryId,
      workoutSubCategoryName: values.workoutSubCategoryName,
      image: uploadedFileURLs,
      videos: videos,

      exercisesInstructions: values.exercisesInstructions,
      exercises: exercises,
      time: values.time,
      description: values.description,
    };
    console.log(data);

    try {
      setLoading(true);
      const res = await postDataHandlerWithToken(
        "addUpdateWorkoutSubCategory",
        data
      );
      console.log(res?.data);
      if (res?.responseCode === 200) {
        toast.success(res?.responseMessage);
        navigate(-1);
        console.log("added");
      } else {
        toast.error(res?.data?.responseMessage);
        console.log("error");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form");
    } finally {
      setLoading(false);
    }
  };
  const handleChange = (index, keyOrValue, value) => {
    const newNutrition = [...exercises];
    newNutrition[index][keyOrValue] = value;
    setExercise(newNutrition);
    formik.setFieldValue("exercises", [...newNutrition]);
    console.log(formik.values.exercises);
  };

  const formik = useFormik({
    initialValues: {
      selectStage: "",
      workoutSubCategoryName: "",
      image: [],
      videos: [],
      exercises: [],

      exercisesInstructions: "",
      time: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  return (
    <DashboardLayout
      sectionName="Content - Program Management"
      title="Add Program"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>
            Add Workout Subcategory
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <form
          className="add-edit-content-section"
          onSubmit={formik.handleSubmit}
        >
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Select Stage:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <Dropdown
                  name="selectStage"
                  onBlur={formik.handleBlur}
                  sx={addEditStyle.categoryInputField}
                  options={dropdownOptions}
                  value={formik.values.selectStage}
                  placeholder="Select stage"
                  onChange={(value) => {
                    formik.setFieldValue("selectStage", value);
                    handleDropdownChange(value);
                  }}
                />
                {formik.errors.selectStage && formik.touched.selectStage ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.selectStage}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Divider sx={{ mb: "15px" }} />

            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Subcategory Name:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="workoutSubCategoryName"
                  value={formik.values.workoutSubCategoryName}
                  maxLength={30}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter Subcategory name"
                />
                {formik.errors.workoutSubCategoryName &&
                formik.touched.workoutSubCategoryName ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.workoutSubCategoryName}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Divider sx={{ mb: "15px" }} />

            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Description:
                </Typography>
              </Box>
              <Box className="add-edit-input-field ckeditor-container">
                <CKEditor
                  editor={ClassicEditor}
                  data={editorContent}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setEditorContent(data);
                    formik.setFieldValue("description", data);
                    const plainText = data.replace(/<[^>]*>/g, "");
                    setCharCount1(plainText.length);
                  }}
                  config={{
                    toolbar: [
                      "heading",
                      "|",
                      "bold",
                      "italic",
                      "link",
                      "bulletedList",
                      "numberedList",
                      "blockQuote",
                    ],
                    // height: "200px",
                  }}
                  className="StaticEditor"
                />

                {formik.errors.description && formik.touched.description ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.description}
                  </p>
                ) : null}
                <Typography
                  variant="subtitle2"
                  sx={{
                    textAlign: "right",
                    mt: 1,
                    color: charCount1 > 1200 ? "red" : "inherit",
                  }}
                >
                  {charCount1}/{1200} characters
                </Typography>
              </Box>
            </Box>
            <Divider sx={{ mb: "15px" }} />

            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Thumbnail:
                </Typography>
              </Box>
              <Box className="add-edit-input-field" sx={commonGridStyle}>
                <Button
                  sx={{ backgroundColor: "rgb(225, 140, 168)" }}
                  variant="contained"
                  component="label"
                  startIcon={<AddIcon />}
                  disabled={uploadedFileURLs.length === 1}
                >
                  Image{" "}
                  {loadingImage && (
                    <CircularProgress size={20} sx={{ color: "white" }} />
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={handleFileSelect}
                  />
                </Button>

                <Grid container spacing={2} sx={{ mt: "10px" }}>
                  {uploadedFileURLs.map((preview, index) => (
                    <Grid item xs={3} key={index}>
                      <Card sx={{ maxWidth: 300, maxHeight: "auto" }}>
                        <CardMedia
                          component="img"
                          image={preview}
                          alt={`Preview ${index}`}
                        />
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            handleRemoveStep(
                              index,
                              uploadedFileURLs,
                              setUploadedFileURLs,
                              "image"
                            );
                          }}
                        >
                          <DeleteIcon sx={{ color: "rgb(225, 140, 168)" }} />
                        </IconButton>
                      </Card>
                    </Grid>
                  ))}
                </Grid>

                {formik.errors.image && formik.touched.image ? (
                  <p style={{ color: "red", float: "left" }}>
                    {formik.errors.image}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Divider sx={{ mb: "15px" }} />
            <Box className="add-edit-input-container" sx={{ marginTop: 2 }}>
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>Videos:</Typography>
              </Box>
              <Box className="add-edit-input-field">
                {videos.map((item, index) => (
                  <Grid container alignItems="center" sx={commonGridStyle}>
                    <Grid item xs={5}>
                      {videos[index].video.length !== 0 ? (
                        <Card sx={{ maxWidth: 300, maxHeight: "auto" }}>
                          <CardMedia
                            component="video"
                            image={videos[index].video}
                            alt={`Preview ${index}`}
                            controls
                          />

                          <Button
                            sx={{
                              backgroundColor: "rgb(225, 140, 168)",
                              margin: "5px",
                            }}
                            variant="contained"
                            component="label"
                            startIcon={<EditIcon />}
                          >
                            Video{" "}
                            {videosLoader && (
                              <CircularProgress
                                size={20}
                                sx={{ color: "white" }}
                              />
                            )}
                            <input
                              type="file"
                              accept="video/*"
                              hidden
                              onChange={(e) =>
                                handleVideoSelect(e, index, "video", "videos")
                              }
                            />
                          </Button>
                        </Card>
                      ) : (
                        <Button
                          sx={{ backgroundColor: "rgb(225, 140, 168)" }}
                          variant="contained"
                          component="label"
                          startIcon={<AddIcon />}
                        >
                          Video{" "}
                          {videosLoader && (
                            <CircularProgress
                              size={20}
                              sx={{ color: "white" }}
                            />
                          )}
                          <input
                            type="file"
                            accept="video/*"
                            hidden
                            onChange={(e) =>
                              handleVideoSelect(e, index, "video", "videos")
                            }
                          />
                        </Button>
                      )}
                    </Grid>

                    <Grid item xs={5} ml={2}>
                      {videos[index].image.length !== 0 ? (
                        <Card sx={{ width: 250, height: "auto" }}>
                          <CardMedia
                            component="img"
                            image={videos[index].image}
                            alt={`Preview ${index}`}
                          />
                          <IconButton
                            aria-label="edit"
                            onClick={() =>
                              document
                                .getElementById(`image-upload-${index}`)
                                .click()
                            }
                            sx={{ color: "rgb(225, 140, 168)" }}
                          >
                            <EditIcon />
                            {videosThumbnailLoader && (
                              <CircularProgress
                                size={20}
                                sx={{ color: "rgb(225, 140, 168)" }}
                              />
                            )}
                          </IconButton>
                          <input
                            id={`image-upload-${index}`}
                            type="file"
                            accept="image/*"
                            hidden
                            onChange={(e) =>
                              handleVideoSelect(e, index, "image", "videos")
                            }
                          />
                        </Card>
                      ) : (
                        <Button
                          sx={{
                            backgroundColor: "rgb(225, 140, 168)",
                            
                          }}
                          variant="contained"
                          component="label"
                          startIcon={<AddIcon />}
                          aria-label="Upload Thumbnail"
                        >
                          Thumbnail
                          {videosThumbnailLoader && (
                            <CircularProgress
                              size={20}
                              sx={{ color: "white" }}
                            />
                          )}
                          <input
                            id={`image-upload-${index}`}
                            type="file"
                            accept="image/*"
                            hidden
                            onChange={(e) =>
                              handleVideoSelect(e, index, "image", "videos")
                            }
                          />
                        </Button>
                      )}
                    </Grid>

                    <Grid item xs={2}>
                      {index === videos.length - 1 ? (
                        <Grid
                          box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            gap: "10px",
                            mt: "5px",
                          }}
                        >
                          <IconButton
                            sx={{ ...addEditStyle.addRemoveButton }}
                            onClick={() => handleAddValue(videos, setVideos)}
                          >
                            <AddCircleOutlineRoundedIcon />
                          </IconButton>

                          {index !== 0 && (
                            <IconButton
                              sx={{ ...addEditStyle.addRemoveButton }}
                              onClick={() =>
                                handleRemoveStep(
                                  index,
                                  videos,
                                  setVideos,
                                  "videos"
                                )
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </Grid>
                      ) : (
                        <IconButton
                          onClick={() =>
                            handleRemoveStep(index, videos, setVideos, "videos")
                          }
                          sx={{ ...addEditStyle.addRemoveButton }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                ))}

                {formik.errors.videos && formik.touched.videos ? (
                  <p style={{ color: "red", float: "left" }}>
                    {formik.errors.videos}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Divider sx={{ mb: "15px" }} />

            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Time Required(in minutes):
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="number"
                  name="time"
                  value={formik.values.time}
                  maxLength={3}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter time required"
                />
                {formik.errors.time && formik.touched.time ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.time}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Divider sx={{ mb: "15px" }} />
            <Box className="add-edit-input-container" sx={{ width: "100%" }}>
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Exercises:
                </Typography>
              </Box>
              <Box className="add-edit-input-field" sx={{ width: "100%" }}>
                {exercises.map((item, index) => (
                  <Box key={index} mb={2}>
                    <Grid ontainer alignItems="center" sx={commonGridStyle}>
                      <Grid item xs={5}>
                        <InputField
                          placeholder={`Exercise ${index + 1}`}
                          value={item.title}
                          maxLength={30}
                          onChange={(e) =>
                            handleChange(index, "title", e.target.value)
                          }
                          fullWidth
                        />
                      </Grid>

                      <Box
                        spacing={2}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          gap: "5px",
                        }}
                        mt={2}
                      >
                        <Grid item xs={5}>
                          {exercises[index]?.video.length !== 0 ? (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Card
                                sx={{
                                  maxWidth: 250,
                                  maxHeight: "auto",
                                }}
                              >
                                <CardMedia
                                  component="video"
                                  image={exercises[index].video}
                                  alt={`Preview ${index}`}
                                  controls
                                />
                                <Button
                                  sx={{
                                    backgroundColor: "rgb(225, 140, 168)",
                                    margin: "5px",
                                  }}
                                  variant="contained"
                                  component="label"
                                  startIcon={<EditIcon />}
                                >
                                  Video{" "}
                                  {exerciseLoader && (
                                    <CircularProgress
                                      size={20}
                                      sx={{ color: "white" }}
                                    />
                                  )}
                                  <input
                                    type="file"
                                    accept="video/*"
                                    hidden
                                    onChange={(e) =>
                                      handleVideoSelect(
                                        e,
                                        index,
                                        "video",
                                        "exercises"
                                      )
                                    }
                                  />
                                </Button>
                              </Card>
                            </Box>
                          ) : (
                            <Button
                              sx={{ backgroundColor: "rgb(225, 140, 168)" }}
                              variant="contained"
                              component="label"
                              startIcon={<AddIcon />}
                            >
                              Video{" "}
                              {exerciseLoader && (
                                <CircularProgress
                                  size={20}
                                  sx={{ color: "white" }}
                                />
                              )}
                              <input
                                type="file"
                                accept="video/*"
                                hidden
                                onChange={(e) =>
                                  handleVideoSelect(
                                    e,
                                    index,
                                    "video",
                                    "exercises"
                                  )
                                }
                              />
                            </Button>
                          )}
                        </Grid>

                        <Grid item>
                          {exercises[index].image.length !== 0 ? (
                            <Card
                              sx={{
                                maxWidth: 250,
                                maxHeight: "auto",
                                marginTop: "10px",
                              }}
                            >
                              <CardMedia
                                component="img"
                                image={exercises[index].image}
                                alt={`Preview ${index}`}
                              />
                              <IconButton
                                aria-label="edit"
                                onClick={() =>
                                  document
                                    .getElementById(`image-upload2-${index}`)
                                    .click()
                                }
                                sx={{ color: "rgb(225, 140, 168)" }}
                              >
                                <EditIcon />
                              </IconButton>
                              {exerciseThumbnailLoader && (
                                <CircularProgress
                                  size={20}
                                  sx={{ color: "rgb(225, 140, 168)"}}
                                />
                              )}

                              <input
                                id={`image-upload2-${index}`}
                                type="file"
                                accept="image/*"
                                hidden
                                onChange={(e) =>
                                  handleVideoSelect(
                                    e,
                                    index,
                                    "image",
                                    "exercises"
                                  )
                                }
                              />
                            </Card>
                          ) : (
                            <Button
                              sx={{ backgroundColor: "rgb(225, 140, 168)" }}
                              variant="contained"
                              component="label"
                              startIcon={<AddIcon />}
                            >
                              Thumbnail
                              {exerciseThumbnailLoader && (
                                <CircularProgress
                                  size={20}
                                  sx={{ color: "white" }}
                                />
                              )}
                              <input
                                type="file"
                                accept="image/*"
                                hidden
                                onChange={(e) =>
                                  handleVideoSelect(
                                    e,
                                    index,
                                    "image",
                                    "exercises"
                                  )
                                }
                              />
                            </Button>
                          )}
                        </Grid>
                      </Box>

                      <Grid item xs={12}>
                        {index === exercises.length - 1 ? (
                          <Grid
                            box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              gap: "10px",
                              mt: "5px",
                            }}
                          >
                            <IconButton
                              onClick={() =>
                                handleAddExercise(exercises, setExercise)
                              }
                              sx={{ ...addEditStyle.addRemoveButton }}
                            >
                              <AddCircleOutlineRoundedIcon />
                            </IconButton>

                            {index !== 0 && (
                              <IconButton
                                onClick={() =>
                                  handleRemoveStep(
                                    index,
                                    exercises,
                                    setExercise,
                                    "exercises"
                                  )
                                }
                                sx={{ ...addEditStyle.addRemoveButton }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </Grid>
                        ) : (
                          <IconButton
                            onClick={() =>
                              handleRemoveStep(
                                index,
                                exercises,
                                setExercise,
                                "exercises"
                              )
                            }
                            sx={{
                              ...addEditStyle.addRemoveButton,
                              marginTop: "5px",
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                ))}

                {formik.errors.exercises && formik.touched.exercises ? (
                  <p style={{ color: "red", float: "left" }}>
                    {formik.errors.exercises}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Divider sx={{ mb: "15px" }} />
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Exercises Instructions:
                </Typography>
              </Box>
              <Box className="add-edit-input-field ckeditor-container">
                <CKEditor
                  editor={ClassicEditor}
                  data={exerciseEditorContent}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setExerciseEditorContent(data);
                    formik.setFieldValue("exercisesInstructions", data);
                    const plainText = data.replace(/<[^>]*>/g, "");
                    setCharCount2(plainText.length);
                  }}
                  config={{
                    toolbar: [
                      "heading",
                      "|",
                      "bold",
                      "italic",
                      "link",
                      "bulletedList",
                      "numberedList",
                      "blockQuote",
                    ],
                    // height: "200px",
                  }}
                  className="StaticEditor"
                />
                {formik.errors.exercisesInstructions &&
                formik.touched.exercisesInstructions ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.exercisesInstructions}
                  </p>
                ) : null}

                <Typography
                  variant="subtitle2"
                  sx={{
                    textAlign: "right",
                    mt: 1,
                    color: charCount2 > 1200 ? "red" : "inherit",
                  }}
                >
                  {charCount2}/{1200} characters
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            className="add-edit-button-section"
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <BasicButtons
              text="Back"
              width={"25%"}
              onClick={() => navigate(-1)}
            />
            <BasicButtons
              text={"Add Subcategory"}
              name="submit"
              type="submit"
              width={"25%"}
              disabled={loading}
              loading={loading}
            />
          </Box>
        </form>
      </Box>
      {selectedImage && (
        <ImageCropper
          image={selectedImage}
          aspectRatio={1}
          onCropComplete={handleCropComplete}
          onClose={() => setSelectedImage(null)}
        />
      )}
    </DashboardLayout>
  );
};

export default AddSubcategory;
