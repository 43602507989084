import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Button, Typography, Divider } from "@mui/material";
import PaginationTable from "../../../components/PaginationTable";
import { useNavigate } from "react-router-dom";
import BasicButtons from "../../../components/Button";
import { deleteDataHandler, getDataHandler } from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import AlertDialog from "../../../components/AlertDialog"; // Adjust the path as needed
import backBtn from "../../../assets/backBtn.svg";
import typographyStyles from "../../../theme/Typography";
import DataNotFound from "../../../components/DataNotFound";
import Loading from "../../../components/Loading";
const columns = [
  { id: "sr_no", label: "Sr.No." },
  { id: "question", label: "Question" },
  { id: "answer", label: "Answer" },
  { id: "created_date_time", label: "Created Date & Time" },
  { id: "Status", label: "Status" },
  { id: "action", label: "Action" },
];

const FAQs = () => {
  const navigate = useNavigate();
  const [listfaq, setListfaq] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading]=useState(false)

  const [open, setOpen] = useState(false);
  const handleClickOpen = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  useEffect(() => {
    // Fetch data when component mounts
    setLoading(true)
    getDataHandler("getAllstaticContentByType", { contentType: "FAQ" })
      .then((response) => {
        if (response?.responseCode === 200) {
          setListfaq(response.result); // Assuming response.result contains the list of FAQs
        } else {
          toast.error(response?.responseMessage || "Failed to fetch FAQs");
        }
      })
      .catch((error) => {
        console.error("Error in fetching data", error);
        toast.error("Failed to fetch FAQs");
      });
      setLoading(false)
  }, [handleClose]); // Empty dependency array ensures this effect runs only once

  const handleEdit = (id) => {
    // Navigate to edit page
    navigate(`/staticcontent/faqs/editfaq`, { state: { faqId: id } });
  };

  const handleDelete = async (id) => {
    try {
      const res = await deleteDataHandler("deleteStaticContent", {
        staticContentId: id,
      });
      console.log(res);
      if (res.responseCode == 200) {
        toast.success("FAQ deleted successfully");
      }
    } catch (error) {
      console.log("Error while deleting FAQ", error);
    }
    handleClose();
  };

  return (
    <DashboardLayout sectionName="Static Content Management" title="FAQs">
      <Box className="heading-container-top">
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Box>
              <img
                src={backBtn}
                alt="backBtn"
                style={{
                  // // width: "2.813",
                  // height: "5.813",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/staticcontent")}
              />
            </Box>
            <Typography sx={{ ...typographyStyles.h4 }}>FAQs</Typography>
          </Box>
        </Box>

        <Box className="button-container">
          <BasicButtons
            text="+Add FAQ"
            onClick={() => {
              navigate("/staticcontent/faqs/addFAQ");
            }}
          />
        </Box>
      </Box>
      <br></br>
      <Divider />
      <Box className="StaticScreenContainer">
        <Box>
        {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh", // Adjust height as needed
                width: "100%",
              }}
            >
              <Loading/> 
            </Box>
          ) : listfaq?.length < 1 ? (
            <DataNotFound/>
          ) : (
          <PaginationTable
            columns={columns}
            data={listfaq?.map((item, index) => ({
              sr_no: index + 1,
              question: item.question,
              answer: item.answer,
              created_date_time: item.createdAt,
              Status: item.status,
              action: (
                <Box className="row-button-container" key={item._id}>
                  <Button
                    variant="outlined"
                    onClick={() => handleEdit(item._id)}
                    sx={{
                      color: "rgb(225,140,168)",
                      borderColor: "rgb(225,140,168)",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => handleClickOpen(item)}
                    sx={{
                      color: "rgb(225,140,168)",
                      borderColor: "rgb(225,140,168)",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    Delete
                  </Button>
                </Box>
              ),
            }))}
          />
          )}
        </Box>
      </Box>
      <AlertDialog
        open={open}
        handleClose={handleClose}
        handleDelete={() => handleDelete(selectedRow?._id)}
        title="Delete Item?"
        description={`Are you sure you want to delete the FAQ titled "${selectedRow?.question}"? This action cannot be undone.`}
      />
    </DashboardLayout>
  );
};

export default FAQs;
