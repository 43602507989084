import { Box } from "@mui/material";
import React from "react";

import Logo from "../assets/dashboardlogo.png";

const headerStyle = {
    headerbox:{
      backgroundColor: 'rgb(255,255,255)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      paddingLeft: '2%',
      position: "sticky",
      // position: 'fixed',
      // left: '0',
      // right: '0',
      // top: '0',
      // zIndex: "9999",
      boxShadow: '0 0 10px 0 #D9D9D9',
    }
}

const Header = () => {
  return (
    <Box
        style = {headerStyle.headerbox}
    >
        <img src={Logo} alt="Logo" />
    </Box>
  );
};

export default Header;