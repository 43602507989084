import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Typography, Card, CardMedia } from "@mui/material";
import InputField from "../../../components/InputField";
import { useNavigate } from "react-router-dom";
import {
  postDataHandlerWithToken,
  postDataHandler,
} from "../../../apiconfig/service";
import backBtn from "../../../assets/backBtn.svg";
import typographyStyles from "../../../theme/Typography";
import { toast } from "react-hot-toast";
import "../../../scss/main.css";
import BasicButtons from "../../../components/Button";
import ImageCropper from "../../../utils/ImageCropper";

const addEditStyle = {
  addEditTitle: {
    fontSize: "24px",
    fontWeight: 600,
  },
  addEditLabel: {
    fontSize: "16px",
    fontWeight: 500,
    marginBottom: "8px",
  },
};

const AddTeamMember = () => {
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = useState(null);
  const [imageUrl, setImageUrl] = useState(null); // To store the uploaded image URL
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  // To handle the image upload loading state
  const [thumbnailPreview, setThumbnailPreview] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const formik = useFormik({
    initialValues: {
      memberName: "",
      designation: "",
      profilePhoto: null,
    },
    validationSchema: Yup.object({
      memberName: Yup.string()

        .max(50, "Member name can't be more than 50 characters.")
        .required("Please enter the member's name."),
      designation: Yup.string()

        .max(50, "Designation can't be more than 50 characters.")
        .required("Please enter the designation."),
      profilePhoto: Yup.mixed().required("Please upload the image."),
    }),

    onSubmit: async (values) => {
      

      try {
        // Prepare team member data
        const data = {
          memberName: values.memberName,
          designation: values.designation,
          profilePhoto: imageUrl, // Use the uploaded image URL
        };

        // Send data to backend
        const response = await postDataHandlerWithToken("addUpdateTeam", data);

        if (response?.responseCode === 200) {
          toast.success("Team member added successfully");
          navigate("/staticcontent/team");
        } else {
          toast.error(
            response?.responseMessage || "Failed to add team member."
          );
        }
      } catch (error) {
        console.error("Error adding team member:", error);
        toast.error("Failed to add team member. Please try again later.");
      } finally {
      }
    },
  });

  const handleThumbnailChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      formik.setFieldValue("profilePhoto", file);
    }
  };
  const handleCropComplete = (cloudinaryUrl) => {
    setCroppedImage(cloudinaryUrl);
    setImageUrl(cloudinaryUrl);
    setImagePreview(cloudinaryUrl);
    setSelectedImage(null);
  };

  return (
    <DashboardLayout sectionName="Team Management" title="Add Team Member">
      <Box
        className="heading-container-top"
        sx={{ textAlign: "center", marginBottom: "20px" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>
            Add Team Member
          </Typography>
        </Box>
      </Box>
      <Box className="add-edit-container">
        <form
          className="add-edit-content-section"
          onSubmit={formik.handleSubmit}
        >
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Member Name
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="memberName"
                  placeholder="Enter the member's name"
                  value={formik.values.memberName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  maxLength={50}
                />
                {formik.touched.memberName && formik.errors.memberName ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.memberName}
                  </p>
                ) : null}
              </Box>
            </Box>

            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Designation
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="designation"
                  placeholder="Enter the designation"
                  value={formik.values.designation}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  maxLength={30}
                />
                {formik.touched.designation && formik.errors.designation ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.designation}
                  </p>
                ) : null}
              </Box>
            </Box>

            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Upload Profile Photo
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="file"
                  name="profilePhoto"
                  onBlur={formik.handleBlur}
                  onChange={handleThumbnailChange}
                  acceptedFile="image/*"
                />
                {formik.touched.profilePhoto && formik.errors.profilePhoto ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.profilePhoto}
                  </p>
                ) : null}
                {imagePreview && (
                  <Box sx={{ marginTop: "10px" }}>
                    <Card sx={{ maxWidth: 200, maxHeight: 200 }}>
                      <CardMedia component="img" src={imagePreview} />
                    </Card>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          <Box sx={{ textAlign: "center", marginTop: "20px" }}>
            <Box>
              <BasicButtons
                text={loading || imageLoading ? "Processing..." : "Add"}
                name="submit"
                type="submit"
                disabled={loading || imageLoading} // Disable the submit button if image is being uploaded
                loading={loading || imageLoading}
                width={"30%"}
              />
            </Box>
          </Box>
        </form>
      </Box>
      <ImageCropper
        image={selectedImage}
        aspectRatio={16 / 9}
        onCropComplete={handleCropComplete}
        onClose={() => setSelectedImage(null)}
      />
    </DashboardLayout>
  );
};

export default AddTeamMember;
