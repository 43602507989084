import React, { useEffect, useState } from "react";
import { Box, Typography, Divider, Tooltip, IconButton } from "@mui/material";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import PaginationTable from "../../../components/PaginationTable";
import { useNavigate } from "react-router-dom";
import BasicButtons from "../../../components/Button";
import { toast } from "react-hot-toast";
import EditIcon from "@mui/icons-material/Edit";
import backBtn from "../../../assets/backBtn.svg";
import typographyStyles from "../../../theme/Typography";
import VisibilityIcon from "@mui/icons-material/Visibility";


import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "../../../Modal/DeleteModal";
import {
  getDataHandler,
  deleteDataHandler,
  getDataHandlerWithToken,
} from "../../../apiconfig/service";
import moment from "moment";
import DataNotFound from "../../../components/DataNotFound";

const columns = [
  { id: "sr_no", label: "Sr.No." },
  { id: "title", label: "Title" },
  { id: "description", label: "Description" },
  { id: "created_date_time", label: "Created Date & Time" },
  { id: "action", label: "Action" },
];

const ListStatistics = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [statisticsData, setStatisticsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDataHandlerWithToken("getAllStatistics");
        if (response?.responseCode === 200) {
          setStatisticsData(response.result);
        } else {
          toast.error(
            response?.responseMessage || "Failed to fetch statistics"
          );
        }
      } catch (error) {
        console.error("Error in fetching data", error);
        toast.error("Failed to fetch statistics");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleEdit = (statisticsData) => {
    navigate(`/staticcontent/listStatistics/editStatistics`, {
      state: { statisticsData },
    });
  };

  const handleClickOpen = (statisticsData) => {
    navigate(`/staticcontent/listStatistics/viewStatistics`, {
      state: { statisticsData },
    });
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  const handleDeleteConfirm = async () => {
    if (!selectedRow) return;

    try {
      const response = await deleteDataHandler("deleteStaticContent", {
        staticContentId: selectedRow._id,
      });
      if (response?.responseCode === 200) {
        toast.success("Statistic deleted successfully");
        setStatisticsData(
          statisticsData.filter((item) => item._id !== selectedRow._id)
        );
      } else {
        toast.error(response?.responseMessage || "Failed to delete statistic");
      }
    } catch (error) {
      console.error("Error deleting statistic", error);
      toast.error("Failed to delete statistic");
    } finally {
      handleClose();
    }
  };

  return (
    <DashboardLayout
      sectionName="Static Content Management"
      title="Statistics Management"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/staticcontent")}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>
            Statistics Management
          </Typography>
        </Box>
        <Box className="button-container"></Box>
      </Box>
      <br />
      <Divider />
      <Box className="StaticScreenContainer">
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            {/* Add a Loading component if needed */}
          </Box>
        )  : statisticsData?.length < 1 ? (
          <DataNotFound/>
        ) : (
          <PaginationTable
            columns={columns}
            data={statisticsData?.map((item, index) => ({
              sr_no: index + 1,
              title: item?.title,
              description: item?.description,
              created_date_time: moment(item.createdAt).format(
                "MM/DD/YYYY, h:mm A"
              ),
              action: (
                <Box className="row-button-container">
                 
                  <IconButton
                    onClick={() => handleClickOpen(item)}
                    sx={{ color: "rgb(225,140,168)" }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleEdit(item)}
                    sx={{ color: "rgb(225,140,168)" }}
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
              ),
            }))}
          />
        )}
      </Box>
      {/* {open && (
        <DeleteModal
          open={open}
          handleClose={handleClose}
          handleDelete={handleDeleteConfirm}
          title="Delete Item?"
          description={`Are you sure you want to delete the statistic titled "${selectedRow?.title}"? This action cannot be undone.`}
        />
      )} */}
    </DashboardLayout>
  );
};

export default ListStatistics;
