import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import "../../../scss/main.css";
import typographyStyles from "../../../theme/Typography";
import ViewImg from "../../../assets/viewImg.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { getDataHandlerWithToken } from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
  addEditInfo: {
    ...typographyStyles.h6,
    fontWeight: "300",
  },
};

const stripHtml = (html) => {
  let doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
};

const ViewBooking = () => {
  const [booking, setBooking] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const fetchBooking = async () => {
    const query = {
      bookingId: location.state?.bookingId,
    };
    try {
      const res = await getDataHandlerWithToken("viewBooking", query);
      console.log(res?.result);
      if (res.responseCode === 200) {
        setBooking(res?.result);
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBooking();
  }, []);

  function formatDate(dateStr, timeStr) {
    const dateTime = new Date(`${dateStr}T${timeStr}:00`);
    const optionsDate = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const optionsTime = {
      hour: "numeric",
      minute: "numeric",
     
      hour12: true,
    };
    const formattedDate = dateTime.toLocaleDateString("en-US", optionsDate);
    const formattedTime = dateTime.toLocaleTimeString("en-US", optionsTime);
    return `${formattedDate} at ${formattedTime}`;
  }

  const formattedDateTime = formatDate(booking?.date, booking?.time);

  console.log(booking);
  return (
    <DashboardLayout sectionName="Booking Management" title="Booking Details">
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{
                cursor: "pointer",
              }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>View Booking</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <Box className="add-edit-content-section">
          <Box className="add-edit-content">
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      User Name:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {booking?.userName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Consultant Name:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {booking?.consult?.consultName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>Plan:</TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {booking?.plan}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Amount:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {booking?.amount/100} $
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Booked On:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {new Date(booking?.createdAt).toLocaleString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        }
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Appointment Selected:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {formattedDateTime}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default ViewBooking;
