import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Card, CardMedia, Divider, Typography } from "@mui/material";
import typographyStyles from "../../../theme/Typography";
import InputField from "../../../components/InputField";
import BasicButtons from "../../../components/Button";
import { useNavigate, useLocation } from "react-router-dom";
import {
  postDataHandler,
  putDataHandlerWithToken,
} from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";
import "../../../scss/main.css";

const editStyle = {
  editTitle: {
    ...typographyStyles.h4,
  },
  editLabel: {
    ...typographyStyles.h6,
  },
};

const EditStatistics = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { statisticsData } = state || {};
  const [iconPreview, setIconPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  console.log(statisticsData);
  const SUPPORTED_FORMATS = ["image/jpeg", "image/png", "image/gif"];
  const FILE_SIZE = 1024 * 1024; // 1MB
  const formik = useFormik({
    initialValues: {
      statisticsValue: statisticsData?.statistics || "",
      title: statisticsData?.title || "",
      description: statisticsData?.description || "",
      featureIcon: null,
    },
    validationSchema: Yup.object({
      statisticsValue: Yup.string()
        .min(0, "Value must be greater than or equal to 0.")
        .required("Please enter the statistics value."),
      title: Yup.string()
        .min(5, "Title must be at least 5 characters.")
        .max(25, "Title must be 25 characters or less.")
        .required("Please enter the title."),
      description: Yup.string()
        .min(10, "Description must be at least 10 characters.")
        .required("Please enter the description."),
      featureIcon: Yup.mixed()
        .nullable()
        .test("fileSize", "The file is too large.", (value) => {
          return value ? value.size <= FILE_SIZE : true;
        })
        .test("fileFormat", "Unsupported Format", (value) => {
          return value
            ? ["image/jpeg", "image/png", "image/gif"].includes(value.type)
            : true;
        })
        .test(
          "fileDimensions",
          "File diamension should be less than or equal to 100*100",
          async (value) => {
            if (!value) return true; // Skip validation if no file is provided
            return new Promise((resolve) => {
              const render = new FileReader();
              render.onload = (e) => {
                const img = new Image();
                img.src = e.target.result;
                img.onload = () => {
                  resolve(img.width <= 100 && img.height <= 100);
                };
              };
              render.readAsDataURL(value);
            });
          }
        ),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const data = {
          statisticId: statisticsData?._id,
          statistics: values.statisticsValue,
          title: values.title,
          description: values.description,
          icon: statisticsData?.icon, // Keep existing icon URL if no new icon is uploaded
        };
        console.log(values);

        if (values.featureIcon) {
          const formData = new FormData();
          formData.append("file", values.featureIcon);
          const cloudinaryResponse = await postDataHandler(
            "uploadFile",
            formData
          );

          data.icon = cloudinaryResponse.result;
        }
        console.log(data);
        const response = await putDataHandlerWithToken("updateStatistic", data);
        if (response.responseCode === 200) {
          toast.success("Statistic updated successfully");
          navigate("/staticcontent/listStatistics");
        } else {
          toast.error("Something went wrong, try again.");
        }
      } catch (error) {
        console.error("Error updating statistic:", error);
        toast.error("Failed to update statistic. Please try again later.");
      } finally {
        setLoading(false);
      }
    },
  });

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    setFieldValue,
  } = formik;

  const handleIconChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFieldValue("featureIcon", file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setIconPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (statisticsData?.icon) {
      setIconPreview(statisticsData.icon);
    }
  }, [statisticsData]);

  const handleBackClick = () => {
    navigate(-1);
  };

  console.log(errors);

  return (
    <DashboardLayout
      sectionName="Static Content Management"
      title="Edit Statistics"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={handleBackClick}
            />
          </Box>
          <Typography sx={editStyle.editTitle}>Edit Statistics</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <form className="add-edit-content-section" onSubmit={handleSubmit}>
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={editStyle.editLabel}>
                  Statistics Value:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="statisticsValue"
                  placeholder="Enter the statistics value"
                  value={values.statisticsValue}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={20}
                />
                {errors.statisticsValue && touched.statisticsValue ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.statisticsValue}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={editStyle.editLabel}>Title:</Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="title"
                  placeholder="Enter the title"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={25}
                />
                {errors.title && touched.title ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.title}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={editStyle.editLabel}>Description:</Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="description"
                  placeholder="Enter the description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={100}
                />
                {errors.description && touched.description ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.description}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={editStyle.editLabel}>Upload Icon:</Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="file"
                  name="featureIcon"
                  onBlur={handleBlur}
                  onChange={handleIconChange}
                  acceptedFile="image/*"
                />
                {errors.featureIcon && touched.featureIcon ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.featureIcon}
                  </p>
                ) : null}{" "}
                <br />
                {iconPreview && (
                  <Box sx={{ marginTop: "10px" }}>
                    <Card sx={{ maxWidth: 200, maxHeight: 200 }}>
                      <CardMedia
                        component="img"
                        image={iconPreview}
                        alt="invalid image url"
                      />
                    </Card>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            className="add-edit-button-section"
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <BasicButtons
              text="Update"
              name="submit"
              type="submit"
              disabled={loading}
              loading={loading}
              width={"20%"}
            />
          </Box>
        </form>
      </Box>
    </DashboardLayout>
  );
};

export default EditStatistics;
