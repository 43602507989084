import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  Card,
  CardMedia,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import typographyStyles from "../../../theme/Typography";
import backBtn from "../../../assets/backBtn.svg";
import "../../../scss/main.css";
import DataNotFound from "../../../components/DataNotFound";

const viewStyle = {
  viewTitle: {
    ...typographyStyles.h4,
  },
  viewLabel: {
    ...typographyStyles.h6,
  },
  viewInfo: {
    ...typographyStyles.h6,
    fontWeight: "300",
  },
};

const ViewStatistics = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const statistics = location.state?.statisticsData;
 console.log(statistics)
  if (!statistics) {
    return <><DataNotFound/></>;
  }

  return (
    <DashboardLayout
      sectionName="Statistics Management"
      title="View Statistics"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={viewStyle.viewTitle}>View Statistics</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="view-container" sx={{ padding: "2%" }}>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={viewStyle.viewLabel}>Title:</TableCell>
                <TableCell sx={viewStyle.viewInfo}>{statistics.title}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={viewStyle.viewLabel}>Statistics Value:</TableCell>
                <TableCell sx={viewStyle.viewInfo}>{statistics.statistics}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={viewStyle.viewLabel}>Description:</TableCell>
                <TableCell sx={viewStyle.viewInfo}>
                  <div dangerouslySetInnerHTML={{ __html: statistics.description }} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={viewStyle.viewLabel}>Icon:</TableCell>
                <TableCell sx={viewStyle.viewInfo}>
                  {statistics.icon && (
                    <Card sx={{ maxWidth: 100, }}>
                      <CardMedia
                        component="img"
                        image={statistics.icon}
                        alt={statistics.title}
                      />
                    </Card>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </DashboardLayout>
  );
};

export default ViewStatistics;
