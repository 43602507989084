import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Typography,Tooltip } from "@mui/material";

import "../../../scss/main.css";
import typographyStyles from "../../../theme/Typography";
import ViewImg from "../../../assets/viewImg.svg";
import { getDataHandlerWithToken } from "../../../apiconfig/service";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },

  addEditLabel: {
    ...typographyStyles.h6,
  },

  addEditInfo: {
    ...typographyStyles.h6,
    fontWeight: "300",
  },

  radioContainer: {
    display: "flex",
  },

  // imagePreview: {
  //     marginTop: '10px',
  //     objectFit: 'cover',
  //     border: '2px solid gray',
  // }
};

const ViewExerciseDetails = () => {
  const { exerciseId } = useParams();
  const [values, setValues] = useState({});
  useEffect(() => {
    const fetchExercise = async () => {
      try {
        const result = await getDataHandlerWithToken("viewExercise", {
          exerciseId,
        });
        if (result.responseCode === 200) {
          console.log(result.result);
          toast.success(result.responseMessage);

          const exerciseData = result.result;
          setValues(exerciseData);
          console.log();
        } else {
          toast.error(result.responseMessage);
          console.log(result);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchExercise();
  }, [exerciseId, setValues]);
  console.log(values);

  return (
    <DashboardLayout
      sectionName="Workout Management - Category - Exercise"
      title="Exercise Detials"
      showBackButtonSection={true}
    >
      <Box className="add-edit-container">
        <Box className="add-edit-content-section">
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Exercise Name:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <Typography sx={addEditStyle.addEditInfo}>
                  {values.exerciseName}
                </Typography>
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Exercise Thumbnail Image:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <img src={values.image} alt="ViewImg" />
              </Box>
            </Box>
            <Box className="add-edit-input-container desc-box">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Description:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <Typography sx={addEditStyle.addEditInfo}>
                  {values.description}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default ViewExerciseDetails;
