import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Avatar,
  Card,
  CardMedia,
} from "@mui/material";
import InputField from "../../../components/InputField";
import { useNavigate } from "react-router-dom";
import {
  postDataHandlerWithToken,
  postDataHandler,
} from "../../../apiconfig/service";
import backBtn from "../../../assets/backBtn.svg";
import typographyStyles from "../../../theme/Typography";
import { toast } from "react-hot-toast";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import "../../../scss/main.css";
import BasicButtons from "../../../components/Button";

const addEditStyle = {
  addEditTitle: {
    fontSize: "24px",
    fontWeight: 600,
  },
  addEditLabel: {
    fontSize: "16px",
    fontWeight: 500,
    marginBottom: "8px",
  },
};

const AddTestimonial = () => {
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      username: "",
      title: "",
      rating: 0,

      description: "",
      profilePic: null,
      video: null,
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .required("Please enter the username.")
        .min(5, "The username text must be at least 5 characters long.")
        .max(50, "The username text cannot be more than 50 characters long."),
      title: Yup.string()
        .required("Please enter the testimonial title.")
        .min(5, "The title text must be at least 5 characters long.")
        .max(50, "The title text cannot be more than 50 characters long."),
      description: Yup.string()
        .required("Please enter the testimonial text.")
        .min(20, "The testimonial text must be at least 20 characters long.")
        .max(
          500,
          "The testimonial text cannot be more than 500 characters long."
        ),

      rating: Yup.number()
        .min(1)
        .max(5)
        .required("Please enter a rating between 1 and 5."),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);

        // Upload image
        const imageFormData = new FormData();
        imageFormData.append("file", values.profilePic);
        const imageResponse = await postDataHandler(
          "uploadFile",
          imageFormData
        );
        const imageUrl = imageResponse.result;

        // Upload video
        const videoFormData = new FormData();
        videoFormData.append("file", values.video);
        const videoResponse = await postDataHandler(
          "uploadVideo",
          videoFormData
        );
        const videoUrl = videoResponse.result;

        // Prepare testimonial data
        const data = {
          username: values.username,
          title: values.title,
          rating: values.rating,
          description: values.description,
          profilePic: imageUrl,
          video: videoUrl,
        };

        // Send data to backend
        const response = await postDataHandlerWithToken("addTestimonial", data);

        if (response?.responseCode === 200) {
          toast.success("Testimonial added successfully");
          navigate("/staticcontent/testimonialmanagement");
        } else {
          toast.error(
            response?.responseMessage || "Failed to add testimonial."
          );
        }
      } catch (error) {
        console.error("Error adding testimonial:", error);
        toast.error("Failed to add testimonial. Please try again later.");
      } finally {
        setLoading(false);
      }
    },
  });

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      formik.setFieldValue("profilePic", file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      formik.setFieldValue("video", file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setVideoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <DashboardLayout
      sectionName="Static Content Management"
      title="Add Testimonial"
    >
      <Box
        className="heading-container-top"
        sx={{ textAlign: "center", marginBottom: "20px" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>
            Add Testimonial
          </Typography>
        </Box>
      </Box>
      <Box className="add-edit-container">
        <form
          className="add-edit-content-section"
          onSubmit={formik.handleSubmit}
        >
          <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
            <IconButton component="label">
              <input
                type="file"
                acceptedFile="image/*"
                hidden
                onChange={handleImageChange}
              />
              <Avatar
                src={imagePreview || "/defaultAvatar.png"}
                sx={{ width: 100, height: 100, margin: "0 auto" }}
              />
              <CameraAltIcon
                sx={{
                  position: "absolute",
                  bottom: 0,
                  right: "calc(50% - 12px)",
                  backgroundColor: "white",
                  borderRadius: "50%",
                  padding: "2px",
                }}
              />
            </IconButton>
          </Box>

          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Full Name
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="username"
                  placeholder="Enter the name"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  maxLength={50}
                />
                {formik.touched.username && formik.errors.username ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.username}
                  </p>
                ) : null}
              </Box>
            </Box>

            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Title/Profession
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="title"
                  placeholder="Job role"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  maxLength={50}
                />
                {formik.touched.title && formik.errors.title ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.title}
                  </p>
                ) : null}
              </Box>
            </Box>

            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Description
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="description"
                  placeholder="Enter the description"
                  multiline
                  minRows={4}
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  maxLength={500}
                />
                {formik.touched.description && formik.errors.description ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.description}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>Rating:</Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="number"
                  name="rating"
                  placeholder="Enter the rating (1-5)"
                  value={formik.values.rating}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.rating && formik.errors.rating ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.rating}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Upload Client Review Video/Image
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="file"
                  name="video"
                  onBlur={formik.handleBlur}
                  onChange={handleVideoChange}
                  
                />
                {formik.touched.video && formik.errors.video ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.video}
                  </p>
                ) : null}
                {videoPreview && (
                  <Box sx={{ marginTop: "10px" }}>
                    <Card sx={{ maxWidth: 200, maxHeight: 200 }}>
                      <CardMedia
                        component={
                          formik.values.video.type.includes("video")
                            ? "video"
                            : "img"
                        }
                        src={videoPreview}
                        controls
                      />
                    </Card>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          <Box
            className="add-edit-button-section"
            sx={{ textAlign: "center", marginTop: "20px" }}
          >
            <Box
              className="add-edit-button-section"
              sx={{ alignItems: "center", justifyContent: "center" }}
            >
              <BasicButtons
                text={loading ? "Adding..." : "Add"}
                name="submit"
                type="submit"
                disabled={loading}
                loading={loading}
                width={"20%"}
              />
            </Box>
          </Box>
        </form>
      </Box>
    </DashboardLayout>
  );
};

export default AddTestimonial;
