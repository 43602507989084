import React, { useState, useEffect } from "react";
import { Box, Typography, Divider, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BlockIcon from "@mui/icons-material/Block";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import PaginationTable from "../../../components/PaginationTable";
import BlockModal from "../../../Modal/BlockModal";
import Loading from "../../../components/Loading";
import typographyStyles from "../../../theme/Typography";
import {
  getDataHandlerWithToken,
  putDataHandlerWithToken,
} from "../../../apiconfig/service";
import DataNotFound from "../../../components/DataNotFound";

const columns = [
  { id: "sr_no", label: "Sr.No." },
  { id: "trimester", label: "Trimester" },
  { id: "created_date_time", label: "Created Date & Time" },
  { id: "action", label: "Action" },
];

const TrimesterListing = () => {
  const [pregnancyTrackerList, setPregnancyTrackerList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const [blockId, setBlockId] = useState(null);
  const [status, setStatus] = useState();
  const [name, setName] = useState();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const handleView = (id) => {
    navigate("/pregnancyTrackerListing/TrimesterWeeks", {
      state: { trimesterId: id },
    });
  };

  const handleEdit = (id) => {
    navigate("/pregnancyTrackerListing/editTrimester", {
      state: { trimesterId: id },
    });
  };

  const handleBlock = (id, name, status) => {
    setBlockId(id);
    setStatus(status === "ACTIVE" ? "BLOCK" : "ACTIVE");
    setName(name);
    setBlockModalOpen(true);
  };

  const handleBlockConfirm = async () => {
    const id = blockId;
    const query = { trimesterId: id };

    try {
      const res = await putDataHandlerWithToken(
        "blockUnblockPregnancyTracker",
        {},
        query
      );
      if (res.responseCode === 200) {
        const updatedPregnancyTrackerList = pregnancyTrackerList.map(
          (program) => (program._id === id ? { ...program, status } : program)
        );
        setPregnancyTrackerList(updatedPregnancyTrackerList);
        setFilteredData(updatedPregnancyTrackerList);
        toast.success(res.responseMessage);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setBlockModalOpen(false);
    }
  };

  const handleBlockCancel = () => {
    setBlockModalOpen(false);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await getDataHandlerWithToken("getTrimester");
      if (res.responseCode === 200) {
        setPregnancyTrackerList(res.result || []);
        setFilteredData(res.result);
      } else {
        // toast.error(res.responseMessage);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <DashboardLayout
      sectionName="Pregnancy Tracker Management"
      showBackButtonSection={false}
    >
      <Box className="category-container">
        <Box className="heading-container-top">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ ...typographyStyles.h4 }}>
              Trimester Listing
            </Typography>
          </Box>
          <Box className="button-container" />
        </Box>
        <Divider />
        <Box className="table-container">
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <Loading />
            </Box>
          )  : filteredData?.length < 1 ? (
            <DataNotFound />
          ) : (
            <PaginationTable
              columns={columns}
              data={filteredData.map((obj, index) => ({
                sr_no: index + 1,
                trimester: (
                  <Tooltip title={obj.trimesterName} arrow>
                    <span>
                      {obj.trimesterName.length > 20
                        ? `${obj.trimesterName.substring(0, 20)}...`
                        : obj.trimesterName}
                    </span>
                  </Tooltip>
                ),
                created_date_time: new Date(obj.createdAt).toLocaleString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  }
                ),
                action: (
                  <Box className="row-button-container" sx={{ gap: "15%" }}>
                    <VisibilityIcon
                      onClick={() => handleView(obj._id)}
                      sx={{ color: "rgb(225,140,168)", cursor: "pointer" }}
                    />
                    {/* <BlockIcon
                      onClick={() => handleBlock(obj._id, obj.trimesterName, obj.status)}
                      sx={{
                        color: obj.status === "ACTIVE" ? "green" : "red",
                        cursor: "pointer",
                      }}
                    /> */}
                  </Box>
                ),
              }))}
            />
          )}
        </Box>
      </Box>
      {blockModalOpen && (
        <BlockModal
          title="TRIMESTER"
          status={status}
          name={name}
          onClose={handleBlockCancel}
          onBlock={handleBlockConfirm}
        />
      )}
    </DashboardLayout>
  );
};

export default TrimesterListing;
