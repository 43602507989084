import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ListItemText } from "@mui/material";

export default function Dropdown({
  options,
  value,
  onChange,
  placeholder,
  label,
  width,
  none,
}) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleSelect = (selectedOption) => {
    onChange(selectedOption);
    setOpen(false);
  };

  return (
    <Box
      sx={{
        width: width ? 130 : "100%",
        display: none ? none : "block",
        // maxWidth: '100%',
        // width: '100%',
      }}
    >
      <FormControl fullWidth>
        <InputLabel id="basic-select-label">{label}</InputLabel>
        <Select
          labelId="radio-dropdown-label"
          id="radio-dropdown"
          open={open}
          onClose={handleOpen}
          onOpen={handleOpen}
          value={value}
          onChange={(e) => handleSelect(e.target.value)}
          displayEmpty
          sx={{
            "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input":
              {
                minHeight: "13px !important",
                //height:"13px !important"
                lineHeight: "14px !important",
              },
            // Styling the placeholder text
            "& .placeholder": {
              color: "grey", // Change this color to your desired placeholder color
            },
          }}
          renderValue={(selected) => {
            if (selected === "") {
              return (
                <span className="placeholder" style={{ color: "grey" }}>
                  {placeholder || "Select range"}
                </span>
              );
            }
            return selected;
          }}
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={option}>
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
