import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Typography, Divider } from "@mui/material";
import PaginationTable from "../../../components/PaginationTable";
import { useNavigate } from "react-router-dom";
import BasicButtons from "../../../components/Button";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";
import typographyStyles from "../../../theme/Typography";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "../../../Modal/DeleteModal";
import { getDataHandler, deleteDataHandler } from "../../../apiconfig/service";
import Loading from "../../../components/Loading";
import DataNotFound from "../../../components/DataNotFound";

const columns = [
  { id: "sr_no", label: "Sr.No." },
  { id: "feature_title", label: "Feature Title" },
  { id: "created_date_time", label: "Created Date & Time" },
  { id: "action", label: "Action" },
];

const ListKeyFeatures = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [featureData, setFeatureData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDataHandler("getAllstaticContentByType", {
          contentType: "KEYFEATURES",
        });
        if (response?.responseCode === 200) {
          setFeatureData(response.result);
        } else {
          toast.error(response?.responseMessage || "Failed to fetch features");
        }
      } catch (error) {
        console.error("Error in fetching data", error);
        toast.error("Failed to fetch features");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleView = (featureData) => {
    navigate(`/staticcontent/keyFeature/viewKeyFeature`, {
      state: { featureData },
    });
  };

  const handleEdit = (featureData) => {
    navigate(`/staticcontent/listKeyFeatures/editKeyFeatures`, {
      state: { featureData },
    });
  };

  const handleClickOpen = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  const handleDeleteConfirm = async () => {
    if (!selectedRow) return;

    try {
      const response = await deleteDataHandler("deleteStaticContent", {
        staticContentId: selectedRow._id,
      });
      if (response?.responseCode === 200) {
        toast.success("Feature deleted successfully");
        setFeatureData(
          featureData.filter((item) => item._id !== selectedRow._id)
        );
      } else {
        toast.error(response?.responseMessage || "Failed to delete feature");
      }
    } catch (error) {
      console.error("Error deleting feature", error);
      toast.error("Failed to delete feature");
    } finally {
      handleClose();
    }
  };

  return (
    <DashboardLayout
      sectionName="Static Content Management"
      title="Feature Management"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/staticcontent")}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>
            Key Feature Management
          </Typography>
        </Box>
        <Box className="button-container">
          <BasicButtons
            text="+Add Feature"
            onClick={() =>
              navigate("/staticcontent/listKeyFeatures/addKeyFeatures")
            }
            disabled={featureData?.length >= 8}
          />
        </Box>
      </Box>
      <br />
      <Divider />
      <Box className="StaticScreenContainer">
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <Loading/>
          </Box>
        )  : featureData?.length < 1 ? (
          <DataNotFound/>
        ) : (
          <PaginationTable
            columns={columns}
            data={featureData?.map((item, index) => ({
              sr_no: index + 1,
              feature_title: item.title,
              created_date_time: moment(item.createdAt).format(
                "MM/DD/YYYY, h:mm A"
              ),
              action: (
                <Box className="row-button-container">
                  <EditIcon
                    variant="outlined"
                    onClick={() => handleEdit(item)}
                    sx={{ color: "rgb(225,140,168)", cursor: "pointer" }}
                  />
                  <DeleteIcon
                    variant="outlined"
                    onClick={() => handleClickOpen(item)}
                    sx={{ color: "rgb(225,140,168)", cursor: "pointer" }}
                  />
                </Box>
              ),
            }))}
          />
        )}
      </Box>
      {open && (
        <DeleteModal
          open={open}
          handleClose={handleClose}
          handleDelete={handleDeleteConfirm}
          title="Delete Item?"
          description={`Are you sure you want to delete the feature titled "${selectedRow?.title}"? This action cannot be undone.`}
        />
      )}
    </DashboardLayout>
  );
};

export default ListKeyFeatures;
