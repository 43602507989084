import React, { useState, useEffect,useCallback } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Typography, Divider, Card, CardMedia } from "@mui/material";
import typographyStyles from "../../../theme/Typography";
import BasicButtons from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import InputField from "../../../components/InputField";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { topicProgram } from "../../../schema";
import { toast } from "react-hot-toast";
import "../../../scss/main.css";
import {
  getDataHandlerWithToken,
  postDataHandlerWithToken,
  postDataHandler,
} from "../../../apiconfig/service";
import backBtn from "../../../assets/backBtn.svg";
import Cropper from "react-easy-crop";
import ReactModal from "react-modal";

import { modalStyles } from "../../../scss/style"; // Ensure this path is correct
import { cropAndUploadImage } from "../../../utils/cropAndUploadImage";

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },

  addEditLabel: {
    ...typographyStyles.h6,
  },

  radioContainer: {
    display: "flex",
  },
};

const dropdownOptions = [
  "Trying to Conceive",
  "Pregnant",
  "Postpartum (0-6 Weeks After Birth)",
  "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
];

const se = [
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum (0-6 Weeks After Birth)", value: "POSTPARTUM" },
  {
    key: "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
    value: "BEYOND",
  },
];

const EditTopicProgram = () => {
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("Select Category");
  const [categoryList, setCategoryList] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [categoryType, setCategoryType] = useState([]);
  const [thumbnail, setThumbnail] = useState(null);
  const location = useLocation();
  const [program, setProgram] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [mydata, setMydata] = useState([]);
  // States for cropping functionality
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null)

  let formData = new FormData();

  const fetchProgram = async () => {
    const query = {
      programId: location.state?.programId,
    };
    try {
      const res = await getDataHandlerWithToken("viewProgram", query);
      if (res.responseCode === 200) {
        setProgram(res?.result);
        setThumbnail(res?.result?.programThumbnailImage); // Set the thumbnail
        const stageKey = se.find(
          (option) => option.value === res?.result?.stage
        )?.key;
        setSelectedValue(stageKey);
        setSelectedCategory(res?.result?.categoryName || "Select Category");
        setCategoryId(res?.result?.categoryId || "");
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      const res = await getDataHandlerWithToken("listCategory");
      if (res.responseCode === 200) {
        setMydata(res?.result?.docs || []);
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filterCategoriesByStage = (selectedValue) => {
    const stageValue = se.find((option) => option.key === selectedValue)?.value;
    if (stageValue) {
      const filteredCategories = mydata.filter(
        (category) =>
          category.stage === stageValue &&
          category.categoryName != "Recipes" &&
          category.categoryName != "Workout" &&
          category.status !== "BLOCK"
      );
      setCategoryList(filteredCategories);
    }
  };

  useEffect(() => {
    fetchData().then(() => {
      fetchProgram();
    });
  }, []);

  useEffect(() => {
    if (selectedValue) {
      filterCategoriesByStage(selectedValue);
    }
  }, [selectedValue, mydata]);

  const handleDropdownChange = (value) => {
    setFieldValue("selectStage", value);
    setSelectedValue(value);
    setSelectedCategory("");
    setCategoryId("");
    filterCategoriesByStage(value);
  };

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
    const selectedCategoryObj = categoryList.find(
      (obj) => obj.categoryName === value
    );
    if (selectedCategoryObj) {
      setCategoryId(selectedCategoryObj._id);
    }
  };

  const handleThumbnailChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));

      setIsModalOpen(true);
    }
  };
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  // Image cropping functions
  const handleCrop = useCallback(async () => {
    setLoading(true);
    try {
      const cloudinaryUrl = await cropAndUploadImage(
        selectedImage,
        croppedAreaPixels
      );
      setCroppedImage(cloudinaryUrl);
      setSelectedImage(cloudinaryUrl);
      setThumbnail(cloudinaryUrl);
     
      setIsModalOpen(false);
    } catch (e) {
      toast.error("Error while cropping or uploading image");
    }
    setLoading(false);
  }, [croppedAreaPixels, selectedImage]);

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
    setCroppedImage(null);
    setThumbnail(null);
  };

  const handleFormSubmit = async (values) => {
    const data = {
      programId: location.state?.programId,
      stage: se.find((option) => option.key === selectedValue)?.value,
      categoryId: categoryId,
      programName: values.programName,
      programThumbnailImage: croppedImage
        ? croppedImage
        : program.programThumbnailImage,
    };
    try {
      if (!loading) {
        setLoading(true);
        const res = await postDataHandlerWithToken("addUpdateProgram", data);
        if (res?.responseCode === 200) {
          toast.success(res?.responseMessage);
          navigate("/topiclisting");
        } else {
          toast.error(res?.data?.responseMessage);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const initialValues = {
    selectStage: program?.stage
      ? se.find((option) => option.value === program.stage)?.key
      : "",
    categoryName: program?.categoryName || "",
    programName: program?.programName || "",
    categoryImage: program?.programThumbnailImage,
  };

  const {
    values,
    errors,
    setFieldValue,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: topicProgram,
    enableReinitialize: true,
    onSubmit: handleFormSubmit,
  });

  return (
    <DashboardLayout
      sectionName="Content - Program Management"
      title="Edit Program"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{
                cursor: "pointer",
              }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>Edit Program</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <form className="add-edit-content-section" onSubmit={handleSubmit}>
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Select Stage:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <Dropdown
                  name="selectStage"
                  onBlur={handleBlur}
                  sx={addEditStyle.categoryInputField}
                  options={dropdownOptions}
                  value={values.selectStage}
                  onChange={handleDropdownChange}
                />
                {errors.selectStage && touched.selectStage ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.selectStage}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Category:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <Dropdown
                  name="selectCategory"
                  onBlur={handleBlur}
                  sx={addEditStyle.categoryInputField}
                  options={categoryList.map((obj) => obj.categoryName)}
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                />
                {errors.selectRange && touched.selectRange ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.selectRange}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Program Name:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  placeholder="Program Name"
                  name="programName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.programName}
                  error={touched.programName && Boolean(errors.programName)}
                  helperText={touched.programName && errors.programName}
                />
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Thumbnail Image:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="file"
                  name="categoryImage"
                  acceptedFile="image/*"
                  onChange={(event) => {
                    setFieldValue(
                      "categoryImage",
                      event.currentTarget.files[0]
                    );
                    handleThumbnailChange(event);
                  }}
                  onBlur={handleBlur}
                />
                {errors.programThumbnailImage &&
                touched.programThumbnailImage ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.programThumbnailImage}
                  </p>
                ) : null}
                {thumbnail && (
                  <Box mt={2}>
                    <Card
                      sx={{ maxWidth: 300, maxHeight: "auto", margin: "5px" }}
                    >
                      <CardMedia component="img" image={thumbnail} />
                    </Card>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            className="add-edit-button-section"
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <BasicButtons
              type="submit"
              style={{ ...addEditStyle.btn, marginLeft: "10px" }}
              loading={loading}
              disabled={loading}
              children="Update"
              text={"Edit Program"}
              width={"20%"}
            />
          </Box>
        </form>
      </Box>
      {/* Image Cropper Modal */}
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={() => handleCancel()}
        style={modalStyles}
        contentLabel="Crop Image"
      >
        <div style={modalStyles.cropperContainer}>
          <Cropper
            image={selectedImage}
            crop={crop}
            zoom={zoom}
            aspect={17 / 12}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              p: 2,
              gap: 5,
            }}
          >
            <BasicButtons text="Cancel" onClick={handleCancel} />
            <BasicButtons text="Crop" onClick={handleCrop} loading={loading} />
          </Box>
        </div>
      </ReactModal>
    </DashboardLayout>
  );
};

export default EditTopicProgram;
