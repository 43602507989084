import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import "../../../scss/main.css";
import typographyStyles from "../../../theme/Typography";
import ViewImg from "../../../assets/viewImg.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { getDataHandlerWithToken } from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
  addEditInfo: {
    ...typographyStyles.h6,
    fontWeight: "300",
  },
};


const ViewCoupon = () => {
  const [couponData, setcouponData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.state?.couponId);

  const fetchProgram = async () => {
    const query = {
      couponId: location.state?.couponId,
    };
    try {
      const res = await getDataHandlerWithToken("viewCoupon", query);
      console.log(res?.result);
      if (res.responseCode === 200) {
        setcouponData(res?.result);
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProgram();
  }, []);

  const formatDateTime = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };
  return (
    <DashboardLayout
      sectionName="Content - Program Management"
      title="Program General Details"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{
                cursor: "pointer",
              }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>View Coupon</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <Box className="add-edit-content-section">
          <Box className="add-edit-content">
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>Coupon Code:</TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {couponData?.name}
                    </TableCell>
                  </TableRow>
                  
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Discount:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {couponData?.percent_off} %   
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Maximum Redemptions:
                    </TableCell>
                   
                    <TableCell sx={addEditStyle.addEditInfo}>


                    {couponData?.max_redemptions === null ? "Unlimited" : `${couponData?.max_redemptions} Times`}
                      
                    </TableCell>
                   
                  </TableRow>
                 
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Creation Date:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {formatDateTime(couponData.created)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Expiry Date:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                    {couponData.redeem_by ? formatDateTime(couponData.redeem_by) : "Forever"}
                      
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Times Redeemed:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {couponData?.times_redeemed}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Duration:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {couponData?.duration}
                    </TableCell>
                  </TableRow>
                  {/* <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Duration in months:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {couponData?.duration_in_months ? couponData?.duration_in_months: "Null" }
                    </TableCell>
                  </TableRow> */}
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Valid:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {couponData?.valid ? "Valid": "Invalid" }
                    </TableCell>
                  </TableRow>
                  
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default ViewCoupon;
