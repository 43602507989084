import React, { useEffect, useState, useCallback } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  Typography,
  Divider,
  Card,
  CardMedia,
  Button,
  Grid,
  IconButton,
  CircularProgress,
} from "@mui/material";
import typographyStyles from "../../../theme/Typography";
import BasicButtons from "../../../components/Button";
import InputField from "../../../components/InputField";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  postDataHandlerWithToken,
  postDataHandler,
  getDataHandlerWithToken,
} from "../../../apiconfig/service";
import backBtn from "../../../assets/backBtn.svg";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import Cropper from "react-easy-crop";
import ReactModal from "react-modal";
import { modalStyles ,commonGridStyle} from "../../../scss/style";
import { cropAndUploadImage } from "../../../utils/cropAndUploadImage";
import EditIcon from "@mui/icons-material/Edit";
import { topicSchema } from "../../../schema";


const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
  addRemoveButton: {
    textTransform: "none",
    backgroundColor: "rgb(225,140,168)",
    color: "white",
    fontWeight: "400",
    fontSize: "15px",
    fontFamily: "'Poppins', sans-serif",
    boxShadow: "0 0 0.938rem 0 rgba(0, 0, 0, 0.2)",
    marginTop: "2px",
    marginLeft: "2px",
    "&:hover": {
      backgroundColor: "white",
      color: "rgb(225,140,168)",
    },
  },
};
const validationSchema = topicSchema;

const EditTopic = () => {
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailPreview, setThumbnailPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [contentDetails, setContentDetails] = useState([
    { description: "", images: [] },
  ]);
  const [topicData, setTopicData] = useState();
  const [charCount, setCharCount] = useState(0);
  const charLimit = 1200; // Set your character limit here
  // States for cropping functionality

  // States for cropping functionality
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageIndexForCropping, setImageIndexForCropping] = useState(null);
  const [descriptionIndexForCropping, setDescriptionIndexForCropping] =
    useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const Navigate = useNavigate();
  const location = useLocation();

  const fetchProgram = async () => {
    const query = {
      topicId: location.state?.topicId,
    };
    try {
      const res = await getDataHandlerWithToken("viewTopic", query);
      if (res.responseCode === 200) {
        setTopicData(res?.result);

        const { topicThumbnailImage, contentDetails } = res?.result;
        setThumbnail(topicThumbnailImage);
        setThumbnailPreview(topicThumbnailImage);
        formik.setFieldValue("categoryImage", res?.result?.topicThumbnailImage);
        const updatedContentDetails = await removeIds(contentDetails || [{ description: "", images: [] }]);
        setContentDetails(updatedContentDetails);
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (location.state?.topicId) {
      fetchProgram();
    }
  }, [location.state?.topicId]);
  

  const removeIds = async (array) => {
    return array.map(({ _id, ...rest }) => rest);
  };

  const handleThumbnailChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setImageIndexForCropping(null); // Ensure cropping is for thumbnail
      setDescriptionIndexForCropping(null);
      setIsModalOpen(true);
    }
  };

  const handleCrop = useCallback(async () => {
    try {
      const cloudinaryUrl = await cropAndUploadImage(
        selectedImage,
        croppedAreaPixels
      );
      if (imageIndexForCropping === null) {
        // If cropping thumbnail
        setThumbnail(cloudinaryUrl);
        setThumbnailPreview(cloudinaryUrl);
        formik.setFieldValue("categoryImage", cloudinaryUrl);
      } else {
        // If cropping description image
        const newContentDetails = [...contentDetails];
        if (descriptionIndexForCropping !== null) {
          newContentDetails[descriptionIndexForCropping].images.push(
            cloudinaryUrl
          );
          setContentDetails(newContentDetails);
        }
      }
      setIsModalOpen(false);
      setSelectedImage(null);
    } catch (e) {
      toast.error("Error while cropping or uploading image");
    }
  }, [
    croppedAreaPixels,
    selectedImage,
    imageIndexForCropping,
    descriptionIndexForCropping,
    contentDetails,
  ]);

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  const handleFileSelectImage = async (event, index) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setImageIndexForCropping(0); // Assuming adding new image to existing list
      setDescriptionIndexForCropping(index);
      setIsModalOpen(true);
    }
  };

  const handleRemoveImage = (sectionIndex, imageIndex) => {
    const newContentDetails = [...contentDetails];
    newContentDetails[sectionIndex].images.splice(imageIndex, 1);
    setContentDetails(newContentDetails);
  };

  const handleAddValueContent = () => {
    setContentDetails([...contentDetails, { description: "", images: [] }]);
  };

  const handleRemoveStepContent = (index) => {
    if (contentDetails.length > 1) {
      const newContentDetails = contentDetails.filter((_, i) => i !== index);
      setContentDetails(newContentDetails);
    } else {
      toast.error("You must have at least one description section.");
    }
  };

  const formik = useFormik({
    initialValues: {
      topicName: topicData?.topicName || "",
      contentDetails: contentDetails || [{ description: "", images: [] }],
      categoryImage: topicData?.topicThumbnailImage || "",
    },
    validationSchema: validationSchema,

    enableReinitialize: true,
    onSubmit: async (values) => {
      const data = {
        topicId: location.state.topicId,
        programId: location.state.programId,
        topicName: values.topicName,
        topicThumbnailImage: thumbnail,
        contentDetails: contentDetails,
      };
      try {
        setLoading(true);
        const res = await postDataHandlerWithToken("addUpdateTopic", data);
        if (res.responseCode === 200) {
          toast.success(res.responseMessage);
          Navigate("/topiclisting/programsTopic", {
            state: { programId: location.state.programId },
          });
        } else {
          toast.error(res.data.responseMessage);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <DashboardLayout
      sectionName="Content - Program Management"
      title="Edit Topic"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={() => Navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>Edit Topic</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <form
          className="add-edit-content-section"
          onSubmit={formik.handleSubmit}
        >
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Topic Name:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="topicName"
                  value={formik.values.topicName}
                  maxLength={50}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter topic name"
                />
                {formik.errors.topicName && formik.touched.topicName ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {formik.errors.topicName}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Topic Thumbnail Image:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="file"
                  name="categoryImage"
                  onChange={handleThumbnailChange}
                  onBlur={formik.handleBlur}
                  acceptedFile="image/*"
                />
                {formik.errors.categoryImage && formik.touched.categoryImage ? (
                  <p style={{ color: "red", marginTop: "5px", float: "left" }}>
                    {formik.errors.categoryImage}
                  </p>
                ) : null}

                {thumbnailPreview && (
                  <Box>
                    <Card
                      sx={{ maxWidth: 250, maxHeight: "auto", margin: "5px" }}
                    >
                      <CardMedia component="img" image={thumbnailPreview} />
                    </Card>
                  </Box>
                )}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={{ ...typographyStyles.h6 }}>
                  Description Section:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                {contentDetails.map((pair, index) => (
                  <Box key={index} mb={2}>
                    <Grid container sx={commonGridStyle}>
                      <Grid
                        item
                        xs={6}
                        md={12}
                        className="add-edit-input-field ckeditor-container"
                        sx={{ width: "100%" }}
                      >
                        <CKEditor
                          editor={ClassicEditor}
                          data={pair.description || ""}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            const newContentDetails = [...contentDetails];
                            newContentDetails[index].description = data;
                            setContentDetails(newContentDetails);
                            formik.setFieldValue(
                              "contentDetails",
                              newContentDetails
                            );
                            const plainText = data.replace(/<[^>]*>/g, "");
                            setCharCount(plainText.length);
                          }}
                          config={{
                            toolbar: [
                              "heading",
                              "|",
                              "bold",
                              "italic",
                              "underline",
                              "strikethrough",
                              "|",
                              "bulletedList",
                              "numberedList",
                              "blockQuote",
                              "|",

                              "undo",
                              "redo",
                            ],
                            // height: "200px",
                          }}
                          className="StaticEditor"
                        />
                        {formik.touched.contentDetails?.[index]?.description &&
                          formik.errors.contentDetails?.[index]
                            ?.description && (
                            <p
                              style={{
                                color: "red",
                                marginTop: "5px",
                                float: "left",
                              }}
                            >
                              {formik.errors.contentDetails[index].description}
                            </p>
                          )}

                        <Typography
                        variant="subtitle2"
                          sx={{
                            
                            textAlign: "right",
                            mt: 1,
                            color: charCount > charLimit ? "red" : "inherit",
                          }}
                        >
                          {charCount}/{charLimit} characters
                        </Typography>
                      </Grid>
                      <Box className="add-edit-input-field" mt={2}>
                        <Button
                          sx={{
                            backgroundColor: "rgb(225, 140, 168)",
                            textTransform: "none",
                          }}
                          variant="contained"
                          component="label"
                          startIcon={<AddIcon />}
                        >
                          Add Images
                          {loadingImage && (
                            <CircularProgress
                              size={20}
                              sx={{ color: "white" }}
                            />
                          )}
                          <input
                            type="file"
                            accept="image/*"
                            hidden
                            onChange={(event) =>
                              handleFileSelectImage(event, index)
                            }
                          />
                        </Button>
                        <Box>
                          {pair.images &&
                            pair.images.map((image, imageIndex) => (
                              <Card
                                key={imageIndex}
                                sx={{
                                  maxWidth: 200,
                                  maxHeight: "auto",
                                  margin: "5px",
                                }}
                              >
                                <CardMedia
                                  component="img"
                                  image={image}
                                  alt={`image ${imageIndex + 1}`}
                                />
                                <IconButton
                                  onClick={() =>
                                    handleRemoveImage(index, imageIndex)
                                  }
                                  sx={{ color: "rgb(225, 140, 168)" }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                               
                              </Card>
                            ))}
                        </Box>
                      </Box>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            gap: "10px",
                          }}
                        >
                          {contentDetails?.length > 1 && (
                            <Grid
                              item
                              xs={2}
                              md={1}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              marginTop="25px"
                            >
                              <IconButton
                                onClick={() =>
                                  handleRemoveStepContent(
                                    index,
                                    contentDetails,
                                    setContentDetails,
                                    "contentDetails"
                                  )
                                }
                                sx={{ ...addEditStyle.addRemoveButton }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          )}
                          {index === contentDetails?.length - 1 && (
                            <Grid
                              item
                              xs={2}
                              md={1}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              marginTop="25px"
                            >
                              <IconButton
                                onClick={() =>
                                  handleAddValueContent(
                                    contentDetails,
                                    setContentDetails
                                  )
                                }
                                sx={addEditStyle.addRemoveButton}
                              >
                                <AddCircleOutlineRoundedIcon />
                              </IconButton>
                            </Grid>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
          <Box
            className="add-edit-button-section"
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <BasicButtons
              type="submit"
              text={loading ? "Saving..." : "Save"}
              btnStyle={{
                backgroundColor: "#E18CA8",
                color: "white",
                width: "100px",
                opacity: loading ? 0.7 : 1,
              }}
              disabled={loading}
              width="25%"
            />
          </Box>
        </form>
      </Box>

      {/* Cropping Modal */}
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={() => handleCancel()}
        style={modalStyles}
        contentLabel="Crop Image"
      >
        <div style={modalStyles.cropperContainer}>
          <Cropper
            image={selectedImage}
            crop={crop}
            zoom={zoom}
            aspect={17 / 12}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={(croppedArea, croppedAreaPixels) =>
              setCroppedAreaPixels(croppedAreaPixels)
            }
          />
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              p: 2,
              gap: 5,
            }}
          >
            <BasicButtons text="Cancel" onClick={handleCancel} />
            <BasicButtons text="Crop" onClick={handleCrop} loading={loading} />
          </Box>
        </div>
      </ReactModal>
    </DashboardLayout>
  );
};

export default EditTopic;
