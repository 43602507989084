import React, { useEffect, useState, useRef } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Button, Typography, Divider, Tooltip } from "@mui/material";
import PaginationTable from "../../../components/PaginationTable";
import { useNavigate } from "react-router-dom";
import BasicButtons from "../../../components/Button";
import AlertDialog from "../../../components/AlertDialog";
import "../../../scss/main.css";
import {
  listBanner,
  deleteDataHandler,
  getDataHandler,
} from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";
import typographyStyles from "../../../theme/Typography";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "../../../Modal/DeleteModal";
import Loading from "../../../components/Loading";
import DataNotFound from "../../../components/DataNotFound";

const columns = [
  { id: "sr_no", label: "Sr.No." },
  { id: "banner_title", label: "Banner Title" },
  { id: "created_date_time", label: "Created Date & Time" },
  { id: "action", label: "Action" },
];

const ListCardsBanners = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [bannerData, setBannerData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch data when component mounts
    getDataHandler("getAllstaticContentByType", { contentType: "CARDSBANNER" })
      .then((response) => {
        if (response?.responseCode === 200) {
          setBannerData(response.result); // Assuming response.result contains the list of banners
        } else {
          toast.error(response?.responseMessage || "Failed to fetch banners");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error in fetching data", error);
        toast.error("Failed to fetch banners");
        setLoading(false);
      });
  }, []);

  const handleView = (cardData) => {
    navigate(`/staticcontent/cardsBanner/viewCardsBanner`, {
      state: { cardData },
    });
  };

  const handleEdit = (cardData) => {
    navigate(`/staticcontent/cardsBanner/editCardsBanner`, {
      state: { cardData },
    });
  };

  const handleClickOpen = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  const handleDeleteConfirm = async () => {
    if (!selectedRow) return;

    try {
      const response = await deleteDataHandler("deleteStaticContent", {
        staticContentId: selectedRow._id,
      });
      if (response?.responseCode === 200) {
        toast.success("Banner deleted successfully");
        setBannerData(
          bannerData.filter((item) => item._id !== selectedRow._id)
        );
      } else {
        toast.error(response?.responseMessage || "Failed to delete banner");
      }
    } catch (error) {
      console.error("Error deleting banner", error);
      toast.error("Failed to delete banner");
    } finally {
      setOpen(false);
    }
  };

  return (
    <DashboardLayout
      sectionName="Static Content Management"
      title="Banner Management"
    >
      <Box className="heading-container-top">
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Box>
              <img
                src={backBtn}
                alt="backBtn"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/staticcontent")}
              />
            </Box>
            <Typography sx={{ ...typographyStyles.h4 }}>
              Card Banner Management
            </Typography>
          </Box>
        </Box>

        <Box className="button-container">
          <BasicButtons
            text="+Add Card Banner"
            onClick={() => navigate("/staticcontent/cardsBanner/addCardBanner")}
            disabled={bannerData.length >= 4} // Disable the button if there are 4 or more banners
          />
        </Box>
      </Box>
      <br />
      <Divider />
      <Box className="StaticScreenContainer">
        <Box>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <Loading/> 
            </Box>
          ) : bannerData?.length < 1 ? (
            <DataNotFound />
          ) : (
            <PaginationTable
              columns={columns}
              data={bannerData.map((item, index) => ({
                sr_no: index + 1,
                banner_title: item.title,
                created_date_time: moment(item.createdAt).format(
                  "MM/DD/YYYY, h:mm A"
                ),
                action: (
                  <Box className="row-button-container">
                    <VisibilityIcon
                      variant="outlined"
                      onClick={() => handleView(item)}
                      sx={{ color: "rgb(225,140,168)", cursor: "pointer" }}
                    />
                    <EditIcon
                      variant="outlined"
                      onClick={() => handleEdit(item)}
                      sx={{ color: "rgb(225,140,168)", cursor: "pointer" }}
                    />
                    <DeleteIcon
                      variant="outlined"
                      onClick={() => handleClickOpen(item)}
                      sx={{ color: "rgb(225,140,168)", cursor: "pointer" }}
                    />
                  </Box>
                ),
              }))}
            />
          )}
        </Box>
      </Box>
      {open && (
        <DeleteModal
          handleClose={handleClose}
          handleDelete={handleDeleteConfirm}
          name="Delete Item?"
        />
      )}
    </DashboardLayout>
  );
};

export default ListCardsBanners;
