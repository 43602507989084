import React, { useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
  Modal,
  TextField,
  Grid,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";
import {
  postDataHandler,
  postDataHandlerWithToken,
} from "../../../apiconfig/service";
import BasicButtons from "../../../components/Button";

const addEditStyle = {
  addEditLabel: {
    fontWeight: "bold",
  },
  addEditInfo: {
    fontWeight: "normal",
  },
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ViewTicket = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const contactUs = location.state?.contactUs || null;
  const [open, setOpen] = useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleReply = async () => {
    try {
      const response = await postDataHandlerWithToken("sendEmail", {
        contactUsId: contactUs?._id, // Ensure _id or the appropriate identifier is available
        subject,
        message,
        email: contactUs?.email,
      });
      if (response?.responseCode === 200) {
        toast.success("Reply sent successfully.");
        setSubject("");
        setMessage("");
        handleClose();
        navigate("/ticketListing");
      } else {
        toast.error(response?.responseMessage || "Failed to send reply");
      }
    } catch (error) {
      console.error("Error sending reply:", error);
      toast.error("Failed to send reply.");
    }
  };

  if (!contactUs) {
    return (
      <DashboardLayout sectionName="Ticket detail" title="Contact Us Not Found">
        <Box sx={{ padding: "2rem" }}>
          <Typography variant="h5">Contact Us data not found</Typography>
        </Box>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout
      sectionName="Contact Us Management"
      title="View Contact Us"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography variant="h4">View Ticket </Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <Box className="add-edit-content-section">
          <Box className="add-edit-content">
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>Name:</TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {contactUs?.fullName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>Email:</TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {contactUs?.email}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>Phone:</TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {contactUs?.phoneNumber}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Status:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {contactUs?.status}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Message:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {contactUs?.message}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Solution:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {contactUs?.solution || "Not replied"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BasicButtons
                width={"40%"}
                text={"Reply"}
                onClick={handleOpen}
                disabled={contactUs?.status == "closed" ? true : false}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="reply-modal-title"
        aria-describedby="reply-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="reply-modal-title" variant="h6" component="h2">
            Reply to Contact Us
          </Typography>
          <Grid container spacing={2} mt={2}>
            <Grid item xs={12}>
              <TextField
                label="Subject"
                fullWidth
                variant="outlined"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Message"
                fullWidth
                variant="outlined"
                multiline
                rows={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} mt={2}>
              <Button
                variant="contained"
                sx={{ backgroundColor: "rgb(225, 140, 168)" }}
                onClick={handleReply}
              >
                Reply
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleClose}
                sx={{ ml: 2 }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </DashboardLayout>
  );
};

export default ViewTicket;
