import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import "../../../scss/main.css";
import typographyStyles from "../../../theme/Typography";
import { useNavigate, useLocation } from "react-router-dom";
import { getDataHandlerWithToken } from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
  addEditInfo: {
    ...typographyStyles.h6,
    fontWeight: "300",
  },
};

const durationOptions = [
  { key: "Monthly", value: "MONTHLY" },
  { key: "6 Months", value: "SIXMONTH" },
  { key: "Yearly", value: "YEARLY" },
];

const ViewSubscription = () => {
  const [subscriptionData, setSubscriptionData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const fetchProgram = async () => {
    const query = {
      subscriptionId: location.state?.subscriptionId,
    };
    try {
      const res = await getDataHandlerWithToken("viewSubscription", query);
      console.log(res?.result);
      if (res.responseCode === 200) {
        setSubscriptionData(res?.result);
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProgram();
  }, []);

  const getDurationKey = (value) => {
    const option = durationOptions.find((option) => option.value === value);
    return option ? option.key : value;
  };

  return (
    <DashboardLayout
      sectionName="Content - Program Management"
      title="Program General Details"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{
                cursor: "pointer",
              }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>View Subscription</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <Box className="add-edit-content-section">
          <Box className="add-edit-content">
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>Subscription name:</TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {subscriptionData?.subscriptionName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Subscription Duration:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {getDurationKey(subscriptionData?.subscriptionDuration)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Price:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {subscriptionData?.subscriptionPrice} $
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Discount:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {subscriptionData?.subscriptionDiscount} %
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Stripe Price Id:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {subscriptionData?.stripePriceId}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Creation Date:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {new Date(subscriptionData?.createdAt).toLocaleString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        }
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default ViewSubscription;
