import React, { useContext, useEffect, useState, useRef } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Button, Divider, Tooltip, Typography } from "@mui/material";
import FilterBox from "../../../components/FilterBox";
import BasicButtons from "../../../components/Button";
import PaginationTable from "../../../components/PaginationTable";
import "../../../scss/main.css";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import typographyStyles from "../../../theme/Typography";
import LoadingPage from "../LoadingPage/LoadingPage";
import Loading from "../../../components/Loading";

import VisibilityIcon from "@mui/icons-material/Visibility";

import {
  getDataHandlerWithToken,
  listCategory,
  putDataHandlerWithToken,
} from "../../../apiconfig/service";
import { saveAs } from "file-saver";
import BlockModal from "../../../Modal/BlockModal";
import toast from "react-hot-toast";
import DataNotFound from "../../../components/DataNotFound";

const columns = [
  { id: "sr_no", label: "Sr.No." },
  { id: "service_name", label: "Service Name" },
  { id: "type", label: "Type" },
  { id: "created_date_time", label: "Created Date & Time" },
  { id: "action", label: "Action" },
];

const se = [
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum (0-6 Weeks After Birth)", value: "POSTPARTUM" },
  {
    key: "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
    value: "BEYOND",
  },
];

const ListServiceAndSpecialization = () => {
  const tableRef = useRef(null);
  const { isAuthenticated, user } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false); // State to trigger re-render
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const [blockId, setBlockId] = useState(null);
  const [status, setStatus] = useState();
  const [name, setName] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(Number.MAX_SAFE_INTEGER);
  const [totalDocs, setTotalDocs] = useState("");

  useEffect(() => {
    const fetchServices = async () => {
      if (isAuthenticated && user) {
        try {
          const params = {
            page: page + 1,
            limit: rowsPerPage,
          };
          console.log(params);
          const response = await getDataHandlerWithToken(
            "getAllServicesOrSpecializations",
            params
          );

          setData(response.result.docs);
          // console.log(response.result.docs);
          setTotalDocs(response.result.totalDocs);
          setFilteredData(response.result.docs); // Initially, all data is filtered data

          setLoading(false);
        } catch (error) {
          console.error("Error fetching categories:", error);
          setLoading(false);
        }
      }
    };

    fetchServices();
  }, [isAuthenticated, user, toggle, page, rowsPerPage]);

  const handleEdit = (id) => {
    navigate(`/serviceAndSpecialization/editServiceAndSpecialization/${id}`);
  };
  const handleBlock = (id, name, status) => {
    setBlockId(id);
    setStatus(status == "ACTIVE" ? "BLOCK" : "ACTIVE");
    setName(name);
    setBlockModalOpen(true);
  };

  const handleBlockConfirm = async () => {
    let id = blockId;
    try {
      const res = await putDataHandlerWithToken(
        "blockUnblockServiceAndSpecialization",
        {},
        { id }
      );
      if (res.responseCode === 200) {
        setToggle((prevToggle) => !prevToggle);
        setBlockModalOpen(false);
        console.log("blocked");
        toast.success(res.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleBlockCancel = () => {
    setBlockModalOpen(false);
  };

  const handleFilterChange = (filterCriteria) => {
    let filtered = data;
    console.log(filterCriteria);

    if (filterCriteria.searchText) {
      filtered = filtered.filter((services) =>
        services.name
          .toLowerCase()
          .includes(filterCriteria.searchText.toLowerCase().trim())
      );
      console.log("filtered: ", filtered);
    }

    const parseCustomDate = (romDate) => {
      const year = romDate.$y;
      const month = romDate.$M; // Months are 0-indexed in JavaScript Date
      const day = romDate.$D;
      const hours = romDate.$H;
      const minutes = romDate.$m;
      const seconds = romDate.$s;
      return new Date(year, month, day, hours, minutes, seconds);
    };

    if (filterCriteria.fromDate) {
      const fromDate = parseCustomDate(filterCriteria.fromDate);
      console.log("fromDate:", fromDate, " (", fromDate.toISOString(), ")");

      filtered = filtered.filter((services) => {
        const createdAtDate = new Date(services.createdAt);
        return createdAtDate >= fromDate;
      });

      console.log("Filtered categories:", filtered);
    }

    if (filterCriteria.toDate) {
      const toDate = parseCustomDate(filterCriteria.toDate);
      console.log(toDate);

      filtered = filtered.filter((services) => {
        const createdAtDate = new Date(services.createdAt);
        return createdAtDate <= toDate;
      });

      console.log("Filtered categories:", filtered);
    }

    if (
      filterCriteria.selectedValue &&
      filterCriteria.selectedValue !== "All"
    ) {
      console.log(filterCriteria.selectedValue);
      filtered = filtered.filter(
        (category) => category.stage === filterCriteria.selectedValue
      );
    }

    setFilteredData(filtered);
    console.log(filteredData);
  };

  const downloadCSV = () => {
    const table = tableRef.current;
    const rows = Array.from(table.querySelectorAll("tr"));

    const csvData = rows
      .map((row) => {
        const cols = Array.from(row.querySelectorAll("td, th"));
        return cols.map((col) => col.textContent).join(",");
      })
      .join("\n");

    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "table_data.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handlePageChange = (newPage, newRowsPerPage) => {
    setPage(newPage);
    setRowsPerPage(newRowsPerPage);
  };

  return (
    <DashboardLayout>
      <Box className="category-container">
        <Box className="heading-container-top">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ ...typographyStyles.h4 }}>
              Service and Specialization
            </Typography>
          </Box>

          
          <Box className="button-container">
            <Box className="button-sub-container">
              <BasicButtons text="Download CSV" onClick={downloadCSV} />
              <BasicButtons
                text="+Add Services"
                onClick={() =>
                  navigate(
                    "/serviceAndSpecialization/addServiceAndSpecialization"
                  )
                }
              />
            </Box>
          </Box>
        </Box>
        <Divider />

        <Box className="filter-container">
          <FilterBox
            placeholder="Search by service name"
            onFilterChange={handleFilterChange}
            dropdownNone={"none"}
          />
        </Box>

        <Box className="table-container">
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh", // Adjust height as needed
              }}
            >
              <Loading />
            </Box>
          ) : filteredData?.length < 1 ? (
            <DataNotFound />
          ) : (
            <PaginationTable
              ref={tableRef}
              columns={columns}
              totalDocs={totalDocs}
              data={filteredData.map((row, index) => ({
                sr_no: index + 1,
                service_name: (
                  <Tooltip title={row.name} arrow>
                    <span>
                      {row.name.length > 20
                        ? `${row.name.substring(0, 20)}...`
                        : row.name}
                    </span>
                  </Tooltip>
                ),
                type: (
                  <Tooltip title={row.type} arrow>
                    <span>
                      {row.type.length > 20
                        ? `${row.type.substring(0, 20)}...`
                        : row.type}
                    </span>
                  </Tooltip>
                ),

                created_date_time: new Date(row.createdAt).toLocaleString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",

                    hour12: true,
                  }
                ),
                action: (
                  <Box className="row-button-container" sx={{ gap: "15%" }}>
                    {/* <VisibilityIcon
                    variant="outlined"
                    // onClick={() => handleView(obj._id)}
                    sx={{
                      color: "rgb(225,140,168)",
                      borderColor: "rgb(225,140,168)",
                      cursor: "pointer",
                    }}
                  /> */}
                    <EditIcon
                      onClick={() => handleEdit(row._id)}
                      sx={{
                        color: "rgb(225,140,168)",
                        borderColor: "rgb(225,140,168)",
                        fontFamily: "'Poppins', sans-serif",
                        cursor: "pointer",
                      }}
                    />
                    <BlockIcon
                      onClick={() => handleBlock(row._id, row.name, row.status)}
                      sx={{
                        color: row.status == "ACTIVE" ? "green" : "red",
                        borderColor: "rgb(225,140,168)",
                        fontFamily: "'Poppins', sans-serif",
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                ),
              }))}
              onPageChange={handlePageChange}
            />
          )}
        </Box>
      </Box>
      {blockModalOpen && (
        <BlockModal
          title="CATEGORY"
          status={status}
          name={name}
          onClose={handleBlockCancel}
          onBlock={handleBlockConfirm}
        />
      )}
    </DashboardLayout>
  );
};

export default ListServiceAndSpecialization;
