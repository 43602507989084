import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Button, Tooltip, Typography, Divider } from "@mui/material";

import FilterBox from "../../../components/FilterBox";
import BasicButtons from "../../../components/Button";
import PaginationTable from "../../../components/PaginationTable";

import "../../../scss/main.css";
import { useNavigate } from "react-router-dom";
import {
  getDataHandlerWithToken,
  putDataHandlerWithToken,
} from "../../../apiconfig/service";
import toast from "react-hot-toast";
import { saveAs } from "file-saver";
import BlockModal from "../../../Modal/BlockModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import typographyStyles from "../../../theme/Typography";
import LoadingPage from "../LoadingPage/LoadingPage";
import Loading from "../../../components/Loading";
import DataNotFound from "../../../components/DataNotFound";

const columns = [
  { id: "sr_no", label: "Sr.No." },
  { id: "recipe_name", label: "Recipe Name" },
  { id: "created_date_time", label: "Created Date & Time" },
  { id: "status", label: "Status" },
  { id: "action", label: "Action" },
];

const RecipeListing = () => {
  const Navigate = useNavigate();
  const [recipeList, setRecipeList] = useState([]);
  const [render, setRender] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const [blockId, setBlockId] = useState(null);
  const [status, setStatus] = useState();
  const [name, setName] = useState();
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      const query = {
        limit: Number.MAX_SAFE_INTEGER,
      };
      const res = await getDataHandlerWithToken("getrecipeList", query);
      if (res.responseCode === 200) {
        setRecipeList(res?.result?.docs || []);
        setFilteredData(res?.result?.docs);
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.error("Error fetching recipe list:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [render]);

  const handleBlock = (id, name, status) => {
    setBlockId(id);
    setStatus(status == "ACTIVE" ? "BLOCK" : "ACTIVE");
    setName(name);
    setBlockModalOpen(true);
  };

  const handleBlockConfirm = async () => {
    const id = blockId;
    try {
      const query = { recipeId: id };
      const res = await putDataHandlerWithToken("blockrecipe", {}, query);
      if (res.responseCode === 200) {
        // Update recipeList with the updated status
        const updatedRecipeList = recipeList.map((program) =>
          program._id === id ? { ...program, status: status } : program
        );
        setRecipeList(updatedRecipeList);
        setFilteredData(updatedRecipeList); // Trigger re-render if necessary (depending on your use case)
        setBlockModalOpen(false);
        toast.success("Recipe status updated successfully");
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.error("Error blocking recipe:", error);
    }
  };
  const handleBlockCancel = () => {
    setBlockModalOpen(false);
  };
  const handleView = (id) => {
    // Handle view action
    console.log("Block button clicked for row id:", id);
    Navigate(`/recipelisting/viewrecipe`, { state: { recipeId: id } });
  };

  const handleEdit = (id) => {
    // Handle edit action
    Navigate(`/recipelisting/editrecipe`, { state: { recipeId: id } });
  };
  const handleFilterChange = (filterCriteria) => {
    let filtered = recipeList;
    console.log(filterCriteria);

    if (filterCriteria.searchText) {
      filtered = filtered.filter((category) =>
        category.recipeName
          .toLowerCase()
          .includes(filterCriteria.searchText.toLowerCase().trim())
      );
      console.log("filtered: ", filtered);
    }

    const parseCustomDate = (romDate) => {
      const year = romDate.$y;
      const month = romDate.$M; // Months are 0-indexed in JavaScript Date
      const day = romDate.$D;
      const hours = romDate.$H;
      const minutes = romDate.$m;
      const seconds = romDate.$s;
      return new Date(year, month, day, hours, minutes, seconds);
    };

    if (filterCriteria.fromDate) {
      const fromDate = parseCustomDate(filterCriteria.fromDate);
      console.log("fromDate:", fromDate, " (", fromDate.toISOString(), ")");

      filtered = filtered.filter((category) => {
        const createdAtDate = new Date(category.createdAt);
        return createdAtDate >= fromDate;
      });

      console.log("Filtered categories:", filtered);
    }

    if (filterCriteria.toDate) {
      const toDate = parseCustomDate(filterCriteria.toDate);
      console.log(toDate);

      filtered = filtered.filter((category) => {
        const createdAtDate = new Date(category.createdAt);
        return createdAtDate <= toDate;
      });

      console.log("Filtered categories:", filtered);
    }

    if (
      filterCriteria.selectedValue &&
      filterCriteria.selectedValue !== "All"
    ) {
      console.log(filterCriteria.selectedValue);
      filtered = filtered.filter(
        (category) => category.stage.includes(filterCriteria.selectedValue)
      );
    }

    setFilteredData(filtered);
    console.log(filteredData);
  };
  const handleDownloadCSV = () => {
    // Remove _id field from data and extract headers
    const dataWithoutId = recipeList?.map(({ _id, ...rest }) => rest);
    const headers = Object.keys(dataWithoutId[0]);

    // Create CSV content with headers
    let csvContent = headers.join(",") + "\n";

    // Append rows of data
    csvContent += dataWithoutId
      ?.map((row) => headers?.map((header) => row[header]).join(","))
      .join("\n");

    // Create a Blob object representing the data as a CSV file
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;

    // Create the filename
    const filename = `RecipeListing_${formattedDate}.csv`;

    // Use FileSaver.js to save the Blob as a file
    saveAs(blob, filename);
  };
  console.log(filteredData);
  return (
    <DashboardLayout
      sectionName="Content - Recipe Management"
      showBackButtonSection={false}
    >
      <Box className="category-container">
        <Box className="heading-container-top">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ ...typographyStyles.h4 }}>
              Recipe Listing
            </Typography>
          </Box>

          
          <Box className="button-container">
            <Box className="button-sub-container">
              <BasicButtons text="Download CSV" onClick={handleDownloadCSV} />
              <BasicButtons
                text="+Add Recipe"
                onClick={() => Navigate("/recipelisting/addrecipe")}
              />
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box className="filter-container">
          <FilterBox
            placeholder="Enter your search query"
            onFilterChange={handleFilterChange}
          />
        </Box>

        <Box className="table-container">
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh", // Adjust height as needed
              }}
            >
              <Loading />
            </Box>
          ) : filteredData?.length < 1 ? (
            <DataNotFound />
          ) : (
            <PaginationTable
              columns={columns}
              data={filteredData?.map((row, index) => ({
                sr_no: index + 1,
                recipe_name: (
                  <Tooltip title={row.recipeName} arrow>
                    <span>
                      {row.recipeName.length > 20
                        ? `${row.recipeName.substring(0, 20)}...`
                        : row.recipeName}
                    </span>
                  </Tooltip>
                ),
                created_date_time: new Date(row.createdAt).toLocaleString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",

                    hour12: true,
                  }
                ),
                status: row.status,
                action: (
                  <Box className="row-button-container" sx={{ gap: "15%" }}>
                    <VisibilityIcon
                      onClick={() => handleView(row._id)}
                      sx={{
                        color: "rgb(225,140,168)",
                        borderColor: "rgb(225,140,168)",
                        cursor: "pointer",
                      }}
                    />

                    <EditIcon
                      onClick={() => handleEdit(row._id)}
                      sx={{
                        color: "rgb(225,140,168)",
                        borderColor: "rgb(225,140,168)",
                        fontFamily: "'Poppins', sans-serif",
                        cursor: "pointer",
                      }}
                    />
                    <BlockIcon
                      onClick={() =>
                        handleBlock(row._id, row.recipeName, row.status)
                      }
                      sx={{
                        color: row.status == "ACTIVE" ? "green" : "red",
                        borderColor: "rgb(225,140,168)",
                        fontFamily: "'Poppins', sans-serif",
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                ),
              }))}
            />
          )}
        </Box>
      </Box>
      {blockModalOpen && (
        <BlockModal
          title="RECIPE"
          status={status}
          name={name}
          onClose={handleBlockCancel}
          onBlock={handleBlockConfirm}
        />
      )}
    </DashboardLayout>
  );
};

export default RecipeListing;
