import axios from "axios";
import ApiConfig from "./apiconfig";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
// export const history = useHistory();
export const postDataHandler = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      data: data,
    });

    if (res?.data?.responseCode === 200) {
      return res.data;
    } else {
      toast.error(res?.data?.message);
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return error;
    }
  }
};

export const getDataHandlerWithToken = async (endPoint, query, data) => {
  try {
    const res = await axios({
      method: "GET",
      url: ApiConfig[endPoint],
      headers: {
        token: sessionStorage.getItem("adminToken"),
      },
      params: query,
      data: data,
    });

    if (res?.data?.responseCode === 200) {
      return res.data;
    } else {
      toast.error(res?.data?.message);
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return error;
    }
  }
};

export const postDataHandlerWithToken = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      headers: {
        token: sessionStorage.getItem("adminToken"),
      },
      data: data,
    });
    if (res?.data?.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const putDataHandler = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      data: data,
    });
    if (res.data.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
    if (error.response) {
      return error.response;
    }
  }
};

export const putDataHandlerWithToken = async (endPoint, data, params) => {
  try {
    const res = await axios({
      method: "PUT",
      url: ApiConfig[endPoint],
      headers: {
        token: sessionStorage.getItem("adminToken"),
      },
      data: data,
      params: params,
    });
    if (res.data.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
    if (error.response) {
      return error.response;
    }
  }
};

export const deleteDataHandler = async (endPoint, query) => {
  try {
    const res = await axios({
      method: "DELETE",
      url: ApiConfig[endPoint],
      headers: {
        token: sessionStorage.getItem("adminToken"),
      },
      params: query,
    });
    if (res.data.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

export const patchDataHandler = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "PATCH",
      url: ApiConfig[endPoint],
      data: data,
    });
    if (res.data.responseCode === 200) {
      toast.success(res.data.responseMessage);
      return res.data;
    } else {
      toast.error(res.data.responseMessage);
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
    console.log(error);
  }
};

export const patchTokenDataHandler = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "PUT",
      url: ApiConfig[endPoint],
      headers: {
        token: sessionStorage.getItem("adminToken"),
      },
      data: data,
    });
    if (res.data.responseCode === 200) {
      return res.data;
    } else {
      toast.error(res?.data?.responseMessage);
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
    console.log(error);
  }
};

export const listCategory = async (params) => {
  try {
    const res = await axios({
      method: "GET",
      url: ApiConfig.listCategory,
      headers: {
        token: sessionStorage.getItem("adminToken"),
      },
      params: params,
    
    });

    if (res?.data?.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const getCategoryWithToken = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "GET", // Adjusted to GET method
      url: `${ApiConfig[endPoint]}?categoryId=${data.categoryId}`,
      headers: {
        token: sessionStorage.getItem("adminToken"),
      },
    });
    if (res?.data?.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};
export const getDataHandler = async (endPoint, query) => {
  try {
    const res = await axios({
      method: "GET",
      url: ApiConfig[endPoint],
      params: query,
    });

    if (res?.data?.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};
export const listBanner = async (endPoint,query) => {
  try {
    const res = await axios({
      method: "GET",
      url: ApiConfig[endPoint],
      params:query,

      headers: {
        token: sessionStorage.getItem("adminToken"),
      },
    });

    if (res?.data?.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};
