import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Typography } from "@mui/material";
import BlockIcon from '@mui/icons-material/Block';
import BasicButtons from './Button';
import DeleteIcon from "@mui/icons-material/Delete";


const dialogStyles = {
  dialogPaper: {
    padding: "30px",
    maxWidth: "500px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
  },
  dialogIcon: {
    color: "red",
    fontSize: "100px",
  },
  dialogTitle: {
    fontSize: "32px",
    fontWeight: "700",
    fontFamily: "'Poppins', sans-serif",
    textAlign: "center",
  },
  dialogContentText: {
    fontSize: "16px",
    fontWeight: "500",
    fontFamily: "'Poppins', sans-serif",
    wordBreak: "break-word",
    overflowWrap: "break-word",
    textAlign: "center",
  },
  dialogActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "2%",
    width: "100%",
  },
};

export default function AlertDialog({ open, handleClose, handleDelete, title, description }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: dialogStyles.dialogPaper,
      }}
    >
      <Box>
        <DeleteIcon sx={dialogStyles.dialogIcon} />
      </Box>
      <DialogTitle id="alert-dialog-title" sx={dialogStyles.dialogTitle}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={dialogStyles.dialogContentText}>
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={dialogStyles.dialogActions}>
      <BasicButtons text="Yes"onClick={handleDelete} autoFocus/>
        <BasicButtons  text="No"onClick={handleClose}/>
       
        
      </DialogActions>
    </Dialog>
  );
}
