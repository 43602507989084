import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Typography, Divider } from "@mui/material";
import { getDataHandler } from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";
import typographyStyles from "../../../theme/Typography";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";

const PrivacyPolicy = () => {
  const [privacyPolicyData, setPrivacyPolicyData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch data when component mounts
    getDataHandler("getAllstaticContentByType", { contentType: "PRIVACYPOLICY" })
      .then((response) => {
        if (response?.responseCode === 200) {
          setPrivacyPolicyData(response.result[0]); // Set the entire response array
        } else {
          toast.error(response?.responseMessage || "Failed to fetch Privacy Policy");
        }
      })
      .catch((error) => {
        console.error("Error in fetching data", error);
        toast.error("Failed to fetch Privacy Policy");
      });
  }, []);

  return (
    <DashboardLayout sectionName="Static Content Management" title="Privacy Policy">
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{
                cursor: "pointer",
              }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>
            Privacy Policy
          </Typography>
        </Box>
      </Box>
      <Divider />

      <Box className="StaticScreenContainer" >
        <Box
          className="StaticScreenContent"
          sx={{
            border: "1px solid #ddd",
            borderRadius: "8px",
            padding: "16px",
            backgroundColor: "#f9f9f9",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          {privacyPolicyData ? (
            <Typography
            
            
            
            
            sx={{
              "& ul, & ol": {
                paddingLeft: "20px", // Indent lists
                marginBottom: "16px", // Add spacing below lists
              },
              "& li": {
                lineHeight: "1.5", // Adjust line height for readability
                marginBottom: "8px", // Add spacing between list items
              },
            }}
          >
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(privacyPolicyData.description),
                }}
              />
            </Typography>
          ) : (
            <Typography variant="body1">Loading Privacy Policy...</Typography>
          )}
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default PrivacyPolicy;

