import { Box } from "@mui/material";
import React, { useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Sidebar from "../../components/Sidebar";
import typographyStyles from "../../theme/Typography";
import LogoutModal from "../../Modal/LogoutModal";
import { useNavigate } from "react-router-dom";

const dashboardLayoutStyle = {
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh', // Ensure full viewport height
    },

    headerSection: {
        position: "sticky",
        top: 0,
        zIndex: 1000,
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },

    footerSection: {
        position: "sticky",
        left: '0',
        right: '0',
        bottom: '0',
        zIndex: 1000,
        backgroundColor: 'white',
    },

    contentSection: {
        display: 'flex',
        flex: 1,
        overflow: 'hidden',
    },

    sidebarStyle: {
        width: '20%',
        height: 'calc(100vh - 64px - 64px)', // Adjust the height based on header and footer height
        boxShadow: '0 10px 10px -10px #D9D9D9, 10px 0 10px -10px #D9D9D9',
        overflowY: 'auto',
        scrollbarWidth: 'none', // For Firefox
        '&::-webkit-scrollbar': { // For Webkit browsers
            display: 'none'
        }
    },

    contentStyle: {
        width: '80%',
        padding: '16px 2%',
        margin: '12px',
        height: 'calc(100vh - 64px - 64px)', // Adjust the height based on header and footer height
        boxShadow: '0 0 10px 0 #D9D9D9',
        overflowY: 'auto',
        scrollbarWidth: 'none', // For Firefox
        '&::-webkit-scrollbar': { // For Webkit browsers
            display: 'none'
        }
    }
};

const DashboardLayout = ({ children, sectionName, showBackButtonSection, title }) => {
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

    const handleLogoutClick = () => {
        setIsLogoutModalOpen(true);
    };

    const handleCloseLogoutModal = () => {
        setIsLogoutModalOpen(false);
    };

    const navigate = useNavigate();

    return (
        <Box sx={dashboardLayoutStyle.mainContainer}>
            <Box sx={dashboardLayoutStyle.headerSection}><Header /></Box>
            <Box sx={dashboardLayoutStyle.contentSection}>
                <Box sx={dashboardLayoutStyle.sidebarStyle}>
                    <Sidebar onLogoutClick={handleLogoutClick} />
                    {isLogoutModalOpen && <LogoutModal onClose={handleCloseLogoutModal} />}
                </Box>
                    <Box sx={dashboardLayoutStyle.contentStyle}>
                    {children}
                </Box>
            </Box>
            <Box sx={dashboardLayoutStyle.footerSection}><Footer /></Box>
        </Box>
    );
};

DashboardLayout.defaultProps = {
    showBackButtonSection: true,
};

export default DashboardLayout;
