import React, { useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";

import LoginLayout from "../../../layouts/LoginLayout/LoginLayout";
import typographyStyles from "../../../theme/Typography";
import BasicButtons from "../../../components/Button";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { postDataHandler } from "../../../apiconfig/service";
import { resolveValue, toast } from "react-hot-toast";
import "../../../scss/main.css";

const authStyle = {
  authHeading: {
    textAlign: "center",
    ...typographyStyles.h3,
  },

  authSubHeading: {
    ...typographyStyles.h6,
    color: "#0009",
    textAlign: "center",
  },

  authLabel: {
    ...typographyStyles.h6,
  },

  forBack: {
    ...typographyStyles.h6,
    color: "#3996d2",
    textAlign: "center",
    cursor: "pointer",
  },

  otpInputContainer: {
    // border: '1px solid blue',
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "2%",
  },

  otpInput: {
    height: "70px",
    width: "12%",
    borderRadius: "10px",
    fontSize: "30px",
    textAlign: "center",
    border: "1px solid rgba(225, 225, 225, 1)",
  },
};

const VerifyOTP = () => {
  const location = useLocation();

  const [Email, setEmail] = useState(location.state?.email || "");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const handleChange = (index, e) => {
    let { value } = e.target;
    if (value === "" && index > 0) {
      inputRefs[index - 1].current.focus();
    } else if (value.length === 1 && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };

  const Navigation = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const otpValue = inputRefs.map((ref) => ref.current.value).join("");
    const data = {
      email: Email,
      otp: otpValue,
    };
    console.log(data);
    try {
      setIsLoading(true);
      const res = await postDataHandler("verifyOTPAdmin", data);

      if (res.responseCode == 200) {
        // Navigation("/resetpassword", { replace: true },{sate:{email:Email}});
        Navigation("/resetpassword", { state: { email: Email } });
        toast.success(res?.responseMessage);
        setIsLoading(false);
      } else {
        setErrorMessage(res?.data?.message);
        toast.error(res?.data?.responseMessage);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [timeLeft, setTimeLeft] = useState(180); // Start from 60 seconds (1:00)
  const [timerActive, setTimerActive] = useState(true);

  useEffect(() => {
    inputRefs[0].current.focus();
  }, []);

  // Load remaining time from localStorage when the component mounts
  useEffect(() => {
    const savedTime = localStorage.getItem("otpTimeLeft");
    if (savedTime) {
      const parsedTime = JSON.parse(savedTime);
      setTimeLeft(parsedTime);
      if (parsedTime > 0) {
        setTimerActive(true); // Start timer if there's time left
      }
    }
  }, []);

  useEffect(() => {
    const storedTimer = localStorage.getItem("otpTimeLeft");
    if (storedTimer) {
      setTimeLeft(parseInt(storedTimer, 10));
    }
    let intervalId;
    if (timerActive) {
      intervalId = setInterval(() => {
        setTimeLeft((prevTimer) => {
          if (prevTimer === 0) {
            setTimerActive(false);

            clearInterval(intervalId);
            localStorage.removeItem("otpTimeLeft");
            return 180;
          } else {
            localStorage.setItem("otpTimeLeft", prevTimer - 1);
            return prevTimer - 1;
          }
        });
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [timerActive]);

  // Format the time as MM:SS
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes < 10 ? `0${minutes}` : minutes}:${
      secs < 10 ? `0${secs}` : secs
    }`;
  };

  const handleResendOTP = async () => {
    setIsLoading(true);
    const data = {
      email: Email,
    };
    const res = await postDataHandler("resendOTPAdmin", data);

    if (res?.responseCode === 200) {
      toast.success(res?.responseMessage);
      console.log(res);
      // auth.checkLogin(res?.token);
      // Clear the input fields
      inputRefs.forEach((ref) => {
        if (ref.current) {
          ref.current.value = "";
        }
      });

      // Reset focus to the first input
      inputRefs[0].current.focus();
      setTimerActive(true);
      setEmail(res?.result?.email.toLocaleLowerCase());
      setIsLoading(false);
    } else {
      setErrorMessage(res?.data?.responseMessage);
      toast.error(res?.data?.responseMessage);

      setIsLoading(false);
    }
  };

  return (
    <LoginLayout style={{ border: "2px solid red" }}>
      <Box
        className="authBox"
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <form className="authContainer" onSubmit={handleSubmit}>
          <Box className="authHeadingBox">
            <Typography style={authStyle.authHeading}>Verify OTP</Typography>
            <Typography style={authStyle.authSubHeading}>
              Please enter the 6 digits OTP sent on your registered email
              address.
            </Typography>
          </Box>
          <Box className="authContent">
            <Box className="authFields">
              <Typography variant="h6" style={authStyle.authLabel}>
                OTP
              </Typography>
              <Box style={authStyle.otpInputContainer}>
                {inputRefs.map((ref, index) => (
                  <input
                    key={index}
                    ref={ref}
                    style={authStyle.otpInput}
                    maxLength={1}
                    onKeyDown={(e) => {
                      const re = /^[0-9\b]+$/;
                      if (
                        e.key === "Backspace" &&
                        e.target.value === "" &&
                        index > 0
                      ) {
                        console.log("here");
                        inputRefs[index - 1].current.focus();
                      }
                      if (!re.test(e.key) && e.key !== "Backspace") {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => handleChange(index, e)}
                    required
                  />
                ))}
              </Box>
              {timerActive && (
                <div
                  className="timer-container"
                  style={{ textAlign: "end", color: "red" }}
                >
                  {formatTime(timeLeft)}
                </div>
              )}
            </Box>
          </Box>
          <Box className="authFields" sx={{ alignItems: "center" }}>
            <p
              className="admin-login-msg"
              style={{ textAlign: "center", marginBottom: "20px" }}
            >
              If you did't receive any OTP ?
              {timerActive ? (
                ` Resend OTP`
              ) : (
                <Link onClick={handleResendOTP} style={authStyle.forBack}>
                  {" "}
                  Resend OTP
                </Link>
              )}
            </p>
            <BasicButtons
              text="Verify OTP"
              name="login"
              type="submit"
              loading={isLoading}
              width={"35%"}
            />
          </Box>
          <Typography
            style={authStyle.forBack}
            onClick={() => {
              Navigation("/");
            }}
          >
            Back to login
          </Typography>
        </form>
      </Box>
    </LoginLayout>
  );
};

export default VerifyOTP;
