import React, { useState, useCallback } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  Card,
  CardMedia,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import typographyStyles from "../../../theme/Typography";
import BasicButtons from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import InputField from "../../../components/InputField";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { addCategorySchema } from "../../../schema";
import {
  postDataHandlerWithToken,
  postDataHandler,
} from "../../../apiconfig/service";
import "../../../scss/main.css";
import toast from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";
import Cropper from "react-easy-crop";
import ReactModal from "react-modal";
import { getCroppedImg } from "../../../utils/cropImage";
import { modalStyles } from "../../../scss/style";

// Styles for the modal and cropping area

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
  radioContainer: {
    display: "flex",
  },
};

const dropdownOptions = [
  "Trying to Conceive",
  "Pregnant",
  "Postpartum (0-6 Weeks After Birth)",
  "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
];
const se = [
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum (0-6 Weeks After Birth)", value: "POSTPARTUM" },
  {
    key: "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
    value: "BEYOND",
  },
];

const AddCategory = () => {
  const [thumbnailPreview, setThumbnailPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();

  const initialValues = {
    stage: "",
    categoryName: "",
    saveAs: "FREE",
    thumbnail: null,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: addCategorySchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("file", croppedImage);

      try {
        setLoading(true);
        const result = await postDataHandler("uploadFile", formData);
        if (result.responseCode === 200) {
          const res = await postDataHandlerWithToken("addUpdateCategory", {
            categoryName: values.categoryName,
            stage: se.find((option) => option.key === selectedValue).value,
            saveAs: values.saveAs,
            image: result.result,
          });
          if (res.responseCode === 200) {
            toast.success(res.responseMessage);
            navigate("/category");
          } else {
            toast.error(res.data.responseMessage);
          }
        } else {
          toast.error("Error while uploading file");
        }
      } catch (error) {
        console.error("Failed to add category: ", error);
      } finally {
        setLoading(false);
      }
    },
  });

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    setFieldValue,
  } = formik;
  const [selectedValue, setSelectedValue] = useState("Select Stage");

  const handleDropdownChange = (value) => {
    setSelectedValue(value);
    setFieldValue("stage", value);
  };

  const handleSaveAsChange = (event) => {
    handleChange(event);
    setFieldValue("saveAs", event.target.value);
  };

  const handleThumbnailChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setIsModalOpen(true);
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCrop = useCallback(async () => {
    try {
      const croppedImageBlob = await getCroppedImg(
        selectedImage,
        croppedAreaPixels
      );
      setCroppedImage(croppedImageBlob);
      setThumbnailPreview(URL.createObjectURL(croppedImageBlob));
      setFieldValue("thumbnail", croppedImageBlob);
      setIsModalOpen(false);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, selectedImage, setFieldValue]);
  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedImage(null); // Clear the selected image
    setCroppedImage(null); // Ensure cropped image is not set
    setThumbnailPreview(null); // Clear the preview
    setFieldValue("thumbnail", null); // Clear Formik field value
  };

  return (
    <DashboardLayout sectionName="Category Management" title="Add Category">
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>Add Category</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <form className="add-edit-content-section" onSubmit={handleSubmit}>
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Select Stage:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <Dropdown
                  name="stage"
                  onBlur={handleBlur}
                  sx={addEditStyle.categoryInputField}
                  options={dropdownOptions}
                  value={selectedValue}
                  onChange={handleDropdownChange}
                />
                {errors.stage && touched.stage && (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.stage}
                  </p>
                )}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Category Name:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="categoryName"
                  value={values.categoryName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={50}
                  placeholder="Enter Category Name"
                />
                {errors.categoryName && touched.categoryName && (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.categoryName}
                  </p>
                )}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>Save As:</Typography>
              </Box>
              <Box className="add-edit-input-field">
                <FormControl>
                  <RadioGroup
                    name="saveAs"
                    value={values.saveAs}
                    onChange={handleSaveAsChange}
                  >
                    <FormControlLabel
                      value="FREE"
                      control={<Radio />}
                      label="Free"
                    />
                    <FormControlLabel
                      className="radio"
                      value="LOCKED"
                      control={<Radio />}
                      label="Locked (will be accessed after subscription)"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Thumbnail:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="file"
                  name="thumbnail"
                  onChange={handleThumbnailChange}
                  onBlur={formik.handleBlur}
                  acceptedFile="image/*"
                />
                {formik.errors.thumbnail && formik.touched.thumbnail ? (
                  <p style={{ color: "red", marginTop: "5px", float: "left" }}>
                    {formik.errors.thumbnail}
                  </p>
                ) : null}

                {thumbnailPreview && (
                  <Box>
                    <Card
                      sx={{ maxWidth: 250, maxHeight: "auto", margin: "5px" }}
                    >
                      <CardMedia component="img" image={thumbnailPreview} />
                    </Card>
                  </Box>
                )}
              </Box>
            </Box>
            <Box
              className="add-edit-button-section"
              sx={{ alignItems: "center", justifyContent: "center" }}
            >
              <BasicButtons
                text="Add Category"
                name="submit"
                type="submit"
                disabled={loading}
                loading={loading}
                width={"20%"}
              />
            </Box>
          </Box>
        </form>
      </Box>
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={() => handleCancel()}
        style={modalStyles}
        contentLabel="Crop Image"
      >
        <div style={modalStyles.cropperContainer}>
          <Cropper
            image={selectedImage}
            crop={crop}
            zoom={zoom}
            aspect={17 / 12}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              p: 2,
              gap: 5,
            }}
          >
            <BasicButtons text="Cancel" onClick={handleCancel} />
            <BasicButtons text="Crop" onClick={handleCrop} />
          </Box>
        </div>
      </ReactModal>
    </DashboardLayout>
  );
};

export default AddCategory;
