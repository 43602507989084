import React, { useState, useCallback, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Typography, Divider, Card, CardMedia } from "@mui/material";
import typographyStyles from "../../../theme/Typography";
import InputField from "../../../components/InputField";
import BasicButtons from "../../../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import {
  postDataHandlerWithToken,
  postDataHandler,
  getDataHandlerWithToken,
} from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";
import Cropper from "react-easy-crop";
import ReactModal from "react-modal";

import { modalStyles } from "../../../scss/style";
import { cropAndUploadImage } from "../../../utils/cropAndUploadImage";

// Styles for the modal and cropping area
const addEditStyle = {
  container: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    flexWrap: "wrap",
  },
  inputContainer: {
    flex: 1,
  },
  imageContainer: {
    maxWidth: "250px",
    maxHeight: "250px",
    margin: "5px",
  },
};

const EditBanner = () => {
  const navigate = useNavigate();
  const{id}= useParams();

  const [thumbnailPreview, setThumbnailPreview] = useState(null);

  const [loading, setLoading] = useState(false);
  // States for cropping functionality
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
 

  const formik = useFormik({
    initialValues: {
      bannerTitle: "",
      categoryImage: null,
    },
    validationSchema: Yup.object({
      bannerTitle: Yup.string().required("Please enter the banner title."),
      categoryImage: Yup.mixed().required("Please upload the image."),
    }),
    onSubmit: async (values) => {
      try {
        // Upload image to Cloudinary

        const data = {
          bannerId: id,
          bannerName: values.bannerTitle,
          image: thumbnailPreview,
        };

        // Send data to backend
        const response = await postDataHandlerWithToken(
          "addUpdateBanner",
          data
        );

        // Handle success or show a message
        toast.success("Banner added successfully");
        navigate("/staticcontent/bannermanagement");
      } catch (error) {
        console.error("Error adding banner:", error);
        toast.error("Failed to add banner. Please try again later.");
      }
    },
  });
  useEffect(() => {
    // Fetch data when component mounts
    const fetchData = async () => {
      try {
        const response = await getDataHandlerWithToken("getBanner", {
          bannerId: id,
        });
        if (response?.responseCode === 200) {
          const content = response.result;
          formik.setValues({
            bannerTitle: content?.bannerName,
            categoryImage:content?.image
            
           
          });
          setThumbnailPreview(content?.image)
        } else {
          toast.error(response?.responseMessage || "Failed to fetch banner");
        }
      } catch (error) {
        console.error("Error in fetching data.", error);
        toast.error("Failed to fetch banner data.");
      }
    };

    fetchData();
  }, [id, formik.setValues]);

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    setFieldValue,
  } = formik;

  const handleThumbnailChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setIsModalOpen(true);
      setFieldValue("categoryImage", file);
    }
  };
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  // Image cropping functions
  const handleCrop = useCallback(async () => {
    setLoading(true)
    try {
      const cloudinaryUrl = await cropAndUploadImage(selectedImage, croppedAreaPixels);
      setCroppedImage(cloudinaryUrl);
      setSelectedImage(cloudinaryUrl)
      setThumbnailPreview(cloudinaryUrl)
      setIsModalOpen(false);
    } catch (e) {
      toast.error("Error while cropping or uploading image");
    }
    setLoading(false)
  }, [croppedAreaPixels, selectedImage]);

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
    setCroppedImage(null);
    setThumbnailPreview(null)
    
  };


  return (
    <DashboardLayout>
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>Edit Banner</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <form className="add-edit-content-section" onSubmit={handleSubmit}>
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Banner title:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  className="authTextField"
                  type="text"
                  name="bannerTitle"
                  placeholder="Enter the banner title"
                  value={values.bannerTitle}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={50}
                />
                {touched.bannerTitle && errors.bannerTitle && (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.bannerTitle}
                  </p>
                )}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Select Image:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="file"
                  name="categoryImage"
                  onChange={handleThumbnailChange}
                  onBlur={handleBlur}
                  acceptedFile="image/*"
                />
                {touched.categoryImage && errors.categoryImage && (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.categoryImage}
                  </p>
                )}
                {thumbnailPreview && (
                  <Box sx={addEditStyle.imageContainer}>
                    <Card>
                      <CardMedia component="img" image={thumbnailPreview} />
                    </Card>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box className="StaticBtnContainer" sx={{ marginBottom: 2 }}>
            <BasicButtons
              text="Edit"
              name="submit"
              type="submit"
              width={"20%"}
            />
          </Box>
        </form>
      </Box>
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={() => handleCancel()}
        style={modalStyles}
        contentLabel="Crop Image"
      >
        <div style={modalStyles.cropperContainer}>
          <Cropper
            image={selectedImage}
            crop={crop}
            zoom={zoom}
            aspect={17 / 12}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              p: 2,
              gap: 5,
            }}
          >
            <BasicButtons text="Cancel" onClick={handleCancel} />
            <BasicButtons text="Crop" onClick={handleCrop} loading={loading} />
          </Box>
        </div>
      </ReactModal>
    </DashboardLayout>
  );
};

export default EditBanner;
