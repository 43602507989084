import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import descLogo from "../assets/descLogo.png"

const Loading = () => {
  return (
    <Box sx={{  display: "flex", justifyContent: "center", alignItems: "center" }}>
      <CircularProgress size={170} thickness={2} sx={{ position: 'absolute', color: 'rgb(225, 140, 168)' }} />
      <img src={descLogo} alt="" />
    </Box>
  );
};

export default Loading;
