import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Card, CardMedia, Select as Selection } from "@mui/material";
import countriesData from "../../../utils/countries";
import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";

import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  IconButton,
  MenuItem,
} from "@mui/material";
import typographyStyles from "../../../theme/Typography";
import BasicButtons from "../../../components/Button";
import InputField from "../../../components/InputField";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { addConsultSchema } from "../../../schema";
import toast from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";
import Select from "react-select";
import Geocode from "react-geocode";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  getDataHandlerWithToken,
  postDataHandler,
  postDataHandlerWithToken,
} from "../../../apiconfig/service";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ImageCropper from "../../../utils/ImageCropper";

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
  
};

const AddCompany = () => {
  const specialisationOptions = [];
  const servicesOptions = [];
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("Select country");

  const navigate = useNavigate();
  const [coordinatesError, setCoordinatesError] = useState("");

  const [companyLogo, setCompanyLogo] = useState("");
  const [consultLogo, setConsultLogo] = useState("");
  const [selectedField, setSelectedField] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);




  const handleThumbnailChange = (event, fieldName) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setSelectedField(fieldName);
      setFieldValue(fieldName, file);
    }
  };

  const handleCropComplete = (croppedUrl) => {
    if (selectedField === "companyLogo") {
      setCompanyLogo(croppedUrl);
      
    } else if (selectedField === "consultLogo") {
      setConsultLogo(croppedUrl);
      
    }
    setSelectedImage(null);
  };

  const getServices = async () => {
    try {
      const res = await getDataHandlerWithToken(
        "getAllServicesOrSpecializations",
        { status: "ACTIVE" }
      );

      const data = res.result.docs;

      data.forEach((item) => {
        if (item.type === "SPECIALIZATION") {
          specialisationOptions.push({ value: item.name, label: item.name });
        } else if (item.type === "SERVICE") {
          servicesOptions.push({ value: item.name, label: item.name });
        }
      });

      // console.log("Specialisation Options: ", specialisationOptions);
      // console.log("Service Options: ", servicesOptions);
    } catch (error) {
      console.log("Error while getting services", error);
    }
  };

  const initialValues = {
    companyName: "",
    companyLogo: null,
    consultName: "",
    consultLogo: null,
    consultDescription: "",
    websiteURL: "",
    meetingLink: "",
    email: "",
    country: "",
    code: "",
    phone: "",
    specialisations: [],
    services: [],
    availability: "online",
    address: "",
    latitude: "",
    longitude: "",
    plans: [{ serviceName: "", price: "" }],
    packages: [{ packageName: "", price: "" }],
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: addConsultSchema,
    onSubmit: async (values, { setErrors }) => {
      const errors = {};
      console.log(consultLogo);
      // Custom error condition
      if (
        values.availability === "offline" &&
        parseFloat(values.longitude) === 0
      ) {
        errors.address = "This is not a valid address";
      }

      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return; // Prevent form submission if there are errors
      }

      // If no errors, proceed with form submission logic

      const formData = new FormData();
      formData.append("companyLogo", values.companyLogo);
      formData.append("consultLogo", values.consultLogo);
      formData.append("address", values.address);

      const companyDetails = {
        companyName: values.companyName,
        companyLogo: companyLogo,
        consultName: values.consultName,
        consultLogo: consultLogo,
        consultDescription: values.consultDescription,
        specialisations: values.specialisations,
        websiteURL: values.websiteURL,
        meetingLink: values.meetingLink,
        email: values.email,
        country: values.country,
        countryCode: values.code,
        phone: values.phone,
        services: values.services.map((services) => services.value),
        specialisations: values.specialisations.map(
          (specialisation) => specialisation.value
        ),
        availability: values.availability,
        address: values.address,
        latitude: values.latitude,
        longitude: values.longitude,
        plans: values.plans,
        packages: values.packages,
      };
      try {
        const res = await postDataHandlerWithToken(
          "addUpdateConsult",
          companyDetails
        );
        if (res.responseCode == 200) {
          toast.success("Consult added successfully!");
          navigate("/consults");
        } else {
          toast.error(res.data.responseMessage);
          // console.log(res);
        }
      } catch (error) {
        console.log("error while creating consult: ", error);
      }

      console.log(companyDetails);
    },
  });

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    setFieldValue,
  } = formik;

  const handleAddressChange = async (event) => {
    const address = event.target.value;
    setFieldValue("address", address);
  };

  const getlongLatt = async (event) => {
    const address = event.target.value;
    try {
      const response = await axios.get(
        `https://geocode.xyz/${encodeURIComponent(
          address
        )}?json=1&auth=37130815437857150226x10414`
      );
      const data = response.data;
      const { latt: lat, longt: lon } = data;
      setFieldValue("latitude", lat);
      setFieldValue("longitude", lon);
    } catch (error) {
      console.error("Error fetching coordinates: ", error);
    }
  };

  const handleAddPlan = () => {
    setFieldValue("plans", [...values.plans, { serviceName: "", price: "" }]);
  };

  const handleRemovePlan = (index) => {
    const newPlans = [...values.plans];
    newPlans.splice(index, 1);
    setFieldValue("plans", newPlans);
  };

  const handleAddPackage = () => {
    setFieldValue("packages", [
      ...values.packages,
      { packageName: "", price: "" },
    ]);
  };

  const handleRemovePackage = (index) => {
    const newPackages = [...values.packages];
    newPackages.splice(index, 1);
    setFieldValue("packages", newPackages);
    console.log(errors);
  };

  const handlePlanChange = (index, field, value) => {
    const newPlans = [...values.plans];
    newPlans[index][field] = value;
    setFieldValue("plans", newPlans);
  };

  const handlePackageChange = (index, field, value) => {
    const newPackages = [...values.packages];
    newPackages[index][field] = value;
    setFieldValue("packages", newPackages);
  };
  getServices();

  const handleCountryChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedCountry(selectedValue);

    // Find the selected country in the countriesData array
    const selectedCountryData = countriesData.find(
      (country) => country.country === selectedValue
    );

    // Update the formik state with the selected country and its code
    handleChange({
      target: {
        name: "country",
        value: selectedCountryData ? selectedCountryData.country : "",
      },
    });

    handleChange({
      target: {
        name: "code",
        value: selectedCountryData ? selectedCountryData.code : "",
      },
    });
  };
  return (
    <DashboardLayout sectionName="Company Management" title="Add Company">
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>Add Consults</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <form className="add-edit-content-section" onSubmit={handleSubmit}>
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Company Name:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="companyName"
                  value={values.companyName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter company name"
                  maxLength={50}
                />
                {errors.companyName && touched.companyName ? (
                  <p style={{ color: "red", marginTop: "2px" }}>
                    {errors.companyName}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Company Logo:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="file"
                  name="companyLogo"
                  onChange={(e) => handleThumbnailChange(e, "companyLogo")}
                  onBlur={handleBlur}
                  acceptedFile="image/*"
                />
                {errors.companyLogo && touched.companyLogo ? (
                  <p style={{ color: "red", marginTop: "2px" }}>
                    {errors.companyLogo}
                  </p>
                ) : null}
                 {companyLogo && (
                  <Box sx={{ marginTop: "10px" }}>
                    <Card sx={{ maxWidth: 200, maxHeight: 200 }}>
                      <CardMedia component="img" image={companyLogo} />
                    </Card>
                  </Box>
                )}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Consultant Name:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="consultName"
                  value={values.consultName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={50}
                  placeholder="Enter consultant name"
                 

                />
                {errors.consultName && touched.consultName ? (
                  <p style={{ color: "red", marginTop: "2px" }}>
                    {errors.consultName}
                  </p>
                ) : null}
                
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Consultant Logo:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="file"
                  name="consultLogo"
                  onChange={(e) => handleThumbnailChange(e, "consultLogo")}
                  onBlur={handleBlur}
                  acceptedFile="image/*"
                />
                {errors.consultLogo && touched.consultLogo ? (
                  <p style={{ color: "red", marginTop: "2px" }}>
                    {errors.consultLogo}
                  </p>
                ) : null}
                 {consultLogo && (
                  <Box sx={{ marginTop: "10px" }}>
                    <Card sx={{ maxWidth: 200, maxHeight: 200 }}>
                      <CardMedia component="img" image={consultLogo} />
                    </Card>
                  </Box>
                )}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Consultant’s Small Description:
                </Typography>
              </Box>
              <Box
                className="add-edit-input-field ckeditor-container"
                sx={{ width: "100%" }}
              >
                <CKEditor
                  editor={ClassicEditor}
                  data={values.consultDescription}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setFieldValue("consultDescription", data);
                  }}
                  config={{
                    toolbar: [
                      // Add or remove toolbar items based on your requirements
                      "heading",
                      "|",
                      "bold",
                      "italic",
                      "link",
                      "bulletedList",
                      "numberedList",
                      "blockQuote",
                    ],
                  }}
                />

                {errors.consultDescription && touched.consultDescription ? (
                  <p style={{ color: "red", marginTop: "2px" }}>
                    {errors.consultDescription}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Website URL:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="websiteURL"
                  value={values.websiteURL}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter website URL"
                  maxLength={100}

                />
                {errors.websiteURL && touched.websiteURL ? (
                  <p style={{ color: "red", marginTop: "2px" }}>
                    {errors.websiteURL}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Meeting Link:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="meetingLink"
                  value={values.meetingLink}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter meeting link"
                  maxLength={100}
                />
                {errors.meetingLink && touched.meetingLink ? (
                  <p style={{ color: "red", marginTop: "2px" }}>
                    {errors.meetingLink}
                  </p>
                ) : null}
              </Box>
            </Box>

            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>Email: </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter email"
                />
                {errors.email && touched.email ? (
                  <p style={{ color: "red", marginTop: "2px" }}>
                    {errors.email}
                  </p>
                ) : null}
              </Box>
            </Box>

            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>Country:</Typography>
              </Box>
              <Box className="add-edit-input-field">
                <FormControl fullWidth>
                  <Selection
                    labelId="country-label"
                    id="country"
                    name="country"
                    width="100%"
                    displayEmpty
                    value={values.country}
                    onChange={handleCountryChange}
                    onBlur={handleBlur}
                    IconComponent={(props) => (
                      <ArrowDropDownIcon
                        {...props}
                        style={{
                          color: "rgba(245, 140, 169, 1)",
                          fontSize: "1.875rem",
                        }}
                      />
                    )}
                    style={{
                      borderRadius: "0.3rem",
                      height: "3rem",
                      width: "100%",
                      color: "rgb(9,1,8)",
                    }}
                  >
                    <MenuItem value="">Select country*</MenuItem>
                    {countriesData.map((country, index) => (
                      <MenuItem key={index} value={country.country}>
                        {country.country}
                      </MenuItem>
                    ))}
                  </Selection>
                </FormControl>
                {errors.country && touched.country ? (
                  <p style={{ color: "red", marginTop: "2px" }}>
                    {errors.country}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Country code:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <div className="country-field-with-phone">
                  <InputField
                    type="text"
                    name="countryCode"
                    value={values.code}
                    placeholder="+91"
                    height="3.438rem"
                    style={{
                      borderRadius: "0.5rem 0 0 0.5rem",
                      border: "1px solid rgb(199,199,199)",
                      height: "3.438rem",
                      width: "15%",
                    }}
                    maxLength={10}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>Phone:</Typography>
              </Box>
              <Box className="add-edit-input-field">
                <div className="country-field-with-phone">
                  <InputField
                    type="text"
                    name="phone"
                    value={values.phone}
                    placeholder="Enter phone number"
                    style={{
                      width: "85%",
                      height: "3.438rem",
                      border: "1px solid rgb(199,199,199)",
                      borderRadius: "0 0.5rem 0.5rem 0",
                      paddingLeft: "2%",
                      fontSize: "1rem",
                    }}
                    maxLength={10}
                    onChange={(e) => {
                      const newValue = e.target.value.replace(/\D/g, "");
                      handleChange({
                        target: {
                          name: "phone",
                          value: newValue,
                        },
                      });
                    }}
                    onBlur={handleBlur}
                  />
                </div>
                {errors.phone && touched.phone ? (
                  <p style={{ color: "red", marginTop: "2px" }}>
                    {errors.phone}
                  </p>
                ) : null}
              </Box>
            </Box>

            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Specialisations:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <Select
                  // styles={{ height: "2.9rem" }}
                  name="specialisations"
                  value={values.specialisations}
                  onChange={(selectedOptions) => {
                    setFieldValue("specialisations", selectedOptions);
                    // console.log(selec);
                  }}
                  options={specialisationOptions}
                  isMulti
                />
                {errors.specialisations && touched.specialisations ? (
                  <p style={{ color: "red", marginTop: "2px" }}>
                    {errors.specialisations}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Services:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <Select
                  name="services"
                  value={values.services}
                  onChange={(selectedOptions) =>
                    setFieldValue("services", selectedOptions)
                  }
                  options={servicesOptions}
                  isMulti
                />
                {errors.services && touched.services ? (
                  <p style={{ color: "red", marginTop: "2px" }}>
                    {errors.services}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Availability:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="availability"
                    name="availability"
                    value={values.availability}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="online"
                      control={<Radio />}
                      label="Online"
                    />
                    <FormControlLabel
                      value="offline"
                      control={<Radio />}
                      label="Offline"
                    />
                  </RadioGroup>
                </FormControl>
                {errors.availability && touched.availability ? (
                  <p style={{ color: "red", marginTop: "2px" }}>
                    {errors.availability}
                  </p>
                ) : null}
              </Box>
            </Box>
            {values.availability === "offline" && (
              <Box className="add-edit-input-container">
                <Box className="add-edit-input-label">
                  <Typography sx={addEditStyle.addEditLabel}>
                    Address:
                  </Typography>
                </Box>
                <Box className="add-edit-input-field">
                  <InputField
                    type="text"
                    name="address"
                    value={values.address}
                    onChange={handleAddressChange}
                    onPointerLeave={getlongLatt}
                    onBlur={handleBlur}
                    placeholder="Enter address"
                  />
                  {errors.address && touched.address ? (
                    <p style={{ color: "red", marginTop: "2px" }}>
                      {errors.address}
                    </p>
                  ) : null}
                </Box>
              </Box>
            )}
          </Box>
          <Divider />
          <Box className="add-edit-input-container">
            <Box sx={{ width: "35%" }}>
              <Typography sx={addEditStyle.addEditLabel}>
                Add Pricing Plan($):
              </Typography>
            </Box>
            <Box>
              {values.plans.map((plan, index) => (
                <Box
                  key={index}
                  className="add-edit-input-field"
                  sx={{ display: "flex" }}
                  mt={1}
                >
                  <Box className="plan-input">
                    <InputField
                      type="text"
                      name={`plans[${index}].serviceName`}
                      value={plan.serviceName}
                      onChange={(e) =>
                        handlePlanChange(index, "serviceName", e.target.value)
                      }
                      onBlur={handleBlur}
                      placeholder="Service Name"
                      maxLength={100}
                    />
                    {touched.plans &&
                      touched.plans[index] &&
                      errors.plans &&
                      errors.plans[index] &&
                      errors.plans[index].serviceName && (
                        <p style={{ color: "red", marginTop: "2px" }}>
                          {errors.plans[index].serviceName}
                        </p>
                      )}
                  </Box>
                  <Box className="plan-input" ml={1}>
                    <InputField
                      type="number"
                      name={`plans[${index}].price`}
                      value={plan.price}
                      onChange={(e) =>
                        handlePlanChange(index, "price", e.target.value)
                      }
                      onBlur={handleBlur}
                      
                      placeholder="Price"
                   
                    />
                    {touched.plans &&
                      touched.plans[index] &&
                      errors.plans &&
                      errors.plans[index] &&
                      errors.plans[index].price && (
                        <p style={{ color: "red", marginTop: "2px" }}>
                          {errors.plans[index].price}
                        </p>
                      )}
                  </Box>
                  <IconButton
                    onClick={() =>
                      index === values.plans.length - 1
                        ? handleAddPlan()
                        : handleRemovePlan(index)
                    }
                  >
                    {index === values.plans.length - 1 ? (
                      <AddIcon sx={{ color: "#E18CA8" }} />
                    ) : (
                      <DeleteIcon sx={{ color: "#E18CA8" }} />
                    )}
                  </IconButton>
                </Box>
              ))}
            </Box>
          </Box>
          <Divider />
          <Box className="add-edit-input-container">
            <Box sx={{ width: "35%" }}>
              <Typography sx={addEditStyle.addEditLabel}>
                Add Pricing Package($):
              </Typography>
            </Box>
            <Box>
              {values.packages.map((pkg, index) => (
                <Box
                  key={index}
                  className="add-edit-input-field"
                  sx={{ display: "flex" }}
                  mt={1}
                >
                  <Box className="package-input">
                    <InputField
                      type="text"
                      name={`packages[${index}].packageName`}
                      value={pkg.packageName}
                      onChange={(e) =>
                        handlePackageChange(
                          index,
                          "packageName",
                          e.target.value
                        )
                      }
                      onBlur={handleBlur}
                      placeholder="Package Name"
                      maxLength={100}

                    />
                    {touched.packages &&
                      touched.packages[index] &&
                      errors.packages &&
                      errors.packages[index] &&
                      errors.packages[index].packageName && (
                        <p style={{ color: "red", marginTop: "2px" }}>
                          {errors.packages[index].packageName}
                        </p>
                      )}
                  </Box>
                  <Box className="package-input" ml={1}>
                    <InputField
                      type="number"
                      name={`packages[${index}].price`}
                      value={pkg.price}
                      onChange={(e) =>
                        handlePackageChange(index, "price", e.target.value)
                      }
                      onBlur={handleBlur}
                      placeholder="Price"
                    />
                    {touched.packages &&
                      touched.packages[index] &&
                      errors.packages &&
                      errors.packages[index] &&
                      errors.packages[index].price && (
                        <p style={{ color: "red", marginTop: "2px" }}>
                          {errors.packages[index].price}
                        </p>
                      )}
                  </Box>
                  <IconButton
                    onClick={() =>
                      index === values.packages.length - 1
                        ? handleAddPackage()
                        : handleRemovePackage(index)
                    }
                  >
                    {index === values.packages.length - 1 ? (
                      <AddIcon sx={{ color: "#E18CA8" }} />
                    ) : (
                      <DeleteIcon sx={{ color: "#E18CA8" }} />
                    )}
                  </IconButton>
                </Box>
              ))}
            </Box>
          </Box>
          <Divider />
          <Box
            className="add-edit-button-section"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <BasicButtons
              text="Add"
              name="submit"
              type="submit"
              disabled={loading}
              loading={loading}
              onClick={handleSubmit}
              width={"20%"}
            />
            <BasicButtons
              text="Back"
              width={"20%"}
              onClick={() => {
                navigate(-1);
              }}
            />
          </Box>
        </form>
      </Box>
      {selectedImage && (
        <ImageCropper
          image={selectedImage}
          aspectRatio={1}
          onCropComplete={handleCropComplete}
          onClose={() => setSelectedImage(null)}
        />
      )}
    </DashboardLayout>
  );
};

export default AddCompany;
