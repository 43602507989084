import React, { useCallback, useEffect, useState } from "react";

import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Typography, Divider, Card, CardMedia } from "@mui/material";

import typographyStyles from "../../../theme/Typography";
import BasicButtons from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import InputField from "../../../components/InputField";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  getDataHandlerWithToken,
  postDataHandlerWithToken,
  postDataHandler,
} from "../../../apiconfig/service";
import backBtn from "../../../assets/backBtn.svg";

import "../../../scss/main.css";
import { programSchema } from "../../../schema";
import Cropper from "react-easy-crop";
import ReactModal from "react-modal";

import { modalStyles } from "../../../scss/style"; // Ensure this path is correct
import { cropAndUploadImage } from "../../../utils/cropAndUploadImage";



const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
};

const dropdownOptions = [
  "Trying to Conceive",
  "Pregnant",
  "Postpartum (0-6 Weeks After Birth)",
  "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
];

const se = [
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum (0-6 Weeks After Birth)", value: "POSTPARTUM" },
  {
    key: "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
    value: "BEYOND",
  },
];

const EditProgram = () => {
  const [selectedValue, setSelectedValue] = useState("");
  const [program, setProgram] = useState({});
  const [thumbnail, setThumbnail] = useState(null);
  const [editorContent, setEditorContent] = useState();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [programId, setProgramId] = useState();
  const [loading, setLoading] = useState(false);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [charCount, setCharCount] = useState(0);
  const charLimit = 500; // Set your character limit here

  const navigate = useNavigate();
  const location = useLocation();

  const fetchProgram = async () => {
    const programIdFromLocation = location.state?.programId;
    setProgramId(programIdFromLocation);

    const query = {
      programId: programIdFromLocation,
    };
    try {
      const res = await getDataHandlerWithToken("viewProgram", query);
      if (res.responseCode === 200) {
        const response = res.result;
        console.log(response);
        setProgram(res.result);
        setEditorContent(response.description);
        setSelectedValue(
          se.find((option) => option.value === res.result.stage)?.key || ""
        );
        setCategoryId(response.categoryId)

        // Initialize the thumbnail state with the fetched image URL
        setThumbnail(response.programThumbnailImage);

        formik.setValues({
          selectRange:
            se.find((option) => option.value === response.stage)?.key || "",
          selectCategory: response.categoryName || "",
          programName: response.programName || "",
          categoryImage: response.programThumbnailImage,
          description: response.description || "",
        });
      } else {
        toast.error(res.data.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCategoryByName = async (categoryName) => {
    try {
      const res = await getDataHandlerWithToken("listCategory", {
        limit: Number.MAX_SAFE_INTEGER,
      });
      if (res.responseCode === 200) {
        const category = res.result.docs.find(
          (cat) => cat.categoryName === categoryName
        );
        return category || null;
      } else {
        toast.error(res.data.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
    return null;
  };

  useEffect(() => {
    fetchProgram();
  }, [location.state?.programId]);

  const handleDropdownChange = (value) => {
    setSelectedValue(value);
    formik.setFieldValue("selectRange", value);
  
    // Reset the category when the stage changes
    setSelectedCategory("");
    setCategoryId("");
  
    // Clear the category field in the formik values
    formik.setFieldValue("selectCategory", "");
  
    filterCategoriesByStage(value);
  };
  

  const handleThumbnailChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setIsModalOpen(true);
    }
  };

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
    formik.setFieldValue("selectCategory", value);
  
    const selectedCategoryObj = categoryList.find(
      (obj) => obj.categoryName === value
    );
  
    if (selectedCategoryObj) {
      setCategoryId(selectedCategoryObj._id);
    } else {
      setCategoryId(""); // Reset if no category is found
    }
  };

  const [mydata, setMydata] = useState([]);

  const fetchData = async () => {
    try {
      const res = await getDataHandlerWithToken("listCategory", {
        limit: Number.MAX_SAFE_INTEGER,
      });
      if (res.responseCode === 200) {
        setMydata(res.result.docs || []);
      } else {
        toast.error(res.data.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  // Image cropping functions
  const handleCrop = useCallback(async () => {
    setLoading(true)
    try {
      const cloudinaryUrl = await cropAndUploadImage(selectedImage, croppedAreaPixels);
      setCroppedImage(cloudinaryUrl);
      setThumbnail(cloudinaryUrl);
      setIsModalOpen(false);
    } catch (e) {
      toast.error("Error while cropping or uploading image");
    }
    setLoading(false)
  }, [croppedAreaPixels, selectedImage]);

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
    setCroppedImage(null);
    setThumbnail(null);
  };

  

  const filterCategoriesByStage = (selectedValue) => {
    const stageValue = se.find((option) => option.key === selectedValue)?.value;
    if (stageValue) {
      const filteredCategories = mydata.filter(
        (category) =>
          category.stage === stageValue &&
          category.categoryName != "Recipes" &&
          category.categoryName != "Workout" &&
          category.status !== "BLOCK"
      );
      setCategoryList(filteredCategories);
    }
  };

  useEffect(() => {
    fetchData();
    filterCategoriesByStage(selectedValue);
  }, [selectedValue]);

  const formik = useFormik({
    initialValues: {
      selectRange: "",
      selectCategory: "",
      programName: "",
      categoryImage: null,
      description: "",
    },
    validationSchema: programSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const data = {
        stage: se.find((option) => option.key === formik.values.selectRange)
          ?.value,

        programId: programId,

        categoryId: categoryId,
        programName: values.programName,
        programThumbnailImage: thumbnail
          ? thumbnail
          : program.programThumbnailImage,
        description: editorContent,
      };
      console.log(data);

      try {
        setLoading(true);
        const res = await postDataHandlerWithToken("addUpdateProgram", data);
        if (res.responseCode === 200) {
          toast.success("Program updated successfully.");
          navigate("/program");
        } else {
          toast.error(res.data.responseMessage);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <DashboardLayout
      sectionName="Content - Program Management"
      title="Edit Program"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{
                cursor: "pointer",
              }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>Edit Program</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <form
          className="add-edit-content-section"
          onSubmit={formik.handleSubmit}
        >
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Select Stage:
                </Typography>
              </Box>
              <Dropdown
                options={dropdownOptions}
                value={formik.values.selectRange}
                onChange={handleDropdownChange}
                placeholder="Select Stage"
              />
              {formik.errors.selectRange && formik.touched.selectRange ? (
                <p style={{ color: "red", marginTop: "5px", float: "left" }}>
                  {formik.errors.selectRange}
                </p>
              ) : null}
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Select Category:
                </Typography>
              </Box>
              <Dropdown
                options={categoryList.map((category) => category.categoryName)}
                value={formik.values.selectCategory}
                onChange={handleCategoryChange}
                placeholder="Select Category"
              />
              {formik.errors.selectCategory && formik.touched.selectCategory ? (
                <p style={{ color: "red", marginTop: "5px", float: "left" }}>
                  {formik.errors.selectCategory}
                </p>
              ) : null}
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Program Name:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="programName"
                  value={formik.values.programName}
                  maxLength={50}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter program name"
                />
                {formik.errors.programName && formik.touched.programName ? (
                  <p style={{ color: "red", marginTop: "5px", float: "left" }}>
                    {formik.errors.programName}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Upload Thumbnail Image:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  name="categoryImage"
                  type="file"
                  onChange={handleThumbnailChange}
                  onBlur={formik.handleBlur}
                  acceptedFile="image/*"
                />
                {formik.errors.categoryImage && formik.touched.categoryImage ? (
                  <p style={{ color: "red", marginTop: "5px", float: "left" }}>
                    {formik.errors.categoryImage}
                  </p>
                ) : null}
                {/* Display the thumbnail preview */}
                {thumbnail && (
                  <Box mt={2}>
                    <Card
                      sx={{ maxWidth: 300, maxHeight: "auto", margin: "5px" }}
                    >
                      <CardMedia component="img" image={thumbnail} />
                    </Card>
                  </Box>
                )}
              </Box>
            </Box>

            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Description:
                </Typography>
              </Box>
              <Box
                className="add-edit-input-field ckeditor-container"
                sx={{ width: "100%" }}
              >
                {" "}
                <CKEditor
                  editor={ClassicEditor}
                  data={formik.values.description}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setEditorContent(data);
                    formik.setFieldValue("description", data);
                    const plainText = data.replace(/<[^>]*>/g, "");
                    setCharCount(plainText.length);
                  }}
                  config={{
                    toolbar: [
                      "heading",
                      "|",
                      "bold",
                      "italic",
                      "underline",
                      "strikethrough",
                      "|",
                      "bulletedList",
                      "numberedList",
                      "blockQuote",
                      "|",

                      "undo",
                      "redo",
                    ],
                    // height: "200px",
                  }}
                />
                {formik.errors.description && formik.touched.description ? (
                  <p style={{ color: "red", marginTop: "5px", float: "left" }}>
                    {formik.errors.description}
                  </p>
                ) : null}
                 <Typography

                 variant="subtitle2"
                  sx={{
                    
                    textAlign: "right",
                    mt: 1,
                    color: charCount > charLimit ? "red" : "inherit",
                  }}
                >
                  {charCount}/{charLimit} characters
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            className="add-edit-button-section"
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <BasicButtons
              width={"20%"}
              type="submit"
              loading={loading}
              title="Save"
              text={"Edit Program"}
            />
          </Box>
        </form>
      </Box>
      <ReactModal
  isOpen={isModalOpen}
  onRequestClose={() => handleCancel()}
  style={modalStyles}
  contentLabel="Crop Image"
>
  <div style={modalStyles.cropperContainer}>
    <Cropper
      image={selectedImage}
      crop={crop}
      zoom={zoom}
      aspect={17 / 12}
      onCropChange={setCrop}
      onZoomChange={setZoom}
      onCropComplete={onCropComplete}
    />
    <Box
            sx={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              p: 2,
              gap:5
            }}
          >
            <BasicButtons text="Cancel" onClick={handleCancel} />
            <BasicButtons text="Crop" onClick={handleCrop}  loading={loading} />
          </Box>
  </div>
</ReactModal>
    </DashboardLayout>
  );
};

export default EditProgram;
