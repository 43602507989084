import React,{useState} from "react";
import { Box, TextField, Typography } from "@mui/material";

import LoginLayout from "../../../layouts/LoginLayout/LoginLayout";
import typographyStyles from "../../../theme/Typography";
import BasicButtons from "../../../components/Button";
import { useNavigate,useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { resetPasswordSchema } from "../../../schema";
import '../../../scss/main.css';
import InputField from "../../../components/InputField";
import { postDataHandler } from "../../../apiconfig/service";
import { toast } from "react-hot-toast";


const authStyle = {

    authHeading: {
        textAlign: 'center',
        ...typographyStyles.h3,
    },

    authSubHeading: {
        ...typographyStyles.h6,
        color: '#0009',
        textAlign: 'center',
    },


    authLabel: {
        ...typographyStyles.h6,
    },


    forBack: {
        ...typographyStyles.h6,
        color: '#3996d2',
        textAlign: 'center',
        cursor: 'pointer',
    },


}

const ResetPassword = () => {

    const initialValues = {
        newPassword:"",
        confirmPassword:"",
    }

    const navigate = useNavigate();
    const location = useLocation();
    const [Email,setEmail]=useState( location.state?.email || "")
    const [loading,setLoading]=useState(false)
    const handleFormSubmit = async (values) => {
        
        const data = {
          email: Email,
          newPassword:values.newPassword
        };
        try {
          
         setLoading(true)
          const res = await postDataHandler("resetPassword", data);
          
          if (res?.responseCode === 200) {
    
            toast.success(res?.responseMessage);
            console.log(res)
            // auth.checkLogin(res?.token);
            
            navigate("/");
    
            
          } else {
            
            toast.error(res?.data?.responseMessage);
            
            
          }
          //console.log("removed");
        } catch (error) {
          console.log(error)
        }finally{
            setLoading(false)
        }
      };

    const {
        values,
        errors,
        handleBlur,
        handleChange,
        touched,
        handleSubmit,
    } = useFormik({
        initialValues: initialValues,
        validationSchema: resetPasswordSchema,
        onSubmit: (values) => handleFormSubmit(values),
    })
    return (
        <LoginLayout style={{border: '2px solid red'}}>
            <Box className="authBox" sx={{ display: 'flex', justifyContent:"center"}}>
                <form className="authContainer" onSubmit={handleSubmit}>
                    <Box className="authHeadingBox">
                    <Typography style={authStyle.authHeading}>Reset Password</Typography>
                    <Typography style={authStyle.authSubHeading}>Enter your new password.</Typography>
                    </Box>
                    <Box className="authContent">
                        <Box className="authFields">
                            <Typography variant="h6" style={authStyle.authLabel}>New Password</Typography>
                            <InputField 
                                    className="authTextField" 
                                    type='password'
                                    name='newPassword'
                                    value={values.newPassword}
                                    maxLength={20}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter the new password"
                            />
                            {errors.newPassword && touched.newPassword ? (
                                    <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                                    {errors.newPassword}
                                    </p>
                            ) : null}
                        </Box>

                        <Box className="authFields">
                            <Typography variant="h6" style={authStyle.authLabel}>confirm Password</Typography>
                            <InputField 
                                    className="authTextField" 
                                    type='password'
                                    name='confirmPassword'
                                    value={values.confirmPassword}
                                    maxLength={20}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter the confirm password"
                            />
                            {errors.confirmPassword && touched.confirmPassword ? (
                                    <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                                    {errors.confirmPassword}
                                    </p>
                            ) : null}
                        </Box>
                        
                    </Box>
                    <Box className="authFields" sx={{alignItems:"center"}}>
                           <BasicButtons 
                            text="Submit" 
                            name='submit'
                            type='submit'
                            loading={loading}
                            width={"35%"}
                           />
                    </Box>
                    <Typography style={authStyle.forBack} onClick={()=>{navigate('/')}}>Back to login</Typography>
                </form>
            </Box>
        </LoginLayout>
    );
};

export default ResetPassword;